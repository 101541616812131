import React from "react";

const Steps = ({ steps, stepsTitles, active, className }) => {
  const stepElements = [];

  for (let i = 0; i < steps; i++) {
    stepElements.push(
      <li
        key={i}
        className={`flex-1 flex flex-col ${i === 0 ? "items-end" : ""}`}
      >
        <div
          className={`${
            i === 0
              ? "rounded-l-3xl w-3/5"
              : i === 3
              ? "rounded-r-3xl justify-end w-3/5"
              : "justify-center"
          } p-1 bg-white flex`}
        >
          {i + 1 < active ? (
            <div className="bg-[#DCFFD8] rounded-full w-5 h-5 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10 3L4.5 8.5L2 6"
                  stroke="#53C645"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div
              className={`rounded-full w-5 h-5 flex justify-center items-center  text-xxs font-semibold text-white ${
                i + 1 == active
                  ? "bg-gradient-to-b from-[#209DF2] to-blue-900"
                  : "bg-gray-300"
              }`}
            >
              {i + 1}
            </div>
          )}
        </div>
        <span
          className={`${
            i + 1 == active ? "text-blue-900" : "text-gray-400"
          }  text-xs font-medium mt-3 w-full text-center`}
        >
          {stepsTitles[i]}
        </span>
      </li>
    );
  }
  return (
    <div className={className}>
      <ul className="flex w-full lg:w-96">{stepElements}</ul>
    </div>
  );
};
export default Steps;
