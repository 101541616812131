import React, { useState, useEffect } from "react";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import ReviewSlider from "../../components/molecules/ReviewSlider";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import ResourceCenter from "../../components/organisms/ResourceCenter";
import TherapistSelection from "../../components/organisms/TherapistSelection";
import { useDispatch, useSelector } from "react-redux";
import Questionnaire from "../../components/organisms/Questionnaire";
import ScheduleSession from "../../components/molecules/ScheduleSession";
import Checkout from "../../components/molecules/Checkout";
import PaymentStatus from "../../components/molecules/PaymentStatus";
import { getSessionsList } from "../../redux/patient/patientThunks";

import {
  getRecommendations,
  getQuestionnaire,
  getPackageDetails,
} from "../../redux/onboarding/onboardingThunks";
import Loader from "../../components/atoms/Loader";
import { setTherapist } from "../../redux/onboarding/onboardingSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ChristinaPatitsa from "../../assets/Christina_patitsa.jpg";
import Gaston from "../../assets/Gaston_molina.jpg";
import Nathasha from "../../assets/Nathasha_sharma.jpg";
import Thomas from "../../assets/Thomas_theodosiou.jpg";
import Manuel from "../../assets/Manuel_torres.jpg";
import Mary from "../../assets/Mary-Ann-Frazier.jpg";
import Fatima from "../../assets/author-placeholder.png";
import { MetaTags } from "../../services/globalFunctions";

const ReviewData = [
  {
    name: "Gaston Molina",
    session: "2",
    review:
      "“Seeing therapists can be daunting, Gaston makes it easy. He gets it, is able to help me break things down and work through everything, he's brilliant!”",
    rating: 5,
    designation: "Clinical Psychologist & Therapist",
    image: Gaston,
  },
  {
    name: "Fatima Issa",
    session: "4",
    review:
      "“The sessions were really helpful, it made me understand myself more and I had a noticeable improvement with my mental health”",
    rating: 4,
    designation: "Clinical Psychologist",
    image: Fatima,
  },
  {
    name: "Christina Patitsa",
    session: "1",
    review:
      "“She is an excellent psychologist, really smart and insightful. She is definitely well-experienced as a psychologist as she able to understand and respond very well to my thoughts and emotions. I would definitely recommend her”",
    rating: 5,
    designation: "Counsellor & Clinical Psychologist",
    image: ChristinaPatitsa,
  },
  {
    name: "Mary Ann Frazier",
    session: "1",
    review:
      "“l liked the vigilance in asking questions and insuring all information are understood. I like how I was provided all the necessary information about what to expect prior to the start of the session, and the reassurance provided throughout the session.”",
    rating: 5,
    designation: "Clinical Psychologist & Therapist",
    image: Mary,
  },
  {
    name: "Nathasha Sharma",
    session: "8",
    review:
      "“I have been putting therapy off for about 2 years. I've always had anxiety, and occasional panic attacks due to a lot of internal and external issues with life, and basically adulthood. The catalyst was actually starting my new job. It was a whole shift and I had to make major adjustments with my life and relationships. I'm only 2 months in, and already handling more than what I'm supposed to. I found myself crying day in and day out because of this, and I would isolate myself away from family. Nathasha listened with an open mind and heart, and had compassion and empathy. She gave me tips on how to better handle myself in situations during a panic/anxiety attack, and communication tips with my boss (who is my biggest obstacle at the moment). It's only been one session, but I can't wait for the next!”",
    rating: 5,
    designation: "Counsellor & Psychologist",
    image: Nathasha,
  },
  {
    name: "Thomas Theodosiou",
    session: "2",
    review:
      "“Thomas really helps me understand my thinking process and highlights areas of improvement”",
    rating: 4,
    designation: "Clinical Neuropsychologist & Clinical Therapist",
    image: Thomas,
  },
  {
    name: "Manuel Torres",
    session: "5",
    review:
      "“I had a wonderful and constructive sessions with Manuel and Fitcy. I found Manuel to be very affable and he gave me the freedom to conduct our sessions in a pace where I am comfortable, this made it easier for me to discuss things i normally would not. My counseling was intended to focus solely on my stress triggers, but Manuel dug deep into my past and origins and helped me understand my issues and myself better.”",
    rating: 5,
    designation: "Counsellor & Clinical Therapist",
    image: Manuel,
  },
];

export default function PatientOnboarding() {
  const [sessions, setSessions] = useState(null);
  //const [selectedTherapist, setSelectedTherapist] = useState(null);

  const params = useLocation();
  const [step, setStep] = useState(
    params.search === "?wp-redirect"
      ? 5
      : params.search === "?skip_questions"
        ? 3
        : params.search === "?schedule_session"
          ? 4
          : 1
  );
  const [assessment, setAssessment] = useState();
  const user = useSelector((state) => state.app.user);
  const [refresh, setRefresh] = useState(true);
  const [bookingLoader, setBookingLoader] = useState({});
  const navigate = useNavigate();
  // if (user.onboarding) window.location.replace("/dashboard/patient-complete");

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      (user?.wp_redirect && params.search != "?wp-redirect") ||
      localStorage.getItem("wp-redirect-user")
    ) {
      localStorage.removeItem("wp-redirect-user");
      navigate("/resources/?wp-redirect");
    }
    dispatch(getQuestionnaire());
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchTherapist();
    }
  }, [refresh]);

  /* useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getSessionsList({ id: selectedTherapist.id }));
        if (response.payload) {
          setSessions(response.payload.data);

          //setLoadingSkeleton(false);
          //console.log("selected Therapist sessions", response);
        }
        // After getAllSessionsList completes, you can access the updated state
      } catch (error) {
        // Handle any errors that might occur during getAllSessionsList
        console.error("Error fetching appointment list:", error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [selectedTherapist]); */

  const fetchTherapist = async () => {
    const res = await dispatch(getRecommendations());
    if (res) setRefresh(false);
  };
  // eslint-disable-next-line no-undef
  const getAssessment = (item) => {
    item?.complete ? setStep(3) : setStep(2);
    setAssessment(item);
  };
  const retakeAssessment = (item) => {
    item?.complete ? setStep(2) : setStep(2);
    setAssessment(item);
  };
  const goToNextStep = async (therapist) => {
    const slotId = `${therapist.productIndex}-${therapist.slotIndex}`;
    window.localStorage.setItem(
      "fitcy_onboarding_package_id",
      therapist.slot.package_id
    );
    window.localStorage.setItem(
      "fitcy_onboarding_therapist_id",
      therapist.selectedTherapist.id
    );
    setBookingLoader((prevLoader) => ({
      ...prevLoader,
      [slotId]: true,
    }));
    const response = await dispatch(
      getPackageDetails({
        package_id: therapist.slot.package_id,
      })
    );

    if (response.payload) {
      dispatch(setTherapist(therapist.selectedTherapist));

      const sessionsResponse = await dispatch(
        getSessionsList({ id: therapist.selectedTherapist.id })
      );

      if (sessionsResponse.payload) {
        setSessions(sessionsResponse.payload.data);
        //console.log("selected therapist sessions", sessionsResponse);
      }

      //setSelectedTherapist(therapist.selectedTherapist);
      //console.log("selected therapist", selectedTherapist);

      setStep(4);
      setBookingLoader((prevLoader) => ({
        ...prevLoader,
        [slotId]: false,
      }));
    }
  };

  const getStepAction = () => {
    if (step === 1)
      return (
        <ResourceCenter
          onAssessmentClick={getAssessment}
          retake={retakeAssessment}
        />
      );

    if (step === 2)
      return (
        <Questionnaire setStep={(value) => setStep(value)} data={assessment} />
      );

    if (step === 3)
      return (
        <TherapistSelection
          user={user}
          setStep={(value) => setStep(value)}
          data={assessment}
          goToNextStep={goToNextStep}
          setRefresh={(value) => setRefresh(value)}
          refresh={refresh}
          bookingLoader={bookingLoader}
        />
      );

    if (step === 4)
      return (
        <ScheduleSession
          setStep={(value) => setStep(value)}
          firstSession={
            sessions?.past.length + sessions?.upcoming.length > 0 ? false : true
          }
        />
      );
    if (step === 5) return <Checkout setStep={(value) => setStep(value)} />;
    if (step === 6) return <PaymentStatus />;
    else return <Loader />;
  };

  return (
    <>
      <MetaTags
        title="Resources | Fitcy Health"
        description="Explore the Fitcy Health's Resource Center with tons of helpful resources such as articles, videos and more."
        url="/resources"
      />
      <Header
        className="border-b border-gray-200 fixed z-10 bg-white"
        dashboard={step === 1 ? true : false}
        backButton={step > 1 && step < 6 ? true : false}
        setStep={(value) => setStep(value)}
        step={step}
      />
      <div
        className={`${step === 1 ? "lg:flex lg:pl-24" : ""} ${step === 5 ? "" : "pb-24"
          } bg-cover mobile:bg-center pt-[4.5rem]`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {step === 1 && (
          <SideMenu className="bg-white left-0 lg:top-[4.45rem]" />
        )}
        <div className="w-full">{getStepAction()}</div>
        {step > 1 && step < 4 && (
          <ReviewSlider
            data={ReviewData}
            heading="Hear what people are saying about us"
            wrapperClassName="container mx-auto mt-12 lg:mt-24 px-4"
            className="bg-teal-50 py-6 px-4 lg:py-16 lg:px-28 rounded-3xl mt-6"
          />
        )}
      </div>
    </>
  );
}
