import React, { useEffect } from "react";
import Loader from "../../components/atoms/Loader";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_URL;

export default function WpRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userFirstName = params.get("first_name");
  const userLastName = params.get("last_name");
  const userEmail = params.get("email");
  const phoneNumber = params.get("phone_number");
  useEffect(() => {
    sendData();
  }, []);

  const sendData = async () => {
    const cookie = Cookies.get();
    const body = {
      start_time: params.get("start_time"),
      end_time: params.get("end_time"),
      timezone: params.get("timezone"),
      package_id: params.get("package_id"),
    };

    let headers = {
      "Content-Type": "application/json",
      "Session-id": cookie._hjSessionUser_3015667,
    };

    if (localStorage.getItem("fitcyAccessToken"))
      headers.Authorization = `JWT ${localStorage.getItem("fitcyAccessToken")}`;

    try {
      const response = await fetch(
        BASE_URL + "/api/public/appointment/schedules/",
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: headers,
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify(body),
        }
      );
      const res = await response.json();
      console.log("response", res);
      if (!res?.data?.is_logged_in) {
        window.localStorage.setItem("wp-redirect-user", true);
        window.localStorage.setItem(
          "session-id",
          cookie._hjSessionUser_3015667
        );
        navigate("/sign-up", {
          state: { userFirstName, userLastName, userEmail, phoneNumber },
        });
      } else if (res?.data?.is_logged_in) navigate("/resources/?wp-redirect");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="h-100">
      <Loader />
    </div>
  );
}
