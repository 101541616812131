import React, { useEffect, useRef, useState, useContext } from "react";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import SearchIcon from "../../assets/search-icon.svg";
import FilterIcon from "../../assets/filter-lines.svg";
import ClockIcon from "../../assets/clock-stopwatch.svg";
import SendIcon from "../../assets/send_blue_icon.svg";
import therapistDefaultChatIcon from "../../assets/therapist_default_chat.svg";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  getPatientTherapistChatList,
  getRTMToken,
} from "../../redux/agora/agoraThunks";
import {
  getMessageHistory,
  patchSingleMessage,
  saveSingleMessage,
} from "../../redux/patient/patientThunks";
import moment from "moment";
import PatientMessageCorner from "../../assets/white-message-corner.svg";
import TherapistMessageCorner from "../../assets/blue-message-corner.svg";
import Modal from "react-modal";
import Avatar from "../../assets/profile-picture-avatar.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { MetaTags, lastMessageTime } from "../../services/globalFunctions";
import { RTMClientContext } from "../../contexts/RTMClientContext";

let page = 1;

const userSkeletonData = [{}, {}];
const TherapistMessages = () => {
  const { rtmClient } = useContext(RTMClientContext);
  const params = useLocation();
  const searchParams = new URLSearchParams(params.search);
  const targetId = searchParams.get("id");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const token = useSelector((state) => state.agora.rtmToken);
  const messageHistory = useSelector((state) => state.patient.messageHistory);
  const chatList = useSelector((state) => state.agora.chatList);

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const messageRef = useRef();
  const filterRef = useRef();
  const uid = user?.id;

  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [filter, setFilter] = useState("Newest");
  const [messageRefHeight, setMessageRefHeight] = useState(0);
  const [searchText, setSearchText] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [patientList, setPatientList] = useState(chatList?.data);
  const [refresh, setRefresh] = useState(false);

  const emptyUserList = patientList?.length == 0 && searchText == "";

  const FinalMessageList = [];
  const reverseArray = chatMessages?.slice(0, page * 100)?.reverse();

  for (let i = 0; i < reverseArray?.length; i++) {
    const currentItem = reverseArray[i];
    const previousItem = reverseArray[i - 1];

    if (i === 0 || currentItem.days !== previousItem.days) {
      FinalMessageList.push({ newDay: currentItem.days });
    }

    FinalMessageList.push(currentItem);
  }

  useEffect(() => {
    setPatientList(chatList?.data);
  }, [chatList]);

  useEffect(() => {
    if (patientList && targetId) {
      const filteredPatient = patientList?.filter(
        (item) => item.id == targetId
      );
      setSelectedPatient(filteredPatient?.length > 0 ? filteredPatient[0] : "");
    }
  }, [patientList, targetId]);

  useEffect(() => {
    if (selectedPatient) {
      const container = document.getElementById("message-container");
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageHistory, selectedPatient]);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0 && messageHistory?.next) {
      page += 1;
      loadMessageHistory();
    }
  };

  useEffect(() => {
    if (page == 1) setChatMessages(messageHistory?.data || []);
    else
      setChatMessages(
        Object.assign([], chatMessages?.concat(messageHistory?.data))
      );
  }, [messageHistory]);

  useEffect(() => {
    if (messageRef.current) {
      if (page == 1) {
        messageRef.current.scrollTop = messageRef?.current?.scrollHeight;
        setMessageRefHeight(messageRef?.current?.scrollHeight);
      } else
        messageRef.current.scrollTop =
          messageRef?.current?.scrollHeight - messageRefHeight;
    }
  }, [chatMessages, isMobile, page]);

  useEffect(() => {
    page = 1;
    if (!token) dispatch(getRTMToken({}));
    fetchChatList();
  }, []);

  useEffect(() => {
    if (token && selectedPatient && !subscribed) initialCall();
    else if (token && selectedPatient) {
      page = 1;
      loadMessageHistory();
    }
  }, [token, selectedPatient]);

  useEffect(() => {
    if (rtmClient) {
      const handleMessage = (eventArgs) => {
        page = 1;
        loadMessageHistory(eventArgs?.publisher);
        fetchChatList();
      };

      const handleStatus = (eventArgs) => {
        console.log(eventArgs, "event status change");
      };

      try {
        rtmClient.addEventListener("message", handleMessage);
        rtmClient.addEventListener("status", handleStatus);
      } catch {
        console.log("Error in fetching listener message");
      }

      // Cleanup function
      return () => {
        rtmClient.removeEventListener("message", handleMessage);
        rtmClient.removeEventListener("status", handleStatus);
      };
    }
  }, [rtmClient]);

  const initialCall = async () => {
    try {
      if (rtmClient) {
        try {
          await subscribeCall();
          setSubscribed(true);
          page = 1;
          loadMessageHistory();
        } catch (err) {
          console.log(err, "error in login");
        }
      }
    } catch (error) {
      console.log(error, "error in setup");
    }
  };

  // const setupSignalingEngine = async () => {
  //   try {
  //     rtmClient = new AgoraRTM.RTM(appId, uid?.toString(), {
  //       token: token?.rtm_token,
  //     });
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  const subscribeCall = async () => {
    try {
      const subscribeOptions = {
        withMessage: true,
        withPresence: true,
        withMetadata: true,
        withLock: true,
      };
      await rtmClient.subscribe(
        selectedPatient?.id?.toString(),
        subscribeOptions
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChatList = async (text = "", sort = filter?.toLowerCase()) => {
    // setIsLoading(true);
    await dispatch(
      getPatientTherapistChatList({
        keyword: text,
        sort: sort,
      })
    );
    // if (res) setIsLoading(false);
  };

  const loadMessageHistory = (patient_id = selectedPatient?.id) => {
    dispatch(
      getMessageHistory({
        patient_id: patient_id,
        therapist_id: uid,
        page: page,
      })
    );
  };

  const sendText = async () => {
    try {
      await rtmClient.publish(selectedPatient?.id?.toString(), message);
      let body = {
        message_type: "text",
        message: message,
        read_status: false,
        to: selectedPatient?.id,
        sender: uid,
      };
      dispatch(saveSingleMessage(body));

      body.created = new Date();
      body.days = "Today";
      setChatMessages(Object.assign([], [body, ...chatMessages]));
      setMessage("");
      fetchChatList();
      messageRef.current.scrollTop = messageRef?.current?.scrollHeight;
    } catch (err) {
      setRefresh(true);
      console.log({ err }, "error occurs at publish message");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendText();
    }
  };

  const commonModalStyles = {
    content: {
      maxWidth: "120px",
      width: "100%",
      top: `${modalPosition.top}px`,
      left: `${modalPosition.left}px`,
      border: "none",
      borderRadius: "16px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      background: "transparent",
      zIndex: "10",
    },
  };

  const handleSearchChat = (e) => {
    setSearchText(e.target.value);
    if (e?.target?.value) {
      const res = chatList?.data?.filter((listItem) =>
        (listItem?.first_name + " " + listItem?.last_name)
          ?.toString()
          ?.toLowerCase()
          ?.includes(e.target?.value?.toLowerCase())
      );
      setPatientList(res);
    } else setPatientList(chatList?.data);
  };

  const openDropdown = () => {
    const boundingBox = filterRef?.current.getBoundingClientRect();
    setModalPosition({
      top: boundingBox.top + window.scrollY + 20,
      left: boundingBox.left + window.scrollX - 100,
    });
    setFilterModal(true);
  };

  // const emptyChatStyles = {
  //   content: {
  //     maxWidth: isMobile ? "350px" : "390px",
  //     border: "none",
  //     top: "0px",
  //     bottom: "0px",
  //     left: "0px",
  //     right: "0px",
  //     margin: "auto",
  //     borderRadius: "16px",
  //     height: "max-content",
  //     background: "#fff",
  //     overflowY: "auto",
  //     padding: "24px",
  //   },
  //   overlay: {
  //     background: "rgba(0, 0, 0, 0.09)",
  //     zIndex: 99999,
  //   },
  // };

  // const handleRoute = () => {
  //   navigate(`/login`);
  // };

  const PatientListComponent = () => {
    return (
      <div
        className={`w-full lg:w-80 px-4 lg:px-5 py-5 lg:border-r lg:border-r-solid`}
      >
        <div className="flex items-center justify-between">
          <div className="text-2xl font-medium">Chats</div>
          {/* <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14V8M9 11H15M7 18.5V20.8355C7 21.3684 7 21.6348 7.10923 21.7716C7.20422 21.8906 7.34827 21.9599 7.50054 21.9597C7.67563 21.9595 7.88367 21.7931 8.29976 21.4602L10.6852 19.5518C11.1725 19.162 11.4162 18.9671 11.6875 18.8285C11.9282 18.7055 12.1844 18.6156 12.4492 18.5613C12.7477 18.5 13.0597 18.5 13.6837 18.5H16.2C17.8802 18.5 18.7202 18.5 19.362 18.173C19.9265 17.8854 20.3854 17.4265 20.673 16.862C21 16.2202 21 15.3802 21 13.7V8.3C21 6.61984 21 5.77976 20.673 5.13803C20.3854 4.57354 19.9265 4.1146 19.362 3.82698C18.7202 3.5 17.8802 3.5 16.2 3.5H7.8C6.11984 3.5 5.27976 3.5 4.63803 3.82698C4.07354 4.1146 3.6146 4.57354 3.32698 5.13803C3 5.77976 3 6.61984 3 8.3V14.5C3 15.43 3 15.895 3.10222 16.2765C3.37962 17.3117 4.18827 18.1204 5.22354 18.3978C5.60504 18.5 6.07003 18.5 7 18.5Z"
              stroke="#029CF5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
        </div>
        <div className="flex items-center drop-shadow justify-between bg-white rounded-lg py-2.5 px-3.5 my-5">
          <div className="flex items-center space-x-2">
            <img src={SearchIcon} />
            <input
              placeholder="Search..."
              className="outline-none border-none w-full"
              onChange={handleSearchChat}
            />
          </div>
          <div
            ref={filterRef}
            className="cursor-pointer"
            onClick={openDropdown}
          >
            <img src={FilterIcon} />
          </div>
        </div>
        <div className="overflow-y-auto h-[calc(100vh-19rem)] lg:h-[68vh]">
          {!emptyUserList ? (
            <>
              {patientList?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      fetchChatList();
                      setSelectedPatient(item);
                      page = 1;
                      loadMessageHistory(item.id);
                    }}
                    key={index}
                    className={`px-3 flex items-center space-x-3 rounded-lg cursor-pointer ${selectedPatient.id == item.id && "bg-blue-50"
                      }`}
                  >
                    <img
                      src={item?.profile_picture?.full_url || Avatar}
                      alt="Profile Image"
                      className="w-12 h-12 rounded-full my-3 bg-gray-100 object-contain"
                    />
                    <div className="flex-1 space-y-1 border-b border-b-solid py-3">
                      <div className="flex items-center justify-between">
                        <div className="w-48 lg:w-32 overflow-hidden whitespace-nowrap">
                          <div className="text-base font-medium text-gray-500 text-ellipsis overflow-hidden">
                            {(item?.first_name || "") +
                              " " +
                              (item?.last_name || "")}
                          </div>
                        </div>
                        {item?.latest_msg && (
                          <div className="text-xs text-gray-400">
                            {lastMessageTime(item?.latest_msg?.created)}
                            {/* {moment(item?.latest_msg?.created).format(
                              "hh:mm a"
                            )} */}
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-between">
                        <div
                          className={`text-sm ${item?.latest_msg
                            ? "text-gray-500"
                            : "text-transparent"
                            }`}
                        >
                          {item?.latest_msg?.message?.slice(0, 17) +
                            (item?.latest_msg?.message?.length > 17
                              ? "..."
                              : "")}
                        </div>
                        {item?.unread_count > 0 && (
                          <div className="text-[10px] text-white font-semibold bg-[#CD1309] px-2 border-[1.5px] border-white rounded-full">
                            {item?.unread_count || 0}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
              {userSkeletonData?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`px-3 flex items-center space-x-3 rounded-lg`}
                  >
                    <img
                      src={Avatar}
                      alt="Profile Image"
                      className="w-12 h-12 rounded-full my-3 bg-gray-100 object-contain"
                    />
                    <div className="flex-1 space-y-1 py-3">
                      <div className="flex items-center justify-between">
                        <Skeleton height={20} width={130} />
                        <Skeleton height={20} width={30} />
                      </div>
                      <div className="flex items-center justify-between">
                        <Skeleton height={15} width={60} />
                        <Skeleton height={15} width={15} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </SkeletonTheme>
          )}
        </div>
      </div>
    );
  };

  const ChatComponent = () => {
    return (
      <div className="flex-1">
        <div className="border-b border-b-solid py-4 px-4 lg:px-6 lg:flex space-y-2 lg:space-y-0 items-end justify-between">
          {!emptyUserList ? (
            <>
              <div className="flex items-center space-x-2">
                {isMobile && (
                  <div onClick={() => setSelectedPatient("")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
                        fill="#101828"
                      />
                    </svg>
                  </div>
                )}
                <img
                  src={selectedPatient?.profile_picture?.full_url || Avatar}
                  alt="Profile Image"
                  className="w-9 h-9 rounded-full bg-gray-100 object-contain"
                />
                <div>
                  <div className="text-base font-medium">
                    {(selectedPatient?.first_name || "") +
                      " " +
                      (selectedPatient?.last_name || "")}
                  </div>
                  {selectedPatient?.next_session && (
                    <div className="text-sm text-gray-500">
                      Upcoming session in {selectedPatient?.next_session?.days}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-1">
                <img src={ClockIcon} />
                <div className="text-xs text-gray-500">
                  {selectedPatient?.first_name}’s Time:
                </div>
                <div className="text-xs font-medium">
                  {moment(selectedPatient?.local_time)
                    .tz(selectedPatient?.timezone)
                    .format("hh:mm A")}
                </div>
              </div>
            </>
          ) : (
            <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
              <div className="flex items-center space-x-2">
                <img
                  src={Avatar}
                  alt="Profile Image"
                  className="w-9 h-9 rounded-full bg-gray-100 object-contain"
                />
                <div>
                  <Skeleton height={20} width={130} />
                  <Skeleton height={20} width={30} />
                </div>
              </div>
              <Skeleton height={20} width={130} />
            </SkeletonTheme>
          )}
        </div>
        <div
          id={"message-container"}
          ref={messageRef}
          className={`bg-blue-50 h-[calc(100vh-320px)] lg:h-[calc(100vh-230px)] px-6 lg:px-10 py-3 overflow-y-auto`}
        // ${
        //   !selectedPatient?.chat_permission
        //     ? "h-[calc(100vh-375px)]"
        //     : "h-[calc(100vh-320px)]"
        // }
        >
          {FinalMessageList?.map((item, index) => {
            if (item?.sender != uid && !item?.read_status && item?.id)
              dispatch(patchSingleMessage({ id: item?.id, read_status: true }));
            return (
              <div key={index}>
                {item?.newDay ? (
                  <div className="flex justify-center">
                    <div className="font-medium text-xs bg-white py-1.5 px-3 rounded-lg drop-shadow mt-3 mb-1">
                      {item?.newDay}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex ${item?.sender == uid ? "justify-end" : "justify-start"
                      }`}
                  >
                    {item?.sender == uid ? (
                      <div className="flex space-x-4 bg-[#B6E3FF] p-2 drop-shadow mt-2.5 max-w-[50%] rounded-b-lg rounded-tl-lg items-end">
                        <div className="text-sm">{item?.message || ""}</div>
                        <div className="text-xs font-medium text-gray-500">
                          {moment(item?.created)
                            .tz(user.timezone)
                            .format("hh:mm A")}
                        </div>
                        <img
                          src={TherapistMessageCorner}
                          className="absolute -right-1.5 top-0"
                        />
                      </div>
                    ) : (
                      <div className="flex space-x-4 bg-white p-2 drop-shadow mt-2.5 max-w-[50%] rounded-b-lg rounded-tr-lg items-end">
                        <div className="text-sm">{item?.message || ""}</div>
                        <div className="text-xs font-medium text-gray-500">
                          {moment(item?.created)
                            .tz(user.timezone)
                            .format("hh:mm A")}
                        </div>
                        <img
                          src={PatientMessageCorner}
                          className="absolute top-0 -left-[22px]"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {/* {!selectedPatient?.chat_permission && !emptyUserList ? (
          <div className="border-t border-t-solid py-5 px-4 lg:px-6 space-x-4">
            <div className="text-base text-[#FF9310] drop-shadow bg-[#FDF2E3] py-2.5 px-5 rounded-full border border-solid border-gray-200">{`${
              selectedPatient?.first_name || ""
            }'s package expired. This conversation will remain locked until the customer renews the package.`}</div>
          </div>
        ) : (  )} */}
        {refresh ? (
          <div className="py-2 px-4 lg:px-6 text-gray-500">
            {`You're currently offline. Please check your connection or try `}
            <span
              className="cursor-pointer text-teal-500"
              onClick={(e) => {
                e.preventDefault();
                window.location.reload();
              }}
            >
              refreshing the page
            </span>
          </div>
        ) : (
          <div className="border-t border-t-solid py-2 px-4 lg:px-6 flex items-center justify-between space-x-4">
            <input
              placeholder="Type a message"
              className="w-full rounded-full border-gray-200 outline-none h-12 px-3"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              onKeyDown={handleKeyPress}
            />
            <img
              onClick={() => (message ? sendText() : {})}
              src={SendIcon}
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
    );
  };

  const DefaultChatComponent = () => {
    return (
      <div className="flex-1 bg-blue-50 flex flex-col items-center justify-center">
        <img src={therapistDefaultChatIcon} />
        <div className="font-medium text-xl mt-6 mb-4 max-w-96 text-center">
          {emptyUserList
            ? "You’ll be able to view your Clients list on the left-hand side as soon as they purchase a package with you"
            : "Select a chat to appear here"}
        </div>
        {/* <div className="text-sm font-medium text-gray-500 w-80 text-center">
          Et commodi et ad qui quo reiciendis et. Veritatis dolor iure commodi.
          Et commodi iendis et.
        </div> */}
      </div>
    );
  };

  return (
    <>
      <MetaTags
        title="Chats | Fitcy Health"
        description="Manage your Fitcy Health chats"
        url="/therapist/messages"
      />
      <Header
        className="border-b border-gray-200 fixed z-10 bg-white"
        dashboard={true}
        app="THERAPIST"
      />
      <div className="flex lg:pl-24 pt-[4.5rem]">
        <SideMenu app="THERAPIST" className="left-0 lg:top-[4.45rem]" />
        {isMobile ? (
          <>{selectedPatient ? ChatComponent() : PatientListComponent()}</>
        ) : (
          <>
            {PatientListComponent()}
            {selectedPatient ? ChatComponent() : DefaultChatComponent()}
          </>
        )}
        <Modal
          isOpen={filterModal}
          onRequestClose={() => {
            setFilterModal(false);
          }}
          style={commonModalStyles}
          className={`${isMobile && filterModal
            ? "translate-y-0"
            : "translate-y-full lg:translate-y-0"
            } w-full transform transition-transform fixed bottom-0 outline-none`}
        >
          <div
            onClick={() => {
              setFilter("Newest");
              fetchChatList("", "Newest");
              setFilterModal(false);
            }}
            className={`text-base font-medium py-2 px-3 cursor-pointer ${filter == "Newest" ? "bg-gray-100" : "text-gray-500"
              }`}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setFilter("Oldest");
              fetchChatList("", "Oldest");
              setFilterModal(false);
            }}
            className={`text-base font-medium py-2 px-3 cursor-pointer ${filter == "Oldest" ? "bg-gray-100" : "text-gray-500"
              }`}
          >
            Oldest
          </div>
        </Modal>

        {/* <Modal
          isOpen={
            patientList?.length == 0 && searchText == "" && !isLoading
          }
          style={emptyChatStyles}
          className={`${
            isMobile ? "translate-y-0" : "translate-y-full lg:translate-y-0"
          } w-full transform transition-transform fixed bottom-0 outline-none`}
        >
          <div className="flex items-start justify-between">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.3229 20H37.6771C38.7314 20 39.6409 19.9999 40.3901 20.0611C41.1807 20.1257 41.9672 20.2684 42.7239 20.654C43.8529 21.2292 44.7708 22.1471 45.346 23.2761C45.7316 24.0328 45.8743 24.8193 45.9389 25.6099C46.0001 26.3591 46 27.2686 46 28.323V33.8925C46 34.7698 46 35.5268 45.9573 36.1533C45.9123 36.813 45.8133 37.4728 45.5433 38.1247C44.9343 39.5948 43.7663 40.7629 42.2961 41.3718C41.6442 41.6419 40.9844 41.7409 40.3248 41.7859C40.2201 41.793 40.1119 41.799 40 41.8039V44C40 44.7446 39.5864 45.4276 38.9265 45.7725C38.2666 46.1174 37.4697 46.0672 36.8583 45.6421L32.5055 42.6159C31.8351 42.1498 31.6994 42.0648 31.5713 42.0067C31.4235 41.9398 31.2682 41.8911 31.1087 41.8617C30.9703 41.8362 30.8104 41.8286 29.9938 41.8286H26.323C25.2686 41.8286 24.3591 41.8286 23.6099 41.7674C22.8193 41.7028 22.0328 41.5602 21.2761 41.1746C20.1471 40.5994 19.2292 39.6815 18.654 38.5525C18.2684 37.7958 18.1257 37.0093 18.0611 36.2186C17.9999 35.4695 18 34.56 18 33.5057V28.3229C18 27.2686 17.9999 26.3591 18.0611 25.6099C18.1257 24.8193 18.2684 24.0328 18.654 23.2761C19.2292 22.1471 20.1471 21.2292 21.2761 20.654C22.0328 20.2684 22.8193 20.1257 23.6099 20.0611C24.3591 19.9999 25.2686 20 26.3229 20Z"
                fill="#007BD2"
              />
              <path
                d="M28.4827 2H13.5175C11.9075 1.99998 10.5788 1.99996 9.49641 2.08839C8.37221 2.18024 7.33881 2.37737 6.36814 2.87195C4.86284 3.63894 3.63899 4.86278 2.87201 6.36808C2.37743 7.33875 2.1803 8.37216 2.08845 9.49635C2.00001 10.5787 2.00003 11.9075 2.00006 13.5174L2.00003 23.0417C1.99976 23.9249 1.99956 24.5794 2.08453 25.1595C2.5968 28.6567 5.34338 31.4033 8.8406 31.9155C8.98358 31.9365 9.07273 31.9901 9.11117 32.0222L9.11116 35.1777C9.11108 35.723 9.11101 36.2578 9.14905 36.6879C9.18284 37.0699 9.27135 37.8612 9.83359 38.517C10.4603 39.2481 11.3999 39.6339 12.3596 39.5541C13.2204 39.4826 13.8394 38.9818 14.1319 38.7337C14.2152 38.6631 14.3015 38.5859 14.3902 38.5038C14.205 37.7824 14.121 37.1133 14.0745 36.5444C13.9995 35.6273 13.9998 34.5677 14 33.5904V28.2383C13.9998 27.261 13.9995 26.2014 14.0745 25.2843C14.1593 24.2462 14.3692 22.8748 15.09 21.4601C16.0487 19.5785 17.5785 18.0487 19.4601 17.09C20.8748 16.3692 22.2462 16.1593 23.2843 16.0745C24.2014 15.9995 25.261 15.9998 26.2383 16H37.7618C38.4932 15.9999 39.2707 15.9997 40.0001 16.031V13.5174C40.0001 11.9074 40.0001 10.5787 39.9117 9.49635C39.8198 8.37216 39.6227 7.33875 39.1281 6.36808C38.3611 4.86278 37.1373 3.63894 35.632 2.87195C34.6613 2.37737 33.6279 2.18024 32.5037 2.08839C31.4213 1.99996 30.0926 1.99998 28.4827 2Z"
                fill="#007BD2"
              />
            </svg>
            <img
              className="cursor-pointer"
              src={CloseIcon}
              onClick={handleRoute}
            />
          </div>
          <div className="text-lg font-medium my-2">
            Your messages will appear here
          </div>
          <div className="text-sm font-medium text-gray-500">
            Once you get matched with a patient, you can text them from here
          </div>
          <div className="space-y-1 mt-4">
            <div className="flex items-center space-x-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6663 5L7.49967 14.1667L3.33301 10"
                  stroke="#007BD2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm font-medium">End-to-end encrypted</div>
            </div>
            <div className="flex items-center space-x-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6663 5L7.49967 14.1667L3.33301 10"
                  stroke="#007BD2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm font-medium">
                Unlimited texts for the duration of the package validity
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6663 5L7.49967 14.1667L3.33301 10"
                  stroke="#007BD2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm font-medium">
                Available instantly after matching with therapist
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-14">
            <div
              onClick={() =>
                window.open(`https://wa.me/${whatsappClientNumber}`)
              }
              className="text-gray-500 text-sm font-medium py-2.5 px-2 lg:px-5 cursor-pointer"
            >
              Talk to our support
            </div>
          </div>
        </Modal> */}
      </div>
    </>
  );
};
export default TherapistMessages;
