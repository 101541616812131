import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import logo from "../../assets/logo.png";
// import { ThreeDots } from "react-loader-spinner";

const Loader = () => {
  const fitycyUser = useSelector((state) => state.app.user);
  const location = useLocation();
  const loaderColorChange = location.pathname.split("/")[1]
  const isTherapistLoader = fitycyUser?.role == 'THERAPIST'
  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
      <div className={`border-t-4 ${(loaderColorChange === "therapist" || isTherapistLoader) ? "border-blue-900" : "border-teal-900"} border-solid rounded-full h-20 w-20 animate-spin`}></div>
    </div>
    // <div className="loader">
    //   <img alt="Logo" width="250px" src={logo} />
    //   <ThreeDots color="black" height={80} width={80} />
    // </div>
  );
};
export default Loader;
