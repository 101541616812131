import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Link from "../atoms/Link";
import { useNavigate } from "react-router-dom";
import Heading from "../atoms/Heading";
import Button from "../atoms/Button";
import Card from "../atoms/Card";
import Tooltip from "../atoms/Tooltip";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

import therapist from "../../assets/onboarding/therapist.png";
import resourceMessageIcon from "../../assets/resource_message_icon.svg";
import resourceInsightIcon from "../../assets/resource_insight_icon.svg";
//import author01 from "../../assets/onboarding/author01.png";
import depression from "../../assets/onboarding/depression.svg";
import users from "../../assets/onboarding/users.svg";
import clock from "../../assets/onboarding/clock-check.svg";
import Info from "../../assets/info-circle.svg";
import author from "../../assets/gaston-molina.png";
import star from "../../assets/onboarding/starIcon.svg";
import creditCard from "../../assets/onboarding/credit-card.svg";
import shieldZap from "../../assets/onboarding/shield-zap.svg";
import videoRecorder from "../../assets/onboarding/video-recorder.svg";
//import PlayButton from "../../assets/onboarding/play-circle.svg";
import PlayButtonCircle from "../../assets/play-circle.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "swiper/css";

function truncateText(text, maxLines, maxWords) {
  const lines = text.split("\n");
  let truncatedText = lines.slice(0, maxLines).join("\n");
  const words = truncatedText.split(" ");

  if (words.length > maxWords) {
    truncatedText = words.slice(0, maxWords).join(" ") + "...";
  }

  return truncatedText;
}

const ResourceCenter = ({ onAssessmentClick, retake }) => {
  const user = useSelector((state) => state.app.user);
  const navigate = useNavigate();
  localStorage.removeItem("hasUpdatedFitcyTimeZone");
  const questionnaireToUse = useSelector(
    (state) => state.onboarding.questionnaire_dict
  );
  const assessmentTest = questionnaireToUse?.filter(
    (record) => record.type === "ASSESSMENT"
  );
  const questionnaire = questionnaireToUse?.filter(
    (record) => record.type === "QUESTIONNAIRE"
  );
  const [postData, setPostData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoSource, setCurrentVideoSource] = useState(false);
  const [visibleVideos, setVisibleVideos] = useState(4);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const fetchPost = async () => {
    try {
      const res = await fetch(
        "https://fitcyhealth.com/wp-json/wp/v2/posts/?_embed&per_page=5"
      );
      const data = await res.json();
      setPostData(data);
    } catch (error) {
      console.error("Error fetching Blog data:", error);
    }
  };
  useEffect(() => {
    fetchPost();
    document.body.classList.remove("overflow-hidden");
  }, []);
  const openModal = (videoSource) => {
    setCurrentVideoSource(videoSource);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const platformVideos = [
    {
      videoId: "2GwLzZOTQBw",
      title: "How to reschedule a session?",
    },
    {
      videoId: "wRfEth9t2qc",
      title: "How to cancel a session?",
    },
    {
      videoId: "nC3_9ak_Lxs",
      title: "How to renew package?",
    },
    {
      videoId: "hrqRhF6hFOc",
      title: "How to navigate Sessions?",
    },
    {
      videoId: "-WePsJ9M2IY",
      title: "How can I update my details?",
    },
    {
      videoId: "rXK0OED3nao",
      title: 'How to fix "No mic or webcam detected" issue?',
    },
  ];
  // Function to show all videos
  const showMoreVideos = () => {
    setVisibleVideos(platformVideos.length);
  };
  const showLessVideos = () => {
    setVisibleVideos(4);
  };

  return (
    <div className="bg-cover">
      <div className="container mx-auto px-4 lg:px-16">
        <section className="pt-10">
          <Heading variant="h1" className="text-lg lg:text-3xl">
            Welcome, {user.first_name} {user.last_name} 👋
          </Heading>
          <div className="flex flex-col lg:flex-row mt-4 lg:mt-8 lg:space-x-5">
            <Card className="p-4 lg:p-6 bg-white w-full lg:w-4/5">
              <div className="lg:flex justify-between">
                <div>
                  <Heading variant="h2" className="text-lg font-medium">
                    Explore Fitcy Health
                  </Heading>
                  <p className="text-sm text-gray-500 font-normal mt-2">
                    Navigate your journey to a healthier mind with personalized
                    support
                  </p>
                </div>
                <Button
                  className="bg-teal-900 hover:shadow-button hover:bg-teal-500 text-white transition-all flex justify-between space-x-5 items-center rounded-xl w-full lg:w-auto mt-4 lg:mt-0"
                  onClick={() =>
                    questionnaire?.length > 0
                      ? onAssessmentClick(questionnaire?.[0])
                      : {}
                  }
                >
                  {user?.assessment_questionnaire
                    ? "View all therapists"
                    : "Get matched , Instantly!"}
                  {/* <span className="flex flex-col items-start">
                    <span className="text-white font-medium">
                      Try Free Intro Session
                    </span>
                    <span className="text-teal-100 text-xs">
                      No card required!
                    </span>
                  </span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16675 10H15.8334M15.8334 10L10.0001 4.16667M15.8334 10L10.0001 15.8333"
                      stroke="#D8EEEC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg> */}
                </Button>
              </div>
              <div className="grid lg:grid-cols-3 gap-4 mt-6 lg:mt-10">
                <Card className="p-6 flex justify-center flex-col items-center rounded-lg shadow-card">
                  <img src={therapist} className="max-h-24" />
                  <p className="flex items-center space-x-2 mt-6">
                    <span className="font-medium text-sm flex-1">
                      Therapy at your fingertips with virtual sessions
                    </span>
                    <span
                      onClick={() => navigate("/sessions")}
                      className="w-7 h-7 rounded-full bg-teal-50 flex justify-center items-center cursor-pointer"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4624 6H10.5365M10.5365 6L5.99944 1.46296M10.5365 6L5.99944 10.537"
                          stroke="#009688"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </p>
                </Card>
                <Card className="p-6 flex justify-center flex-col items-center rounded-lg shadow-card">
                  <img src={resourceMessageIcon} className="max-h-24" />
                  <p className="flex items-center space-x-5 mt-6">
                    <span className="font-medium text-sm flex-1">
                      Message your therapist for quick responses
                    </span>
                    <span
                      onClick={() => navigate("/messages")}
                      className="w-7 h-7 rounded-full bg-teal-50 flex justify-center items-center cursor-pointer"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4624 6H10.5365M10.5365 6L5.99944 1.46296M10.5365 6L5.99944 10.537"
                          stroke="#009688"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </p>
                </Card>
                <Card className="p-6 flex justify-center flex-col items-center rounded-lg shadow-card">
                  <img src={resourceInsightIcon} className="max-h-24" />
                  <p className="flex items-center space-x-5 mt-6">
                    <span className="font-medium text-sm flex-1">
                      Track progress and gain control by insights
                    </span>
                    <span
                      onClick={() => navigate("/home")}
                      className="w-7 h-7 rounded-full bg-teal-50 flex justify-center items-center cursor-pointer"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4624 6H10.5365M10.5365 6L5.99944 1.46296M10.5365 6L5.99944 10.537"
                          stroke="#009688"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </p>
                </Card>
              </div>
            </Card>
            <div className="bg-teal-300 p-3 rounded-2xl lg:w-80 mt-6 lg:mt-0">
              <Card className="p-4 bg-white h-full shadow-card">
                <Swiper
                  modules={[Autoplay, Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className="pagination-swiper"
                >
                  <SwiperSlide>
                    <img src={star} />
                    <Heading variant="h3" className="font-medium mt-3 text-lg">
                      What if I&apos;m not happy with the therapist I am matched
                      with?
                    </Heading>
                    <p className="text-gray-500 text-sm mt-3">
                      If you don&apos;t feel comfortable with the matched
                      therapist, you can switch to a different therapist
                      <span className="font-bold text-gray-900">
                        {" "}
                        for free!
                      </span>
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={creditCard} />
                    <Heading variant="h3" className="font-medium mt-3 text-lg">
                      Will I ever be charged automatically without my knowledge?
                    </Heading>
                    <p className="text-gray-500 text-sm mt-3">
                      You won&apos;t be charged automatically, we will inform
                      you when your credits are expiring. It&apos;s your
                      decision to continue your package subscription.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={shieldZap} />
                    <Heading variant="h3" className="font-medium mt-3 text-lg">
                      Is my personal data & medical history secure in Fitcy
                      Health?
                    </Heading>
                    <p className="text-gray-500 text-sm mt-3">
                      All your personal data & communication with therapist is
                      secured with 256-bit end-to-end encryption.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={videoRecorder} />
                    <Heading variant="h3" className="font-medium mt-3 text-lg">
                      Can I get a free introductory session?
                    </Heading>
                    <p className="text-gray-500 text-sm mt-3">
                      Yes, you can get a taste of our service with a free
                      introduction session. Experience the expertise and care of
                      our therapists at no cost.
                    </p>
                  </SwiperSlide>
                </Swiper>
              </Card>
            </div>
          </div>
        </section>
        <section className="mt-12 lg:mt-16">
          <Heading variant="h3" className="text-lg font-medium">
            Learn the basics about the Platform
          </Heading>
          <p className="text-sm text-gray-500">
            Watch these quick videos to learn how to use our platform
            effectively.
          </p>

          <div className="flex py-6 flex-wrap lg:gap-6 gap-2 items-center">
            {platformVideos.slice(0, visibleVideos).map((video) => (
              <div
                key={video.videoId}
                className="lg:w-[calc(25%-18px)] w-[calc(50%-5px)] p-3 border border-gray-100 rounded-[60px] shadow-card box-border"
              >
                <div
                  className="flex gap-3 items-center cursor-pointer"
                  onClick={() =>
                    openModal(
                      `https://www.youtube.com/embed/${video.videoId}?list=PLbsq3XaKO81_GmPTdJE8KwsxMUE6onEH_`
                    )
                  }
                >
                  <div className="lg:w-14 w-10">
                    <div className="rounded-full border border-teal-500 p-0.5 lg:w-14 lg:h-14 w-8 h-8 overflow-hidden relative">
                      <img
                        src={`https://img.youtube.com/vi/${video.videoId}/maxresdefault.jpg`}
                        alt="Custom Thumbnail"
                        className="h-full object-cover w-full rounded-full"
                      />
                      <div className="absolute top-0 bottom-0 right-0 left-0 m-auto lg:w-10 lg:h-10 w-3 h-3">
                        <img src={PlayButtonCircle} />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:line-clamp-none line-clamp-2 ">
                    <p className="text-gray-900 lg:text-sm text-xs font-medium lg:leading-4.5 leading-3.5">
                      {video.title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {visibleVideos < platformVideos.length ? (
            <button
              onClick={showMoreVideos}
              className="text-teal-500 text-sm font-normal shadow-none mx-auto block"
            >
              View More
            </button>
          ) : (
            <button
              onClick={showLessVideos}
              className="text-teal-500 text-sm font-normal shadow-none mx-auto block"
            >
              View Less
            </button>
          )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="flex items-center justify-center h-full"
            overlayClassName="fixed inset-0 bg-black/10 backdrop-blur-sm z-20 transition-all"
          >
            <div className="relative">
              <div className="rounded-2xl overflow-hidden">
                <iframe
                  className="max-w-full"
                  title="Embedded YouTube Video"
                  width="872"
                  height="490"
                  src={currentVideoSource}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>

              <button
                onClick={closeModal}
                className="w-9 h-9 bg-white border border-gray-200 rounded-full flex justify-center items-center absolute -top-12 right-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M12.6665 4.66699L4.6665 12.667M4.6665 4.66699L12.6665 12.667"
                    stroke="#667085"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </Modal>
        </section>
        <section className="mt-12 lg:mt-16">
          <Heading variant="h3" className="text-lg font-medium">
            Explore Our Top Blogs: Insights, Tips, and Expert Advice
          </Heading>
          <p className="text-sm text-gray-500">
            Discover valuable insights and tips in our top 5 featured blogs.
          </p>

          <div className="">
            <div className="mt-4">
              {postData?.length > 0 ? (
                <>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{
                      prevEl: `.owl-prev`,
                      nextEl: `.owl-next`,
                    }}
                    className="w-full"
                    slidesPerView={2}
                    spaceBetween={25}
                    slidesPerGroup={2}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                      },
                      768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                    }}
                  // onSlideChange={() => console.log("slide change")}
                  // onSwiper={(swiper) => console.log(swiper)}
                  >
                    {postData.map((post) => (
                      <SwiperSlide key={post.id}>
                        <Card className="px-4 pt-6 pb-16 lg:px-6 lg:pb-4 bg-white lg:w-1/1">
                          <Link
                            href={post.link}
                            rel="noreferrer"
                            target="_blank"
                            className="text-gray-900"
                          >
                            <div className="flex items-start space-x-4 lg:space-x-9">
                              <div className="flex-1">
                                <Heading
                                  variant="h3"
                                  className="text-sm lg:text-base font-medium"
                                >
                                  {post.title.rendered}
                                </Heading>
                                <div
                                  className="text-xs lg:text-sm text-gray-500 mt-2 lg:mt-4"
                                  dangerouslySetInnerHTML={{
                                    __html: truncateText(
                                      post.excerpt.rendered,
                                      3,
                                      20
                                    ), // Adjust the number of lines and words as needed
                                  }}
                                />
                              </div>
                              <div className="w-32 lg:w-50 relative">
                                <img
                                  className="rounded-2xl w-full"
                                  src={
                                    post._embedded["wp:featuredmedia"][0]
                                      .source_url
                                  }
                                />
                                <span className="text-teal-900 text-xs font-semibold absolute bottom-2 left-2 bg-teal-50 px-1.5 py-0.5 rounded-xl">
                                  2 mins read
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 lg:mt-8 flex items-center space-x-2">
                              <img
                                src={
                                  author ??
                                  post._embedded.author[0].avatar_urls[24]
                                }
                                className="w-6 h-6 rounded-full"
                              />
                              <div className="flex-1">
                                <Heading variant="h4" className="text-sm">
                                  {post._embedded.author[0].name}
                                </Heading>
                                <p className="text-gray-500 text-xs">
                                  Verified Clinical Therapist @fitcy
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="justify-center w-auto lg:right-20 cursor-pointer flex space-x-4 z-[9] my-4">
                    <div className="owl-prev stroke-teal-500 rotate-180 w-8 h-8 rounded-full flex justify-center items-center shadow border border-gray-200">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4624 6H10.5365M10.5365 6L5.99944 1.46296M10.5365 6L5.99944 10.537"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="owl-next  stroke-teal-500 w-8 h-8 rounded-full flex justify-center items-center shadow border border-gray-200">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4624 6H10.5365M10.5365 6L5.99944 1.46296M10.5365 6L5.99944 10.537"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              ) : (
                <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
                  <div className="flex items-start space-x-4 lg:space-x-10">
                    <div className="flex-1">
                      <Skeleton count={1} />
                      <div className="mt-2 lg:mt-4">
                        <Skeleton count={4} />
                      </div>
                    </div>

                    <Skeleton
                      count={1}
                      height={isMobile ? 85 : 133}
                      width={isMobile ? 128 : 200}
                    />
                  </div>
                  <div className="mt-3 lg:mt-9 flex items-center space-x-2">
                    <Skeleton count={1} width={24} height={24} circle />
                    <div className="flex-1">
                      <Skeleton count={2} />
                    </div>
                  </div>
                </SkeletonTheme>
              )}
            </div>
          </div>
        </section>
        {questionnaire?.[0]?.complete ? (
          <section className="mt-12 lg:mt-16">
            {assessmentTest?.length > 0 ? (
              <>
                <Heading
                  variant="h3"
                  className="text-lg font-medium flex space-x-1 items-center"
                >
                  <span>Free Mental Health Assessment Tests</span>
                  <div className="relative z-10 group">
                    <img src={Info} className="cursor-pointer" />
                    <Tooltip
                      right="-right-4 after:right-4 before:right-3.5"
                      direction={`${isMobile ? "left" : "auto"}`}
                    >
                      Take free, quick tests to assess your mental health
                    </Tooltip>
                  </div>
                </Heading>
                <p className="text-sm text-gray-500">
                  Unlock insights into your well-being with our free mental
                  health assessment tests
                </p>
              </>
            ) : null}

            <div className="grid lg:grid-cols-4 mt-4 gap-6">
              {assessmentTest?.length > 0 &&
                assessmentTest?.map((item, index) =>
                  item.resource_center_visibility ? (
                    <Card className="p-6 bg-white relative" key={index}>
                      <img src={depression} className="w-6 h-6" />
                      <Heading variant="h3" className="mt-6 font-medium">
                        {item.name}
                      </Heading>
                      <p className="text-gray-500 flex space-x-4 mt-6">
                        <span className="flex items-center space-x-2">
                          <img src={users} className="w-6 h-6" />
                          <span className="font-normal text-xs">
                            {item.performed_count + 100} people took the test
                          </span>
                        </span>
                        <span className="flex items-center space-x-2">
                          <img src={clock} className="w-6 h-6" />
                          <span className="font-normal text-xs">
                            {item.assessment_duration_mins} min
                          </span>
                        </span>
                      </p>
                      <div
                        className={`flex space-x-2 ${item?.complete && "justify-end"
                          }`}
                      >
                        {item?.complete && (
                          <div>
                            <div className="px-3 py-1.5 border border-gray-200 text-xs font-medium rounded-lg absolute top-6 right-6">
                              Completed
                            </div>
                            <Button
                              className="bg-teal-100 hover:shadow-button hover:bg-teal-900 text-teal-900 hover:text-white transition-all mt-6"
                              onClick={() => retake(item)}
                              small
                            >
                              Retake
                            </Button>
                          </div>
                        )}
                        <Button
                          className="bg-teal-900 hover:shadow-button hover:bg-teal-500 transition-all mt-6 text-white"
                          onClick={() => onAssessmentClick(item)}
                          small
                        >
                          {item?.complete ? "View Results" : "Take Test"}
                        </Button>
                      </div>
                    </Card>
                  ) : null
                )}
            </div>
          </section>
        ) : null}
      </div>
    </div>
  );
};
export default ResourceCenter;
