const BASE_URL = process.env.REACT_APP_API_URL;
console.log("API LINK = " + BASE_URL);

const simulateLogout = () => {
  localStorage.removeItem("fitcyAccessToken");
  localStorage.removeItem("fitcyRefreshToken");
  location.reload();
};

const accessRefreshCycle = async () => {
  const accessResponse = await apiPost("/api/token/verify/", {
    token: localStorage.getItem("fitcyAccessToken"),
  });
  if (accessResponse.status !== 200) {
    const refreshResponse = await apiPost("/api/token/refresh/", {
      refresh: localStorage.getItem("fitcyRefreshToken"),
    });
    if (refreshResponse.status === 200) {
      const responseJSON = await refreshResponse.json();
      localStorage.setItem("fitcyAccessToken", responseJSON.access);
      return true;
    } else {
      return false;
    }
  } else return true;
};

export const apiGet = async (url) => {
  const response = await fetch(BASE_URL + url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });

  return await response;
};

export const apiPost = async (url, data, headers = {}) => {
  const response = await fetch(BASE_URL + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      ...headers,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return response;
};

export const apiPostAuthenticated = async (url, data) => {
  if (!accessRefreshCycle()) {
    simulateLogout();
  }

  const response = await fetch(BASE_URL + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return await response;
};

export const apiPutAuthenticated = async (url, data) => {
  if (!accessRefreshCycle()) {
    simulateLogout();
  }
  // Create a FormData object
  const formData = new FormData();

  // Append the file to the FormData object if it exists
  if (data.file) {
    formData.append("file", data.file); // Assuming 'file' is the key for your file data
  }
  if (data.certifications) {
    let certifications = data.certifications;
    for (let i = 0; i < certifications.length; i++) {
      formData.append("certifications", certifications[i]);
    }
  }
  if (data.licenses) {
    let licenses = data.licenses;
    for (let i = 0; i < licenses.length; i++) {
      formData.append("licenses", licenses[i]);
    }
  }

  if (data.medical_documents) {
    let documents = data.medical_documents;
    for (let i = 0; i < documents.length; i++) {
      formData.append("medical_documents", documents[i]);
    }
  }

  // Append other non-file data directly to FormData
  for (const [key, value] of Object.entries(data)) {
    if (key !== "file" && key !== "certifications") {
      formData.append(key, value);
    }
  }

  const response = await fetch(BASE_URL + url, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: formData,
  });

  return await response;
};

export const apiGetAuthenticated = async (url) => {
  const result = await accessRefreshCycle();
  if (result === false) {
    simulateLogout();
  }

  const response = await fetch(BASE_URL + url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return await response;
};

export const apiDeleteAuthenticated = async (url, data) => {
  const result = await accessRefreshCycle();
  if (result === false) {
    simulateLogout();
  }

  const response = await fetch(BASE_URL + url, {
    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data),
  });
  return await response;
};

export const apiPatchAuthenticated = async (url, data) => {
  if (!accessRefreshCycle()) {
    simulateLogout();
  }

  const response = await fetch(BASE_URL + url, {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return await response;
};

export const apiPatchFileAuthenticated = async (url, data) => {
  if (!accessRefreshCycle()) {
    simulateLogout();
  }

  const response = await fetch(BASE_URL + url, {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      Authorization: `JWT ${localStorage.getItem("fitcyAccessToken")}`,
    },
    body: data, // body data type must match "Content-Type" header
  });

  return await response;
};
