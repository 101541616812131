import "./services/sentry/instrument"; //Sentry initialization should be imported first!
import "./services/mixpanel/mixpanel"; 
import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Modal from "react-modal";
import Wrapper from "./components/atoms/Wrapper";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RTMClientProvider } from "./contexts/RTMClientContext";

//import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";
Modal.setAppElement("#root");
const container = document.getElementById("root");
const root = createRoot(container);
//Sentry.init({
//  dsn: process.env.REACT_APP_SENTRY_DSN,
//  integrations: [new BrowserTracing()],

// We recommend adjusting this value in production, or using tracesSampler
// for finer control
//  tracesSampleRate: 1.0,
root.render(
  <Provider store={store}>
    <RTMClientProvider>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_ID}>
          <Wrapper>
            <App />
          </Wrapper>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </RTMClientProvider>
  </Provider>
);
