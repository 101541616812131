import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MetaTags,
  calculateTimeDifference,
  convertToInitialCase,
  formatBytes,
  getNotificationIcon,
} from "../../services/globalFunctions";
import Header from "../../components/molecules/Header";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import backgroundCover from "../../assets/cover-bg.png";
import Heading from "../../components/atoms/Heading";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PatientProfileForm from "../../components/organisms/PatientProfileForm";
import "react-accessible-accordion/dist/fancy-example.css";
import editIcon from "../../assets/edit-02.svg";
import NoDocuments from "../../assets/no_documents.svg";
import Modal from "react-modal";
import { submitPatientProfile } from "../../redux/patient/patientThunks";
import { useMediaQuery } from "react-responsive";
import Avatar from "../../assets/profile-picture-avatar.png";
import emptyNotificationList from "../../assets/empty_notification_list.svg";

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { year: "numeric", month: "short" };
  return dateObject.toLocaleString("en-US", options);
};
const formatToLocaleDateString = (dateString) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
};

const PatientProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const notifications = useSelector((state) => state.onboarding.notifications);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalContent, setModalContent] = useState("");
  const [imageUrl, setImageUrl] = useState(
    user?.profile_picture?.full_url || null
  );
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    document.body.classList.remove("overflow-hidden");
  }, []);

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Use FileReader to display the selected image
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageUrl = event.target.result;
        setImageUrl(imageUrl);
      };
      reader.readAsDataURL(file);
      dispatch(
        submitPatientProfile({
          profile_picture: file,
        })
      );
    }
  };

  const customStyles = {
    content: {
      maxWidth: "1024px",
      width: isMobile ? "calc(100% - 2rem)" : "100%",
      left: 0,
      right: 0,
      margin: "auto",
      border: "none",
      borderRadius: "16px",
      padding: "0",
      maxHeight: "calc(100% - 6rem)",
      height: "auto",
      bottom: "auto",
      // top: "10rem",
    },
    overlay: {
      zIndex: "30",
      background: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <>
      <MetaTags
        title="Profile Settings | Fitcy Health"
        description="Manage your Fitcy Health profile"
        url="/profile"
      />
      <Header
        className="border-b border-gray-200 fixed z-30 bg-white"
        backButton={true}
        section="profile"
      />
      <div
        className={`pt-28 pb-24 bg-cover`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="container mx-auto max-w-screen-xl px-4">
          <Heading
            variant="h1"
            heading="Profile"
            className="font-medium text-2xl lg:text-3xl pb-5 border-b border-gray-200"
          />
          <div
            className={`mt-10 bg-no-repeat lg:aspect-[5.79/1] h-32 lg:h-auto w-full`}
            style={{
              backgroundImage: `url(${backgroundCover})`,
              backgroundSize: isMobile ? "100% 100%" : "100% auto",
            }}
          ></div>
          <div className="px-4 lg:px-10 relative">
            <div className="-mt-16 lg:-mt-24 relative w-24 h-24 lg:w-32 lg:h-32">
              <img
                src={imageUrl ?? Avatar}
                alt="Selected Image"
                className="w-24 h-24 lg:w-32 lg:h-32 rounded-full border-2 border-white/80 object-contain bg-gray-100"
              />

              <label htmlFor="dropzone-file">
                <div className="bg-gray-100 rounded-full border border-gray-200 p-1.5 absolute bottom-1 right-0 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      opacity="0.12"
                      d="M15.75 5.74998C16.5784 4.92156 16.5784 3.57841 15.75 2.74998C14.9216 1.92156 13.5784 1.92156 12.75 2.74998L10.5 4.99998L13.5 7.99998L15.75 5.74998Z"
                      fill="#009688"
                    />
                    <path
                      d="M13.5 7.99998L10.5 4.99998M1.875 16.625L4.41328 16.343C4.72339 16.3085 4.87845 16.2913 5.02338 16.2443C5.15197 16.2027 5.27434 16.1439 5.38717 16.0695C5.51434 15.9856 5.62466 15.8753 5.84529 15.6547L15.75 5.74998C16.5784 4.92156 16.5784 3.57841 15.75 2.74998C14.9216 1.92156 13.5784 1.92156 12.75 2.74998L2.8453 12.6547C2.62466 12.8753 2.51434 12.9856 2.43048 13.1128C2.35607 13.2256 2.29726 13.348 2.25564 13.4766C2.20872 13.6215 2.19149 13.7766 2.15703 14.0867L1.875 16.625Z"
                      stroke="#009688"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <input
                  id="dropzone-file"
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </label>
            </div>
            <div className="flex flex-col-reverse lg:flex-row lg:items-center justify-between mt-4 lg:mt-0">
              <div>
                <div className="font-medium text-xl flex space-x-2 lg:mt-6">
                  <span>
                    {user?.first_name} {user?.last_name}{" "}
                  </span>
                  {user?.is_active && (
                    <span className="flex items-center px-2 py-1.5 text-xs text-teal-500 font-semibold bg-teal-50 rounded-2xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="9"
                        viewBox="0 0 8 9"
                        fill="none"
                        className="mr-1.5"
                      >
                        <circle cx="4" cy="4.5" r="3" fill="#009688" />
                      </svg>
                      Active
                    </span>
                  )}
                </div>
                <div className="flex items-center space-x-2 lg:mt-1.5 text-gray-500">
                  <span>{convertToInitialCase(user?.gender)}</span>
                  {user?.preferred_languages &&
                    Object.keys(user?.preferred_languages)?.length > 0 && (
                      <>
                        <span className="w-1 h-1 rounded-full block bg-gray-500"></span>
                        <span>
                          {Object.values(user?.preferred_languages)[0].label}
                        </span>
                      </>
                    )}
                </div>
              </div>
              <div
                className={`absolute right-2 top-3 lg:static flex space-x-1 lg:space-x-2 p-1 bg-gray-100 rounded-xl lg:w-48 items-center border border-gray-200`}
              >
                {user?.my_therapists?.[0]?.profile_picture?.full_url ? (
                  <img
                    src={user?.my_therapists?.[0]?.profile_picture?.full_url}
                    className="w-8 h-8 rounded-lg"
                  />
                ) : (
                  <div className="flex rounded-lg bg-white p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99988 12.4544C7.40618 12.4544 5.09962 13.7067 3.63115 15.6502C3.31509 16.0685 3.15706 16.2776 3.16223 16.5603C3.16623 16.7787 3.30335 17.0541 3.47518 17.189C3.69758 17.3635 4.00577 17.3635 4.62216 17.3635H15.3776C15.994 17.3635 16.3022 17.3635 16.5246 17.189C16.6964 17.0541 16.8335 16.7787 16.8375 16.5603C16.8427 16.2776 16.6847 16.0685 16.3686 15.6502C14.9001 13.7067 12.5936 12.4544 9.99988 12.4544Z"
                        stroke="#667085"
                        strokeWidth="1.63636"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99988 9.99987C12.0333 9.99987 13.6817 8.35146 13.6817 6.31805C13.6817 4.28464 12.0333 2.63623 9.99988 2.63623C7.96647 2.63623 6.31806 4.28464 6.31806 6.31805C6.31806 8.35146 7.96647 9.99987 9.99988 9.99987Z"
                        stroke="#667085"
                        strokeWidth="1.63636"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}
                <div>
                  <div className="text-xs text-gray-500">My Therapist</div>
                  <div className="text-xs lg:text-sm mt-0.5">
                    {user?.my_therapists?.length > 0
                      ? user?.my_therapists[0]?.first_name +
                      " " +
                      user?.my_therapists[0]?.last_name
                      : "Not Assigned"}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex flex-wrap gap-2">
                <div className="px-3 py-1.5 bg-gray-100 rounded-lg text-xs font-medium flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="mr-1.5"
                  >
                    <path
                      d="M14.3333 11.9998L9.90477 7.99984M6.09525 7.99984L1.6667 11.9998M1.33334 4.6665L6.77662 8.4768C7.2174 8.78535 7.43779 8.93962 7.67752 8.99938C7.88927 9.05216 8.11075 9.05216 8.3225 8.99938C8.56223 8.93962 8.78262 8.78535 9.2234 8.4768L14.6667 4.6665M4.53334 13.3332H11.4667C12.5868 13.3332 13.1468 13.3332 13.5747 13.1152C13.951 12.9234 14.2569 12.6175 14.4487 12.2412C14.6667 11.8133 14.6667 11.2533 14.6667 10.1332V5.8665C14.6667 4.7464 14.6667 4.18635 14.4487 3.75852C14.2569 3.3822 13.951 3.07624 13.5747 2.88449C13.1468 2.6665 12.5868 2.6665 11.4667 2.6665H4.53334C3.41324 2.6665 2.85319 2.6665 2.42536 2.88449C2.04904 3.07624 1.74308 3.3822 1.55133 3.75852C1.33334 4.18635 1.33334 4.7464 1.33334 5.8665V10.1332C1.33334 11.2533 1.33334 11.8133 1.55133 12.2412C1.74308 12.6175 2.04904 12.9234 2.42536 13.1152C2.85319 13.3332 3.41324 13.3332 4.53334 13.3332Z"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {user?.username}
                </div>
                <div className="px-3 py-1.5 bg-gray-100 rounded-lg text-xs font-medium flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="mr-1.5"
                  >
                    <g clipPath="url(#clip0_3273_4572)">
                      <path
                        opacity="0.12"
                        d="M4.92047 6.56874C5.38447 7.53515 6.01699 8.44091 6.81804 9.24197C7.6191 10.043 8.52486 10.6755 9.49127 11.1395C9.5744 11.1795 9.61596 11.1994 9.66856 11.2147C9.85546 11.2692 10.085 11.2301 10.2432 11.1167C10.2878 11.0849 10.3259 11.0467 10.4021 10.9705C10.6352 10.7375 10.7517 10.6209 10.8689 10.5447C11.3108 10.2574 11.8805 10.2574 12.3225 10.5447C12.4397 10.6209 12.5562 10.7375 12.7893 10.9705L12.9192 11.1005C13.2735 11.4547 13.4506 11.6319 13.5468 11.8221C13.7382 12.2005 13.7382 12.6474 13.5468 13.0257C13.4506 13.216 13.2735 13.3931 12.9192 13.7474L12.8141 13.8525C12.461 14.2056 12.2845 14.3822 12.0444 14.517C11.7781 14.6666 11.3644 14.7742 11.059 14.7733C10.7837 14.7724 10.5955 14.719 10.2192 14.6122C8.19695 14.0383 6.28871 12.9553 4.69672 11.3633C3.10474 9.7713 2.02176 7.86306 1.44778 5.84079C1.34097 5.4645 1.28757 5.27635 1.28675 5.00105C1.28584 4.69557 1.39341 4.28191 1.54303 4.01557C1.67786 3.77555 1.8544 3.59901 2.20748 3.24593L2.31257 3.14084C2.66687 2.78654 2.84401 2.60939 3.03427 2.51316C3.41264 2.32178 3.85949 2.32178 4.23786 2.51316C4.42811 2.60939 4.60526 2.78654 4.95956 3.14084L5.08947 3.27075C5.32254 3.50382 5.43908 3.62036 5.51527 3.73754C5.8026 4.17947 5.8026 4.74919 5.51527 5.19112C5.43908 5.3083 5.32254 5.42484 5.08947 5.65791C5.01326 5.73411 4.97516 5.77222 4.94326 5.81676C4.82992 5.97505 4.79079 6.20455 4.84527 6.39145C4.8606 6.44405 4.88056 6.48561 4.92047 6.56874Z"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.36673 4.00016C10.0179 4.12721 10.6163 4.44567 11.0854 4.91479C11.5546 5.38391 11.873 5.98234 12.0001 6.6335M9.36673 1.3335C10.7196 1.48379 11.9811 2.08961 12.9442 3.0515C13.9073 4.01339 14.5147 5.27417 14.6667 6.62683M6.81805 9.24221C6.01699 8.44116 5.38447 7.5354 4.92047 6.56898C4.88056 6.48586 4.8606 6.44429 4.84527 6.3917C4.79079 6.2048 4.82992 5.97529 4.94326 5.817C4.97516 5.77246 5.01326 5.73436 5.08947 5.65815C5.32254 5.42508 5.43908 5.30854 5.51527 5.19136C5.8026 4.74943 5.8026 4.17971 5.51527 3.73778C5.43908 3.6206 5.32254 3.50406 5.08947 3.27099L4.95956 3.14108C4.60526 2.78678 4.42811 2.60964 4.23786 2.51341C3.85949 2.32203 3.41264 2.32203 3.03427 2.51341C2.84402 2.60964 2.66687 2.78678 2.31257 3.14108L2.20748 3.24617C1.8544 3.59925 1.67786 3.77579 1.54303 4.01581C1.39341 4.28215 1.28584 4.69581 1.28675 5.00129C1.28757 5.27659 1.34097 5.46474 1.44778 5.84104C2.02176 7.8633 3.10474 9.77154 4.69672 11.3635C6.28871 12.9555 8.19695 14.0385 10.2192 14.6125C10.5955 14.7193 10.7837 14.7727 11.059 14.7735C11.3644 14.7744 11.7781 14.6668 12.0444 14.5172C12.2845 14.3824 12.461 14.2059 12.8141 13.8528L12.9192 13.7477C13.2735 13.3934 13.4506 13.2162 13.5468 13.026C13.7382 12.6476 13.7382 12.2008 13.5468 11.8224C13.4506 11.6321 13.2735 11.455 12.9192 11.1007L12.7893 10.9708C12.5562 10.7377 12.4397 10.6212 12.3225 10.545C11.8805 10.2577 11.3108 10.2577 10.8689 10.545C10.7517 10.6212 10.6352 10.7377 10.4021 10.9708C10.3259 11.047 10.2878 11.0851 10.2433 11.117C10.085 11.2303 9.85546 11.2695 9.66856 11.215C9.61596 11.1997 9.5744 11.1797 9.49127 11.1398C8.52486 10.6758 7.6191 10.0433 6.81805 9.24221Z"
                        stroke="#667085"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3273_4572">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {user?.cell_number}
                </div>

                <div className="px-3 py-1.5 text-xs font-medium text-gray-500">
                  Member since {formatDate(user?.date_joined)}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:px-10 mt-10">
            <div className="bg-gray-100 p-4 lg:p-6 rounded-3xl">
              <Tabs className="shadow-tabs">
                <TabList className="flex">
                  <Tab className="px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Basic Info
                  </Tab>
                  <Tab className="px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Health Record
                  </Tab>
                  <Tab className="px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Documents
                  </Tab>
                  <Tab className="px-2 lg:px-4 py-1.5 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs lg:text-base">
                    Activity Feed
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0 mt-6">
                    <div className="bg-white rounded-2xl p-4 lg:p-6 relative flex-1">
                      <Heading
                        variant="h2"
                        heading="Personal Details"
                        className="font-medium"
                      />
                      <div className="text-base text-gray-500">
                        {`Here you can provide your personal details.`}
                      </div>
                      <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Email</span>
                          <div className="mt-1">{user?.username}</div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">First Name</span>
                          <div className="mt-1">{user?.first_name}</div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Last Name</span>
                          <div className="mt-1">{user?.last_name}</div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Phone Number</span>
                          <div className="mt-1">{user?.cell_number}</div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Date of Birth</span>
                          <div className="mt-1">
                            {user?.date_of_birth || "N/A"}
                          </div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Gender</span>
                          <div className="mt-1">
                            {convertToInitialCase(user?.gender) || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Profession</span>
                          <div className="mt-1">
                            {user?.profession || "N/A"}
                          </div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Country</span>
                          <div className="mt-1">{user?.country || "N/A"}</div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">City</span>
                          <div className="mt-1">{user?.city || "N/A"}</div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Timezone</span>
                          <div className="mt-1">{user?.timezone || "N/A"}</div>
                        </div>
                      </div>
                      <button
                        onClick={() => openModal("Personal details")}
                        className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                      >
                        <img src={editIcon} />
                      </button>
                    </div>
                    <div className="bg-white rounded-2xl p-4 lg:p-6 relative flex-1">
                      <Heading
                        variant="h2"
                        heading="Emergency Contact"
                        className="font-medium"
                      />
                      <div className="text-base text-gray-500">
                        {`This is where you can provide contact details of someone close to you. In case it becomes necessary in future.`}
                      </div>
                      <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Name</span>
                          <div className="mt-1">
                            {user?.emergency_first_name || "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Email</span>
                          <div className="mt-1">
                            {user?.emergency_email || "N/A"}
                          </div>
                        </div>
                        <div className="py-4 lg:p-2 text-sm flex-1">
                          <span className="text-gray-500">Phone Number</span>
                          <div className="mt-1">
                            {user?.emergency_cell_number || "N/A"}
                          </div>
                        </div>
                      </div>

                      <button
                        onClick={() => openModal("Emergency contact")}
                        className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                      >
                        <img src={editIcon} />
                      </button>
                    </div>
                  </div>
                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Preferences"
                      className="font-medium"
                    />
                    <div className="text-base text-gray-500">
                      {`This is where you can choose your preference for the therapist you're looking for.`}
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">
                          Therapist Religion
                        </span>
                        <div className="mt-1">
                          {user?.religious_belief || "N/A"}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">
                          Therapist Languages
                        </span>
                        {user?.preferred_languages &&
                          Object.keys(user?.preferred_languages)?.length > 0 ? (
                          <div className="flex gap-1.5 p-1 border flex-wrap border-gray-200 rounded-lg mt-1">
                            {Object.keys(user?.preferred_languages).map(
                              (key) => (
                                <div
                                  key={key}
                                  className="px-2 py-0.5 bg-gray-100 rounded-lg text-sm font-medium border border-gray-200"
                                >
                                  {user?.preferred_languages[key]?.label}
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="mt-1">{"N/A"}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Therapist Gender</span>
                        <div className="mt-1">
                          {user?.preferred_therapists &&
                            user?.preferred_therapists != "undefined"
                            ? user?.preferred_therapists
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => openModal("Preferences")}
                      className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} />
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Health Record"
                      className="font-medium"
                    />
                    <div className="text-base text-gray-500">
                      {`This is where you can provide your health details.`}
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Height</span>
                        <div className="mt-1">{user?.height || "N/A"}</div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Weight</span>
                        <div className="mt-1">{user?.weight || "N/A"}</div>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Blood Group</span>
                        <div className="mt-1">
                          {user?.blood_group && user?.blood_group != "undefined"
                            ? user?.blood_group
                            : "N/A"}
                        </div>
                      </div>
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">
                          Medical Conditions
                        </span>
                        {user?.medical_conditions &&
                          Object.keys(user?.medical_conditions)?.length > 0 ? (
                          <div className="flex gap-1.5 p-1 border flex-wrap border-gray-200 rounded-lg mt-1">
                            {Object.keys(user?.medical_conditions).map(
                              (key) => (
                                <div
                                  key={key}
                                  className="px-2 py-0.5 bg-gray-100 rounded-lg text-sm font-medium border border-gray-200"
                                >
                                  {user?.medical_conditions[key]?.label}
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="mt-1">{"N/A"}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-4 lg:mt-6">
                      <div className="py-4 lg:p-2 text-sm flex-1">
                        <span className="text-gray-500">Active Medication</span>
                        <div className="mt-1">
                          {user?.medications && user?.medications != "null"
                            ? user?.medications
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => openModal("Health Record")}
                      className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                    >
                      <img src={editIcon} />
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    {user?.medical_documents?.length > 0 && (
                      <>
                        <Heading
                          variant="h2"
                          heading="Documents & Health Records"
                          className="font-medium"
                        />
                        <div className="text-base text-gray-500">
                          This is where you can submit documents related to your
                          Medical Conditions and Active Medications
                        </div>
                      </>
                    )}
                    <div className="lg:mt-6 text-sm">
                      {user?.medical_documents?.length > 0 ? (
                        <ul className="lg:mt-4 flex flex-col gap-4">
                          {user?.medical_documents?.map((item, index) => (
                            <li key={index}>
                              <div className="text-gray-400">
                                {formatToLocaleDateString(item?.created_at)}
                              </div>
                              <div className="flex flex-wrap gap-4 mt-1">
                                <div className="p-4 bg-gray-100 rounded-2xl text-sm text-gray-500 flex items-start justify-between w-full">
                                  <div className="flex space-x-4 items-center">
                                    {item?.full_url &&
                                      item?.media_mime?.includes("image") ? (
                                      <img
                                        src={item?.full_url}
                                        className={
                                          "w-10 h-10 rounded-lg object-contain bg-white"
                                        }
                                      />
                                    ) : (
                                      <div className="p-2 bg-white rounded-full">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="21"
                                          height="20"
                                          viewBox="0 0 21 20"
                                          fill="none"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M11.3333 1.67932C11.3333 1.6574 11.3333 1.64645 11.3324 1.62657C11.3127 1.21509 10.9509 0.853408 10.5394 0.833941C10.5195 0.833002 10.513 0.833004 10.5 0.833008H7.79888C7.1281 0.832999 6.57446 0.832992 6.12348 0.869838C5.65507 0.908109 5.22448 0.990246 4.82003 1.19632C4.19283 1.5159 3.68289 2.02584 3.36331 2.65304C3.15724 3.05749 3.0751 3.48807 3.03683 3.95649C2.99998 4.40748 2.99999 4.96111 3 5.6319V14.3674C2.99999 15.0382 2.99998 15.5919 3.03683 16.0429C3.0751 16.5113 3.15724 16.9419 3.36331 17.3463C3.68289 17.9735 4.19283 18.4835 4.82003 18.803C5.22448 19.0091 5.65507 19.0912 6.12348 19.1295C6.57447 19.1664 7.1281 19.1664 7.7989 19.1663H13.2011C13.8719 19.1664 14.4255 19.1664 14.8765 19.1295C15.3449 19.0912 15.7755 19.0091 16.18 18.803C16.8072 18.4835 17.3171 17.9735 17.6367 17.3463C17.8428 16.9419 17.9249 16.5113 17.9632 16.0429C18 15.5919 18 15.0382 18 14.3674V8.33296C18 8.32001 18 8.31354 17.9991 8.29369C17.9796 7.88216 17.6179 7.52027 17.2064 7.50063C17.1865 7.49968 17.1756 7.49968 17.1538 7.49968L13.4734 7.49969C13.2631 7.49971 13.0583 7.49974 12.8848 7.48556C12.6943 7.47 12.4695 7.43328 12.2434 7.31803C11.9298 7.15824 11.6748 6.90327 11.515 6.58967C11.3997 6.36348 11.363 6.1387 11.3475 5.94821C11.3333 5.77474 11.3333 5.56992 11.3333 5.3596L11.3333 1.67932ZM10.5 9.99968C10.9602 9.99968 11.3333 10.3728 11.3333 10.833V12.4997H13C13.4602 12.4997 13.8333 12.8728 13.8333 13.333C13.8333 13.7932 13.4602 14.1663 13 14.1663H11.3333V15.833C11.3333 16.2932 10.9602 16.6663 10.5 16.6663C10.0398 16.6663 9.66667 16.2932 9.66667 15.833V14.1663H8C7.53976 14.1663 7.16667 13.7932 7.16667 13.333C7.16667 12.8728 7.53976 12.4997 8 12.4997H9.66667V10.833C9.66667 10.3728 10.0398 9.99968 10.5 9.99968Z"
                                            fill="#009688"
                                          />
                                          <path
                                            d="M16.2566 5.833C16.5013 5.83301 16.6237 5.83301 16.7239 5.77157C16.8654 5.6848 16.95 5.48038 16.9112 5.31895C16.8838 5.20466 16.804 5.12495 16.6444 4.96554L13.8675 2.18861C13.7081 2.02904 13.6284 1.94925 13.5141 1.92178C13.3526 1.88298 13.1482 1.96759 13.0614 2.10912C13 2.20935 13 2.33169 13 2.57637L13 5.16631C13 5.39966 13 5.51633 13.0454 5.60546C13.0854 5.68386 13.1491 5.7476 13.2275 5.78755C13.3166 5.83296 13.4333 5.83296 13.6667 5.83297L16.2566 5.833Z"
                                            fill="#009688"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                    <div>
                                      {item?.media_name}{" "}
                                      <div>{formatBytes(item?.media_size)}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <div
                            onClick={() =>
                              openModal("Documents & health records")
                            }
                            className="items-center space-y-2 items-center flex flex-col my-3 cursor-pointer"
                          >
                            <img src={NoDocuments} />
                            <div className="text-base font-medium">
                              No documents uploaded yet
                            </div>
                            <div className="text-sm text-gray-400">
                              Click here to add one or more documents
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {user?.medical_documents?.length > 0 && (
                      <button
                        onClick={() => openModal("Documents & health records")}
                        className="shadow-none absolute right-4 lg:right-6 top-4 lg:top-6"
                      >
                        <img src={editIcon} />
                      </button>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="bg-white rounded-2xl p-4 lg:p-6 mt-6 relative">
                    <Heading
                      variant="h2"
                      heading="Activity Feed"
                      className="font-medium"
                    />
                    {notifications?.length > 0 ? (
                      <>
                        {notifications?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="flex space-x-3 items-start mt-2"
                            >
                              <img
                                src={getNotificationIcon(
                                  item?.notification_iconcode
                                )}
                              />
                              <div>
                                <div className="text-sm">
                                  {item?.notification_text || ""}
                                </div>
                                <div className="text-[10px] text-gray-500">
                                  {calculateTimeDifference(item?.created_at)}
                                </div>
                                <div className="flex items-center space-x-2 mt-1">
                                  {item?.notification_action?.map((i) => {
                                    return (
                                      <div
                                        className="text-sm font-medium text-teal-900 rounded-full bg-teal-100 py-1 px-2"
                                        key={i}
                                      >
                                        {i}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0">
                        <img src={emptyNotificationList} />
                        <div className="text-sm font-medium mt-4">
                          Your activity feed will appear here
                        </div>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </Tabs>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel={modalContent}
                style={customStyles}
              >
                <PatientProfileForm
                  target={modalContent}
                  setModalIsOpen={(value) => setModalIsOpen(value)}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientProfile;
