import React, { useState } from "react";
import Header from "../../components/molecules/Header";
import RegistrationForm from "../../components/organisms/RegistrationForm";
import { useSelector } from "react-redux";
import ProfileCompleted from "../../components/organisms/ProfileCompleted";
import { MetaTags } from "../../services/globalFunctions";

export default function TherapistOnboarding() {
  const user = useSelector((state) => state.app.user);
  const [stepCompleted, setStepCompleted] = useState(user.step_completed);
  const [step, setStep] = useState(1);

  // if (user.onboarding) window.location.replace("/therapist/sessions");

  const getStepAction = () => {
    if (step === 1)
      return (
        <RegistrationForm
          stepCompleted={stepCompleted}
          setStepCompleted={(value) => setStepCompleted(value)}
          setStep={(value) => setStep(value)}
        />
      );
    else return null;
  };

  return (
    !user.onboarding && (
      <>
        <MetaTags
          title="Get Started | Fitcy Health"
          description="Get started with your Fitcy Health profile"
          url="/therapist/onboarding"
        />
        <Header className="border-b border-gray-200 fixed z-30 bg-white" />
        <div
          className={`bg-blue-200 min-h-screen pt-[4.5rem] ${stepCompleted === 4
              ? "bg-no-repeat flex items-center bg-center lg:bg-top px-4"
              : ""
            }`}
        >
          {stepCompleted === 4 ? (
            <ProfileCompleted />
          ) : (
            <div className="w-full max-w-4xl mx-auto">{getStepAction()}</div>
          )}
        </div>
      </>
    )
  );
}
