import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Heading from "../atoms/Heading";
import session from "../../assets/session.png";
import Button from "../atoms/Button";
import {
  submitSession,
  getPackageDetails,
} from "../../redux/onboarding/onboardingThunks";
import CalendarIcon from "../../assets/calendar_icon.svg";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import cross from "../../assets/x-close.svg";
import ButtonLoader from "../atoms/ButtonLoader";

const ScheduleSession = ({
  setStep,
  reschedule,
  schedule,
  selectedTherapist,
  setSuccessData,
  expiryLimit,
  firstSession,
}) => {
  const user = useSelector((state) => state.app.user);
  const dispatch = useDispatch();
  const selectedPackage = useSelector(
    (state) => state.onboarding.selected_package
  );
  const selectedAppointment =
    selectedPackage?.data?.selected_appointment?.package;
  const [selectedDate, setSelectedDate] = useState(
    selectedAppointment?.start_time
      ? new Date(selectedAppointment?.start_time)
      : new Date()
  );
  const [selectedSlot, setSelectedSlot] = useState(
    selectedAppointment
      ? {
        start_time: selectedAppointment?.start_time,
        end_time: selectedAppointment?.end_time,
      }
      : null
  );

  const [slots, setSlots] = useState([]);
  const [nextSlotDay, setNextSlotday] = useState("");
  const [slotsModel, setSlotsModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    selectedPackage?.data?.schedule
      ?.filter((day) => day.date === formatDate(selectedDate))
      ?.flatMap((day) => setSlots(day?.slots));
    // const selectedArrayDate = selectedPackage?.data?.schedule?.filter(
    //   (day) => day.date === formatDate(selectedDate)
    // )[0]?.date;

    const filteredDates = selectedPackage?.data?.schedule.filter((item) => {
      return (
        // new Date(item.date) > new Date(selectedArrayDate) &&
        item.slots.length > 0
      );
    });

    setNextSlotday(filteredDates?.[0]?.date || "");
  }, [selectedPackage, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSlotsModel(true);
    // You may want to filter available slots based on the selected date
  };

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    // Calculate the start and end dates for the current view
    const startDate = activeStartDate;
    let endDate;

    if (view === "month") {
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    }

    // Format the dates as needed (YYYY-MM-DD)
    const formatDate = (date) => {
      return date.toISOString().split("T")[0];
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    const payload = expiryLimit ? { patient_id: user.id } : {};
    // Dispatch the action to fetch schedules
    dispatch(
      getPackageDetails({
        package_id: selectedPackage?.data?.package.id,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        ...payload,
      })
    );
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };
  const rescheduleParams = reschedule
    ? {
      appointment_id: reschedule.appointment_id,
      appointment: "reschedule",
    }
    : null;
  const scheduleParams = schedule
    ? {
      appointment: "schedule",
    }
    : null;
  const handleConfirmBooking = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        submitSession({
          package_id: selectedPackage?.data?.package.id,
          selected_slot: {
            ...selectedSlot,
            ...rescheduleParams,
            ...scheduleParams,
          },
        })
      );
      if (response.payload.success) {
        setLoading(false);
        if (reschedule) {
          setStep(7);
          setSuccessData(response.payload);
        } else if (schedule) {
          setStep(7);
          setSuccessData(response.payload);
        } else {
          setStep(5);
        }
      } else {
        // Handle the case where the submission was not successful
        console.error("Booking failed:", response.error);
      }
    } catch (error) {
      console.error("Error fetching checkout details:", error);
    }
  };
  const handleSkip = () => {
    dispatch(
      submitSession({
        package_id: selectedPackage?.data?.package.id,
      })
    );
    setStep(5);
  };

  const customStyles = {
    content: {
      height: "90%",
      width: "100%",
      left: 0,
      right: 0,
      bottom: "0",
      border: "none",
      padding: isMobile ? "" : "",
    },
    overlay: {
      zIndex: "30",
      background: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <section className="max-w-5xl w-full mx-auto pt-12 px-4">
      {reschedule ? (
        <Heading
          variant="h1"
          className="text-3xl font-medium max-w-3xl mx-auto w-full text-center"
        >
          Reschedule Session
        </Heading>
      ) : (
        <>
          <Heading
            variant="h1"
            className="text-3xl font-medium max-w-3xl mx-auto w-full text-center"
          >
            {firstSession
              ? "Schedule your first session with"
              : "Schedule your next session with"}
            &nbsp;
            {selectedPackage?.data?.therapist.first_name}
          </Heading>
          {/* <p className="text-gray-500 text-lg mt-3 max-w-xl mx-auto w-full text-center">
            Take a depression test to assess your mental well-being, seek help,
            and empower yourself.
          </p> */}
        </>
      )}
      <div className="mt-12 lg:grid lg:grid-cols-12 bg-white shadow-therapistCard rounded-2xl border border-gray-100">
        <div className="p-6 col-span-3 border-r border-gray-100">
          <div>
            <img
              src={selectedPackage?.data.therapist?.profile_picture?.full_url}
              className="w-12 h-12 rounded-full object-cover"
            />
            <div className="text-sm font-medium mt-2">
              {selectedPackage?.data.therapist.first_name}{" "}
              {selectedPackage?.data.therapist.last_name}
            </div>
            <div className="text-sm text-gray-400 font-medium">
              {selectedPackage?.data.therapist.designation ?? ""}
            </div>
          </div>
          {schedule && selectedTherapist ? (
            <div className="p-1 bg-gray-100 rounded-xl flex items-center space-x-2 mt-6">
              <div className="p-1.5 lg:p-2.5 bg-white rounded-xl flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.7587 4H12.2413H12.2413C13.0463 3.99999 13.7106 3.99998 14.2518 4.0442C14.8139 4.09012 15.3306 4.18868 15.816 4.43598C16.5686 4.81947 17.1805 5.43139 17.564 6.18404C17.8113 6.66938 17.9099 7.18608 17.9558 7.74818C17.9968 8.25011 17.9998 8.85799 18 9.58573L19.9536 7.63213L19.9536 7.63212C20.1447 7.44092 20.3326 7.253 20.4973 7.11512C20.6442 6.99211 20.9537 6.7454 21.3823 6.71166C21.861 6.67399 22.3288 6.86775 22.6406 7.23287C22.9198 7.55981 22.9642 7.95307 22.9811 8.14396C23.0001 8.35791 23 8.62368 23 8.89404V8.89407V15.1058V15.1059C23 15.3762 23.0001 15.642 22.9811 15.8559C22.9642 16.0468 22.9198 16.4401 22.6406 16.767C22.3288 17.1321 21.861 17.3259 21.3823 17.2882C20.9537 17.2545 20.6442 17.0078 20.4973 16.8847C20.3326 16.7468 20.1447 16.5589 19.9535 16.3677L18 14.4141C17.9998 15.1419 17.9968 15.7499 17.9558 16.2518C17.9099 16.8139 17.8113 17.3306 17.564 17.816C17.1805 18.5686 16.5686 19.1805 15.816 19.564C15.3306 19.8113 14.8139 19.9099 14.2518 19.9558C13.7106 20 13.0463 20 12.2413 20H12.2413H6.7587H6.75868C5.95372 20 5.28937 20 4.74818 19.9558C4.18608 19.9099 3.66938 19.8113 3.18404 19.564C2.43139 19.1805 1.81947 18.5686 1.43598 17.816C1.18868 17.3306 1.09012 16.8139 1.0442 16.2518C0.999979 15.7106 0.999988 15.0463 1 14.2413V14.2413V9.75869V9.75867C0.999988 8.95371 0.999979 8.28936 1.0442 7.74818C1.09012 7.18608 1.18868 6.66938 1.43598 6.18404C1.81947 5.43139 2.43139 4.81947 3.18404 4.43598C3.66938 4.18868 4.18608 4.09012 4.74818 4.0442C5.28936 3.99998 5.95373 3.99999 6.75868 4H6.7587ZM9.32153 8.32535C9.77714 7.86974 10.5158 7.86974 10.9714 8.32535L13.8215 11.1754C14.2771 11.631 14.2771 12.3697 13.8215 12.8253L10.9714 15.6754C10.5158 16.131 9.77714 16.131 9.32153 15.6754L6.47144 12.8253C6.01583 12.3697 6.01583 11.631 6.47144 11.1754L9.32153 8.32535Z"
                    fill="#009688"
                  />
                </svg>
              </div>
              <div className="flex-1">
                <div className="text-xs lg:text-sm text-gray-500 whitespace-nowrap">
                  Session credits
                </div>
                <div className="text-sm lg:text-base">
                  {selectedTherapist?.unexpired_credits}
                </div>
              </div>
            </div>
          ) : reschedule && selectedTherapist ? (
            <div className="mt-6">
              <span className="text-gray-400 text-sm">
                Current session time
              </span>
              <div className="px-2 py-2.5 bg-gray-100 rounded-xl flex items-center space-x-2 mt-2 justify-center font-medium text-sm">
                {reschedule.session_date}, {reschedule.start_time}
              </div>
            </div>
          ) : (
            <div className="flex items-center mt-6 space-x-2">
              <img src={session} />
              <div className="text-sm">
                <div>
                  {selectedPackage?.data.package.product.product_count}{" "}
                  {selectedPackage?.data.package.product.product_count > 1
                    ? `Sessions`
                    : `Session`}
                </div>
                <div className="text-gray-400 mt-0.5">
                  {selectedPackage?.data.package.slot.slot_name}
                </div>
              </div>
            </div>
          )}
          {selectedPackage?.data.reached_package_expiry && expiryLimit && (
            <div className="text-warning-900 mt-4">
              Your package will expire within this month, you can&apos;t book
              beyond package expiry
            </div>
          )}
        </div>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          tileContent={({ date }) => {
            // Customize the appearance of dates with available slots
            const dateString = formatDate(date);
            const hasSlots = selectedPackage?.data.schedule.some((item) => {
              item.date === dateString && item.slots.length > 0;
            });

            return hasSlots ? <div className="has-slots-marker" /> : null;
          }}
          tileDisabled={({ date }) => {
            const dateString = formatDate(date);
            const hasSlots = selectedPackage?.data.schedule.some(
              (item) => item.date === dateString && item.slots.length > 0
            );

            // Disable the date if there are no available slots
            return !hasSlots;
          }}
          className="session-calender col-span-6 px-4 pb-9"
          minDate={new Date()}
          onActiveStartDateChange={handleActiveStartDateChange}
        />
        {selectedDate && !isMobile && (
          <div className="col-span-3 border-l border-gray-100 relative max-h-[550px]">
            <p className="font-medium p-6">{selectedDate?.toDateString()}</p>
            <div className="flex px-6 text-xs text-gray-500 font-medium">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                className="mr-1.5"
              >
                <path
                  opacity="0.12"
                  d="M8.50016 1.33301C10.5002 2.66634 11.1153 5.5277 11.1668 7.99967C11.1153 10.4717 10.5002 13.333 8.50016 14.6663C6.50016 13.333 5.88499 10.4717 5.8335 7.99967C5.88499 5.5277 6.50016 2.66634 8.50016 1.33301Z"
                  fill="#667085"
                />
                <path
                  d="M8.50016 1.33301C10.5002 2.66634 11.1153 5.5277 11.1668 7.99967C11.1153 10.4717 10.5002 13.333 8.50016 14.6663M8.50016 1.33301C6.50016 2.66634 5.88499 5.5277 5.8335 7.99967C5.885 10.4717 6.50017 13.333 8.50016 14.6663M8.50016 1.33301C4.81826 1.33301 1.8335 4.31778 1.8335 7.99967M8.50016 1.33301C12.1821 1.33301 15.1668 4.31778 15.1668 7.99967M8.50016 14.6663C12.1821 14.6663 15.1668 11.6816 15.1668 7.99967M8.50016 14.6663C4.81827 14.6663 1.8335 11.6816 1.8335 7.99967M15.1668 7.99967C13.8335 9.99967 10.9721 10.6148 8.50016 10.6663C6.02819 10.6148 3.16683 9.99967 1.8335 7.99967M15.1668 7.99967C13.8335 5.99967 10.9721 5.38451 8.50016 5.33301C6.02819 5.38451 3.16683 5.99967 1.8335 7.99967"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {user.timezone}
            </div>
            <ul className="space-y-1.5 overflow-auto px-6 mt-3 pb-2 h-20 lg:h-[calc(100%-13rem)]">
              {selectedPackage == null ? (
                <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
                  <div className="flex-1">
                    <Skeleton count={5} className="py-4 mb-2" />
                  </div>
                </SkeletonTheme>
              ) : (
                <>
                  {slots?.length == 0 && (
                    <>
                      <div className="text-center mt-12">
                        <div className="flex items-center justify-center">
                          <img src={CalendarIcon} />
                        </div>
                        <div className="text-gray-400 text-sm mt-4">
                          No timeslot available for
                        </div>
                        <div className="text-neutral text-sm">
                          {moment(selectedDate).format("ddd, DD MMM YYYY")}
                        </div>
                        {nextSlotDay ? (
                          <>
                            <div className="text-gray-400 text-xs mt-4">
                              Move to next available date
                            </div>
                            <Button
                              className={
                                "border-2 border-teal-900 text-teal-900 border-solid rounded-full shadow-none mt-2 px-4 text-base font-medium"
                              }
                              onClick={() =>
                                setSelectedDate(new Date(nextSlotDay))
                              }
                            >
                              {moment(new Date(nextSlotDay)).format(
                                "ddd, DD MMM YYYY"
                              )}
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                  {slots?.map((slot) => (
                    <li
                      key={slot.start_time}
                      onClick={() => handleSlotSelection(slot)}
                      className={`py-3 rounded-lg border border-teal-300 text-sm text-center cursor-pointer  ${selectedSlot &&
                        selectedSlot.start_time === slot.start_time
                        ? "bg-teal-500 text-white"
                        : "bg-teal-50 text-teal-500"
                        }`}
                    >
                      {`${moment(slot.start_time).format("h:mm a")}
                                            -
                                            ${moment(slot.end_time).format(
                        "h:mm a"
                      )}`}
                    </li>
                  ))}
                </>
              )}
            </ul>
            <div className="p-4 border-t border-t-gray-200 lg:absolute bottom-0 inset-x-0">
              <Button
                type="button"
                className={`w-full ${!selectedSlot
                  ? "bg-gray-200 text-gray-400 cursor-default"
                  : "bg-teal-900 text-white hover:bg-teal-500"
                  }`}
                onClick={handleConfirmBooking}
                disabled={!selectedSlot}
              >
                {loading ? <ButtonLoader /> : "Confirm & Book"}
              </Button>
              {!reschedule && !schedule && (
                <span
                  className="text-xs text-gray-500 mt-2.5 text-center block font-medium cursor-pointer"
                  onClick={handleSkip}
                >
                  Skip for now
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <p className="mt-8 lg:mt-12 text-xs text-transparent text-center">
        *Schedule next sessions in dashboard or skip & schedule later in
        dashbaord
      </p>
      {isMobile && (
        <Modal
          isOpen={slotsModel}
          onRequestClose={() => setSlotsModel(false)}
          style={customStyles}
          className={`${slotsModel ? "translate-y-0" : "translate-y-full"
            }  w-full transform transition-transform fixed bottom-0 outline-none bg-white rounded-t-2xl`}
        >
          {selectedDate && (
            <div className="h-full">
              <div className="flex justify-between p-6">
                <Heading variant="h3" className="text-xl font-medium">
                  Choose a timeslot
                </Heading>
                <img src={cross} onClick={() => setSlotsModel(false)} />
              </div>
              <p className="font-medium pt-0 p-6">
                {selectedDate?.toDateString()}
              </p>
              <div className="flex px-6 text-xs text-gray-500 font-medium">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  className="mr-1.5"
                >
                  <path
                    opacity="0.12"
                    d="M8.50016 1.33301C10.5002 2.66634 11.1153 5.5277 11.1668 7.99967C11.1153 10.4717 10.5002 13.333 8.50016 14.6663C6.50016 13.333 5.88499 10.4717 5.8335 7.99967C5.88499 5.5277 6.50016 2.66634 8.50016 1.33301Z"
                    fill="#667085"
                  />
                  <path
                    d="M8.50016 1.33301C10.5002 2.66634 11.1153 5.5277 11.1668 7.99967C11.1153 10.4717 10.5002 13.333 8.50016 14.6663M8.50016 1.33301C6.50016 2.66634 5.88499 5.5277 5.8335 7.99967C5.885 10.4717 6.50017 13.333 8.50016 14.6663M8.50016 1.33301C4.81826 1.33301 1.8335 4.31778 1.8335 7.99967M8.50016 1.33301C12.1821 1.33301 15.1668 4.31778 15.1668 7.99967M8.50016 14.6663C12.1821 14.6663 15.1668 11.6816 15.1668 7.99967M8.50016 14.6663C4.81827 14.6663 1.8335 11.6816 1.8335 7.99967M15.1668 7.99967C13.8335 9.99967 10.9721 10.6148 8.50016 10.6663C6.02819 10.6148 3.16683 9.99967 1.8335 7.99967M15.1668 7.99967C13.8335 5.99967 10.9721 5.38451 8.50016 5.33301C6.02819 5.38451 3.16683 5.99967 1.8335 7.99967"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {user.timezone}
              </div>
              <ul className="space-y-1.5 overflow-auto px-6 mt-3 pb-8 h-[calc(100%-16rem)]">
                {selectedPackage == null ? (
                  <SkeletonTheme baseColor="#E4E7EC" highlightColor="#F2F4F7">
                    <div className="flex-1">
                      <Skeleton count={5} className="py-4 mb-2" />
                    </div>
                  </SkeletonTheme>
                ) : (
                  <>
                    {slots?.length == 0 && (
                      <>
                        <div className="text-center mt-12">
                          <div className="flex items-center justify-center">
                            <img src={CalendarIcon} />
                          </div>
                          <div className="text-gray-400 text-sm mt-4">
                            No time slot available for
                          </div>
                          <div className="text-neutral text-sm">
                            {moment(selectedDate).format("ddd, DD MMM YYYY")}
                          </div>
                          <div className="text-gray-400 text-xs mt-4">
                            Move to next available date
                          </div>
                          <Button
                            className={
                              "border-2 border-teal-900 text-teal-900 border-solid rounded-full shadow-none mt-2 px-4 text-base font-medium"
                            }
                            onClick={() =>
                              setSelectedDate(new Date(nextSlotDay))
                            }
                          >
                            {moment(new Date(nextSlotDay)).format(
                              "ddd, DD MMM YYYY"
                            )}
                          </Button>
                        </div>
                      </>
                    )}
                    {slots?.map((slot) => (
                      <li
                        key={slot.start_time}
                        onClick={() => handleSlotSelection(slot)}
                        className={`py-3 rounded-lg border border-teal-300 text-sm text-center cursor-pointer  ${selectedSlot &&
                          selectedSlot.start_time === slot.start_time
                          ? "bg-teal-500 text-white"
                          : "bg-teal-50 text-teal-500"
                          }`}
                      >
                        {`${new Date(slot.start_time).toLocaleTimeString(
                          undefined,
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )} - ${new Date(slot.end_time).toLocaleTimeString(
                          undefined,
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`}
                      </li>
                    ))}
                  </>
                )}
              </ul>
              <div className="p-4 border-t border-t-gray-200 lg:absolute bottom-0 inset-x-0">
                <Button
                  type="button"
                  className={`w-full ${!selectedSlot
                    ? "bg-gray-200 text-gray-400 cursor-default"
                    : "bg-teal-900 text-white hover:bg-teal-500"
                    }`}
                  onClick={handleConfirmBooking}
                  disabled={!selectedSlot}
                >
                  {loading ? <ButtonLoader /> : "Confirm & Book"}
                </Button>
                {!reschedule && !schedule && (
                  <span
                    className="text-xs text-gray-500 mt-2.5 text-center block font-medium cursor-pointer"
                    onClick={handleSkip}
                  >
                    Skip for now
                  </span>
                )}
              </div>
            </div>
          )}
        </Modal>
      )}
    </section>
  );
};

export default ScheduleSession;
