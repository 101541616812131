import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAuthenticated,
  apiPatchAuthenticated,
  apiPatchFileAuthenticated,
  apiPostAuthenticated,
  apiDeleteAuthenticated,
  apiPutAuthenticated,
} from "../../services/api/apiService";

export const addAppointmentSlot = createAsyncThunk(
  "therapist/addAppointmentSlot",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/appointment_schedule/add-appointment-slot/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistNotes = createAsyncThunk(
  "therapist/getTherapistNotes",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/notes/?therapist=${payload.appointment_therapist}&&patient=${payload.patient}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getUpcomingAppointmentsTherapist = createAsyncThunk(
  "therapist/getUpcomingAppointmentsTherapist",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/appointments/list/${
        payload
          ? `?start_date=${payload.start_date}&end_date=${payload.end_date}`
          : ""
      }`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistSlots = createAsyncThunk(
  "therapist/getTherapistSlots",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/appointments/slots/${payload.appointment_id}/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const submitRescheduleSlots = createAsyncThunk(
  "therapist/submitRescheduleSlots",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/therapist/appointments/${payload.appointment_id}/`,
      payload.reschedule_slots
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updateJoiningStatus = createAsyncThunk(
  "therapist/updateJoiningStatus",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/therapist/appointments/${payload.appointment_id}/`,
      payload.joining_status
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getAllAppointmentsTherapist = createAsyncThunk(
  "therapist/getAllAppointmentsTherapist",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/upcoming_appointment/past-appointment/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updateTherapistProfile = createAsyncThunk(
  "therapist/updateTherapistProfile",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/therapist/${payload.id}/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updateTherapistPassword = createAsyncThunk(
  "therapist/updateTherapistPassword",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/change_password/change/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistPatients = createAsyncThunk(
  "therapist/getTherapistPatients",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/patient-therapist/?therapist=${payload.therapist}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const addPatientNote = createAsyncThunk(
  "therapist/addPatientNote",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(`/api/notes/`, payload);
    if (response.status === 201) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const updateTherapistPicture = createAsyncThunk(
  "therapist/updateTherapistPicture",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchFileAuthenticated(
      `/therapist/${payload.id}/`,
      payload.formdata
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const patchPatientNote = createAsyncThunk(
  "therapist/patchPatientNote",
  async (payload, { rejectWithValue }) => {
    const response = await apiPatchAuthenticated(
      `/api/notes/${payload.id}/`,
      payload
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistDashboard = createAsyncThunk(
  "therapist/getTherapistDashboard",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(`/api/therapist/dashboard/`);
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistDashboardSessions = createAsyncThunk(
  "therapist/getTherapistDashboardSessions",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/dashboard/sessions/?start_date=${payload.start_date}&end_date=${payload.end_date}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistDashboardClients = createAsyncThunk(
  "therapist/getTherapistDashboardClients",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/dashboard/clients/?start_date=${payload.start_date}&end_date=${payload.end_date}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistDashboardPerformance = createAsyncThunk(
  "therapist/getTherapistDashboardPerformance",
  async (payload, { rejectWithValue }) => {
    const endpoint = payload.all_filter
    ? `/api/therapist/dashboard/performance/?filter=all`
    : `/api/therapist/dashboard/performance/?start_date=${payload.start_date}&end_date=${payload.end_date}`;
  
    const response = await apiGetAuthenticated(endpoint);
    
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getTherapistClientsList = createAsyncThunk(
  "therapist/getTherapistClientsList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/clients/?${payload}`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const getClientData = createAsyncThunk(
  "therapist/getClientData",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/therapist/list-client-history/${payload.id}/`
    );
    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const SendGoogleCalendarToken = createAsyncThunk(
  "therapist/googleCalendarToken",
  async (payload, { rejectWithValue }) => {
    const response = await apiPostAuthenticated(
      `/api/google-calender/save-token/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);

export const connectedAccountsList = createAsyncThunk(
  "therapist/connectedAccountsList",
  async (payload, { rejectWithValue }) => {
    const response = await apiGetAuthenticated(
      `/api/google-calender/list-account/`,
      payload
    );

    if (response.status === 200) return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const deleteAccount = createAsyncThunk(
  "therapist/deleteAccount",
  async (payload, { rejectWithValue }) => {
    console.log("payload", payload);
    const response = await apiDeleteAuthenticated(
      `/api/google-calender/delete-account/`,
      payload
    );
    if (response.status === 200 || response.status === 204)
      return await response.json();
    else return rejectWithValue(await response.json());
  }
);
export const updateGoogleAccount = createAsyncThunk(
  "therapist/updateGoogleAccount",
  async (payload, { rejectWithValue }) => {
    const response = await apiPutAuthenticated(
      `/api/google-calender/update-account/${payload.id}/`,
      payload.calendarVisibility
    );

    if (response.status === 200) {
      return await response.json();
    } else return rejectWithValue(await response.json());
  }
);
