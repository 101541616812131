import React, { createContext, useState, useEffect, useRef } from "react";
import AgoraRTM from "agora-rtm-sdk";
import { useDispatch, useSelector } from "react-redux";
import { getRTMToken } from "../redux/agora/agoraThunks";

export const RTMClientContext = createContext();
const appId = process.env.REACT_APP_AGORA_APP_ID; // ENTER APP ID HERE
function RTMClientProvider({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);
  const token = useSelector((state) => state.agora.rtmToken);
  const [rtmClient, setRtmClient] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isFetchingToken = useRef(false);
  const rtmInstance = useRef(null);
  const uid = user?.id;
  useEffect(() => {
    const initializeClient = async () => {
      if (!token && !isFetchingToken.current) {
        isFetchingToken.current = true;
        try {
          await dispatch(getRTMToken({}));
        } finally {
          isFetchingToken.current = false;
        }
      }

      if (token && uid) {
        if (!rtmInstance.current) {
          rtmInstance.current = new AgoraRTM.RTM(appId, uid?.toString(), {
            token: token?.rtm_token,
          });
          setRtmClient(rtmInstance.current);
        }

        try {
          if (!isLoggedIn) {
            await rtmInstance.current.login();
            setIsLoggedIn(true);
            console.log("RTM Client logged in successfully");
          }
        } catch (error) {
          console.error("RTM Client login error:", error);
        }
      }
    };

    initializeClient();

    return () => {
      const cleanup = async () => {
        if (isLoggedIn) {
          try {
            await rtmInstance.current?.logout();
            setIsLoggedIn(false);
            console.log("RTM Client logged out");
          } catch (error) {
            console.error("RTM Client logout error:", error);
          }
        }
      };

      cleanup();
    };
  }, [dispatch, token, uid, isLoggedIn]);

  return (
    <RTMClientContext.Provider value={{ rtmClient, isLoggedIn }}>
      {children}
    </RTMClientContext.Provider>
  );
}

export { RTMClientProvider };
