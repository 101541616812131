import React from "react";
import Modal from "react-modal";
import WarningIcon from "../../assets/warning_icon.svg";

const ConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  title,
  message1,
  message2,
}) => {
  const warningModalStyles = {
    content: {
      maxWidth: "400px",
      border: "none",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      margin: "auto",
      borderRadius: "12px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
      padding: "24px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      zIndex: "10",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={warningModalStyles}
    >
      <img src={WarningIcon} />
      <div className="text-lg font-medium mt-4">{title}</div>
      {message1 && <div className="text-sm text-gray-500 my-2">{message1}</div>}
      {message2 && <div className="text-sm text-gray-500">{message2}</div>}
      <div className="flex items-center justify-between space-x-4 mt-7">
        <div
          onClick={onRequestClose}
          className="cursor-pointer rounded-full bg-[#D8EEEC] w-full justify-center flex text-teal-900 font-medium text-base py-2"
        >
          No
        </div>
        <div
          onClick={onConfirm}
          className="cursor-pointer rounded-full bg-teal-900 w-full justify-center flex text-white font-medium text-base py-2"
        >
          Yes
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
