import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendOtp,
  signupTherapist,
  signupUser,
} from "../../redux/app/appThunks";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/molecules/Header";
import { split } from "lodash";
import { FormPreviousLink } from "grommet-icons";
import { Box, Text } from "grommet";
import { stopLoading } from "../../redux/app/appSlice";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils.js";
import "intl-tel-input/build/css/intlTelInput.css";
import Form from "../../components/molecules/Form";
import Input from "../../components/atoms/Input";
import Link from "../../components/atoms/Link";
import EmailIcon from "../../assets/mail.svg";
import passwordIcon from "../../assets/passcode-lock.svg";
import userIcon from "../../assets/user.svg";
import Button from "../../components/atoms/Button";
import Swiper from "../../components/molecules/Swiper";
import pattern from "../../assets/auth-pattern.png";
import therapistPattern from "../../assets/circles.png";
import calendar from "../../assets/calendar.svg";
import barChart from "../../assets/bar-chart.svg";
import shieldDollar from "../../assets/shield-dollar.svg";
import TherapistImage from "../../assets/therapistAppMain2.png";
import ButtonLoader from "../../components/atoms/ButtonLoader";
import PasswordStrengthBar from "react-password-strength-bar";
import { MetaTags } from "../../services/globalFunctions";

const firstNamePattern = /^[A-Za-z\s'-]+$/;
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const passwordPattern =
  process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "dev"
    ? /.*/
    : /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export default function SignUp({ app }) {
  const params = useLocation();
  const { userFirstName, userLastName, userEmail, phoneNumber } =
    params.state || {};
  const [firstName, setFirstName] = useState(userFirstName || "");
  const [lastName, setLastName] = useState(userLastName || "");
  const [email, setEmail] = useState(userEmail || "");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const phoneInput = useRef();
  const stopLoader = useSelector((state) => state.app.stop_loading);
  const signupErrors = useSelector((state) => state.app.signupError);
  const wpRedirected = localStorage.getItem("wp-redirect-user");

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io/?token=${process.env.REACT_APP_IPINFO_ID}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user country");
        }
        const data = await response.json();
        return data.country; // Extract country code from the response
      } catch (error) {
        console.error("Error fetching user country:", error);
        return null;
      }
    };
    fetchUserCountry().then((countryCode) => {
      console.log(countryCode);
      const iti = intlTelInput(phoneInput.current, {
        separateDialCode: true,
        initialCountry: countryCode?.toLowerCase() || "ae", // Default to 'ae' (United Arab Emirates) if countryCode is not available
        nationalMode: false,
      });
      // Optionally, you can set the phone number if you have it already
      if (phoneNumber) {
        iti.setNumber(`+${phoneNumber}`);
      }
    });
  }, [phoneInput]);

  useEffect(() => {
    if (stopLoader) {
      setLoading(false);
      dispatch(stopLoading());
    }
  }, [stopLoader]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/login`);
    } else {
      navigate(`/login`);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    let iti = window.intlTelInputGlobals.getInstance(phoneInput.current);
    const rawPhoneNumber = iti.getNumber();

    const firstNameError = checkFirstnameValidation(firstName);
    const lastNameError = checkLastnameValidation(lastName);
    const emailError = checkEmailValidation(email);
    const mobileError = checkMobileValidation(phoneInput);
    const passwordError = checkPasswordValidation(password);
    const mergedObject = {
      ...firstNameError,
      ...lastNameError,
      ...emailError,
      ...mobileError,
      ...passwordError,
    };
    const payload = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      cell_number: rawPhoneNumber,

      therapist_slug: split(split(params.search, "?t=")[1], "&")[0]
        ? split(split(params.search, "?t=")[1], "&")[0]
        : null,
      timezone: window.localStorage.getItem("onboarding-timezone")
        ? JSON.parse(window.localStorage.getItem("onboarding-timezone"))[0]
        : null,
    };
    const headers = {
      "session-id": window.localStorage.getItem("session-id"),
    };

    if (Object.keys(mergedObject).length === 0) {
      setLoading(true);
      if (app == "THERAPIST") {
        dispatch(
          signupTherapist({
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            email: e.target.email.value,
            password: e.target.password.value,
            cell_number: rawPhoneNumber,

            timezone: window.localStorage.getItem("onboarding-timezone")
              ? JSON.parse(
                window.localStorage.getItem("onboarding-timezone")
              )[0]
              : null,
          })
        );
      } else {
        const res = await dispatch(signupUser({ payload, headers }));
        if (res?.payload?.user) {
          if (!res?.payload?.user?.is_email_verified) {
            setLoading(true);
            const otpRes = await dispatch(sendOtp({ email: email }));
            if (otpRes) {
              setLoading(false);
              navigate("/verify-otp", {
                state: { email: email, password: password, signUp: true },
              });
            } else {
              setLoading(false);
            }
          }
        }
      }
    } else {
      setErrors(mergedObject);
    }
  };

  const checkFirstnameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.firstNameError = "Please input a name at least 3 characters long";
    else if (!firstNamePattern.test(value))
      Errors.firstNameError =
        "Name cannot contain special characters or numbers";
    else delete Errors.firstNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkLastnameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.lastNameError = "Please input a name at least 3 characters long";
    else if (!firstNamePattern.test(value))
      Errors.lastNameError =
        "Name cannot contain special characters or numbers";
    else delete Errors.lastNameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkEmailValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.emailError = "Please input the email";
    else if (!emailPattern.test(value))
      Errors.emailError = "Please input a valid email";
    else delete Errors.emailError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkMobileValidation = (value, validator = {}) => {
    let iti = window.intlTelInputGlobals.getInstance(value.current);
    let validNumber = iti.isValidNumber();

    let Errors = validator;
    if (!validNumber)
      Errors.validNumberError = "Please input a valid phone number";
    else delete Errors.validNumberError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkPasswordValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value)
      Errors.passwordError = "Please input the password";
    else if (!passwordPattern.test(value))
      Errors.passwordError = "Please ensure that the password starts with a capital letter and is atleast 8 characters long";
    else delete Errors.passwordError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  return (
    <div className="h-100 lg:overflow-hidden">
      <MetaTags
        title="Signup | Fitcy Health"
        description="Create your Fitcy Health account"
        url={`${app == "THERAPIST" ? "/therapist/sign-up" : "/sign-up"}`}
      />
      <Header className="lg:absolute" />
      <div className="flex flex-col lg:flex-row">
        <div className="flex justify-center lg:w-1/2 pb-24 lg:pb-0 pt-16 lg:pt-24">
          {window.localStorage.getItem("from-guided-journey") && (
            <Box
              className="noOutline"
              onClick={() =>
                window.location.replace(
                  `/psychologist/calendar?t=${JSON.parse(
                    window.localStorage.getItem("guided-selected-therapist")
                  ).slug
                  }`
                )
              }
              style={{
                // marginTop: "5px",
                cursor: "pointer",
                marginBottom: "5px",
              }}
              // background="red"
              width="100%"
              pad="small"
              direction="row"
              align="center"
              gap="small"
            >
              <Box
                className="primary-btn"
                gap="small"
                direction="row"
                pad="small"
                align="center"
                round="8px"
              >
                <FormPreviousLink color="white" size="small" />
                <Text size="small">Back</Text>
              </Box>
            </Box>
          )}
          <div className="lg:w-[26rem] w-full overflow-auto lg:h-[calc(100vh-8rem)] px-4 lg:scroll-track">
            {/* <GoogleButton /> */}
            {/* <OrDivider /> */}

            <Form
              heading={
                wpRedirected && app != "THERAPIST"
                  ? "You’re almost there!"
                  : "Welcome to Fitcy Health"
              }
              peragraph={
                wpRedirected && app != "THERAPIST"
                  ? "Enter your details below and we’ll take you to the checkout on the next page."
                  : "Create your account"
              }
              method="POST"
              onSubmit={handleSignUp}
              className="mt-6"
            >
              <Input
                icon={userIcon}
                label="First Name"
                placeholder="First Name"
                type="text"
                name="first_name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  checkFirstnameValidation(e.target.value, errors);
                }}
                error={errors.firstNameError}
                app={app}
                onBlur={() => checkFirstnameValidation(firstName, errors)}
              />

              <Input
                icon={userIcon}
                label="Last Name"
                placeholder="Last Name"
                type="text"
                name="last_name"
                className="mt-6"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  checkLastnameValidation(e.target.value, errors);
                }}
                error={errors.lastNameError}
                app={app}
                onBlur={() => checkLastnameValidation(lastName, errors)}
              />

              <Input
                icon={EmailIcon}
                label="Email Address"
                placeholder="Email"
                type="email"
                name="email"
                className="mt-6"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  checkEmailValidation(e.target.value, errors);
                }}
                error={errors.emailError ?? signupErrors?.emailError}
                app={app}
                onBlur={() => checkEmailValidation(email, errors)}
              />

              <Input
                ref={phoneInput}
                label="Phone Number"
                placeholder="Phone Number"
                type="tel"
                name="cell_number"
                className="mt-6"
                error={errors.validNumberError}
                app={app}
                onBlur={() => checkMobileValidation(phoneInput, errors)}
              />

              <Input
                icon={passwordIcon}
                label="Password"
                placeholder="&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;"
                type="password"
                name="password"
                className="mt-6"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  checkPasswordValidation(e.target.value, errors);
                }}
                inputClassName={`placeholder:text-xs`}
                error={errors.passwordError ?? signupErrors?.passwordError}
                app={app}
                onBlur={() => checkPasswordValidation(password, errors)}
              />
              <div className="text-gray-500 text-xs mt-4 mb-2">
                Password Strength
              </div>
              <PasswordStrengthBar
                password={password}
                shortScoreWord={"Weak"}
                barColors={[
                  "#D0D5DD",
                  "#FF2E00",
                  "#FFB03A",
                  "#53C645",
                  "#53C645",
                ]}
                scoreWords={["Weak", "Weak", "Medium", "Good", "Strong"]}
              />
              <Button
                type="submit"
                className={`${app === "THERAPIST"
                    ? "bg-blue-900 hover:bg-blue-500"
                    : "bg-teal-900 hover:bg-teal-500"
                  }  hover:shadow-button transition-all mt-10 text-white`}
                full
              >
                {loading ? <ButtonLoader /> : "Create Account"}
              </Button>
              <div className="text-gray-400 cursor-pointer mt-4 text-center font-medium">
                Already have account? &nbsp;
                <span
                  onClick={handleLogin}
                  className={
                    app === "THERAPIST" ? "text-blue-900" : "text-teal-900"
                  }
                >
                  Login
                </span>
              </div>
            </Form>
            <p className="text-xs mt-4 text-gray-500 px-4">
              By confirming your email, you agree to our &nbsp;
              <Link
                href="https://fitcyhealth.com/terms-and-conditions/"
                rel="noreferrer"
                target="_blank"
                className={
                  app === "THERAPIST"
                    ? "text-blue-900 font-medium"
                    : "text-teal-900 font-medium"
                }
              >
                Terms of Service &nbsp;
              </Link>
              & that you have read & understood the &nbsp;
              <Link
                href="https://fitcyhealth.com/privacy-policy/"
                rel="noreferrer"
                target="_blank"
                className={
                  app === "THERAPIST"
                    ? "text-blue-900 font-medium"
                    : "text-teal-900 font-medium"
                }
              >
                Privacy Policy.
              </Link>
            </p>
          </div>
        </div>
        {app === "THERAPIST" ? (
          <div
            className="h-screen flex-1 bg-cover lg:w-1/2 justify-end items-center hidden lg:flex overflow-hidden py-24 lg:py-0"
            style={{ backgroundImage: `url(${therapistPattern})` }}
          >
            <div className="relative">
              <img src={TherapistImage} />
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-56 absolute bottom-20 left-1/2">
                <img src={calendar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Manage & grow your practice effortlessly
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-52 absolute top-1/2 left-0">
                <img src={shieldDollar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  seamless payment system
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-[18.5rem] absolute top-20 left-1/3">
                <img src={barChart} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Meaningful insights about your practice & client’s behavior
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="h-screen flex-1 bg-cover bg-teal-900 lg:w-1/2 justify-center items-center flex overflow-hidden px-4 py-24 lg:py-0 bg-center"
            style={{ backgroundImage: `url(${pattern})` }}
          >
            <Swiper />
          </div>
        )}
        <button
          onClick={handleLogin}
          className="absolute w-12 h-12 flex justify-center items-center left-4 lg:left-10 lg:top-24 rounded-full drop-shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
              fill="#101828"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
