import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  createPaymentIntent,
  getCheckoutDetails,
  // createPaymentCharge,
} from "../../redux/onboarding/onboardingThunks";
// import { toast } from "react-toastify";
import Heading from "../atoms/Heading";
import RadioButton from "../atoms/RadioButton";
import Button from "../atoms/Button";
import Tooltip from "../atoms/Tooltip";
import ButtonLoader from "../atoms/ButtonLoader";
import Info from "../../assets/info-circle.svg";
import stripeIcon from "../../assets/stripe.svg";
import stripeWhite from "../../assets/stripe-white.svg";
import { useMediaQuery } from "react-responsive";
import mixpanel from "mixpanel-browser";
// import { GoSellElements } from "@tap-payments/gosell";

const CARD_ELEMENT_OPTIONS = {
  style: {
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSection = ({ setStep, scheduledAppointment }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const paymentPackage = useSelector((state) => state.onboarding.payment);
  const {email} = useSelector((state) => state.app.user);
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [selectedOption, setSelectedOption] = useState("card");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createPaymentIntent());
  }, []);

  // useEffect(() => {
  //   if (window.CardSDK && selectedOption === "tap") {
  //     const { renderTapCard, Theme, Currencies, Direction, Edges, Locale } =
  //       window.CardSDK;
  //     const { unmount } = renderTapCard("card-sdk-id", {
  //       publicKey: process.env.REACT_APP_TAP_PUBLIC_KEY,
  //       merchant: {
  //         id: 28361187,
  //       },
  //       transaction: {
  //         amount: scheduledAppointment?.grand_total,
  //         currency: Currencies.USD,
  //       },
  //       // customer: {
  //       //   id: "customer id",
  //       //   name: [
  //       //     {
  //       //       lang: Locale.EN,
  //       //       first: "Test",
  //       //       last: "Test",
  //       //       middle: "Test",
  //       //     },
  //       //   ],
  //       //   nameOnCard: "Test Test",
  //       //   editable: true,
  //       //   contact: {
  //       //     email: "test@gmail.com",
  //       //     phone: {
  //       //       countryCode: "20",
  //       //       number: "1000000000",
  //       //     },
  //       //   },
  //       // },
  //       acceptance: {
  //         supportedBrands: ["AMERICAN_EXPRESS", "VISA", "MASTERCARD", "MADA"],
  //         supportedCards: "ALL",
  //       },
  //       fields: {
  //         cardHolder: true,
  //       },
  //       addons: {
  //         displayPaymentBrands: false,
  //         loader: true,
  //         saveCard: true,
  //       },
  //       interface: {
  //         locale: Locale.EN,
  //         theme: Theme.LIGHT,
  //         edges: Edges.CURVED,
  //         direction: Direction.LTR,
  //       },
  //       onReady: () => console.log("onReady"),
  //       onFocus: () => console.log("onFocus"),
  //       onBinIdentification: (data) => console.log("onBinIdentification", data),
  //       onValidInput: (data) => console.log("onValidInputChange", data),
  //       onInvalidInput: (data) => console.log("onInvalidInput", data),
  //       onError: (data) => console.log("onError", data),
  //       onSuccess: (data) => callbackFunc(data),
  //     });

  //     return () => {
  //       unmount();
  //     };
  //   }
  // }, [selectedOption]);

  // const callbackFunc = async (response) => {
  //   setLoading(true);
  //   try {
  //     const data = await dispatch(
  //       createPaymentCharge({
  //         tap_token: response.id,
  //       })
  //     );

  //     if (data.payload) {
  //       setLoading(false);
  //       window.location.href = data.payload.data.transaction.url;
  //     } else {
  //       console.error("Failed to process payment");
  //     }
  //   } catch (error) {
  //     console.error("Error in processing payment:", error);
  //   }
  // };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleSubmit = async () => {
    try {
      const data = await dispatch(getCheckoutDetails());
      // After getCheckoutDetails completes, you can access the updated state
      if (data?.error) {
        setStep(3);
      } else {
        setLoading(true);
        if (!stripe || !elements) {
          setLoading(false);

          return;
        }

        const result = await stripe.confirmCardSetup(
          paymentPackage.setup_intent.client_secret,
          {
            payment_method: {
              card: elements.getElement(CardNumberElement),
            },
            expand: ["payment_method"],
          }
        );
        if (result.error) {
          setError(result.error.message);
          setLoading(false);
        } else if (result.setupIntent) {
          setStep(6);
          setLoading(false);
        }
      }
    } catch (error) {
      // Handle any errors that might occur during getCheckoutDetails
      console.error("Error fetching checkout details:", error);
      // Optionally, you can setStep(3) or handle the error in a different way
    }
  };

  return (
    <div>
      <div className="mb-4 flex space-x-3">
        <label
          className={`px-3 py-4 flex items-start rounded-2xl border cursor-pointer  ${
            selectedOption === "card"
              ? "border-[#6772E5] bg-[#6772E5] text-white"
              : "border-gray-300"
          }`}
        >
          <RadioButton
            value="card"
            checked={selectedOption === "card"}
            onChange={() => handleOptionChange("card")}
            bgColor="bg-[#6772E5]"
          />
          <div className="ml-2 text-xs lg:text-sm">
            <span className="mt-1 font-medium flex items-start">
              Pay with{" "}
              <img
                className="mobile:w-10"
                src={selectedOption === "card" ? stripeWhite : stripeIcon}
              />
            </span>
          </div>
        </label>
        {/* <label
          className={`px-3 py-4 flex items-start rounded-2xl border cursor-pointer ${
            selectedOption === "tap"
              ? "border-gray-900 bg-gray-900 text-white"
              : "border-gray-300"
            }`}
        >
          <RadioButton
            value="tap"
            checked={selectedOption === "tap"}
            onChange={() => handleOptionChange("tap")}
            bgColor="bg-gray-900"
          />
          <div className="ml-2 text-xs lg:text-sm">
            <span
              className={`mt-1 font-medium flex items-start ${selectedOption === "tap" ? "fill-white" : "fill-gray-900"
                } `}
            >
              Pay with{" "}
              <svg
                width="57"
                height="23"
                viewBox="0 0 57 23"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-1 mobile:w-10 mobile:-mt-0.5"
              >
                <g clipPath="url(#clip0_10934_21552)">
                  <path d="M9.70669 0.4375C7.78715 0.437234 5.91056 0.996047 4.31434 2.04326C2.71813 3.09048 1.47395 4.57906 0.739173 6.32076C0.00438831 8.06245 -0.188005 9.97904 0.186323 11.8282C0.560651 13.6772 1.48489 15.3758 2.84215 16.709C4.19941 18.0421 5.92873 18.9502 7.81142 19.318C9.69413 19.6859 11.6456 19.4973 13.4191 18.7758C15.1926 18.0544 16.7085 16.8327 17.7749 15.2651C18.8414 13.6977 19.4107 11.8547 19.4107 9.96943C19.4107 7.44159 18.3884 5.01734 16.5685 3.2298C14.7487 1.44227 12.2805 0.437857 9.70669 0.4375ZM9.70669 18.1187C8.06519 18.1189 6.46053 17.6411 5.09557 16.7456C3.73061 15.8501 2.66671 14.5772 2.03842 13.0877C1.41012 11.5984 1.24566 9.95946 1.56583 8.37824C1.886 6.79708 2.67642 5.34467 3.83713 4.20471C4.99784 3.06474 6.47668 2.28844 8.08663 1.974C9.69661 1.65955 11.3654 1.82107 12.8818 2.43814C14.3983 3.0552 15.6944 4.10009 16.6062 5.44066C17.518 6.78122 18.0045 8.35727 18.0042 9.96943C18.0039 12.1306 17.1296 14.2032 15.5735 15.7314C14.0175 17.2596 11.9072 18.1183 9.70669 18.1187Z" />
                  <path d="M9.60754 3.55078C8.29511 3.55053 7.01214 3.92682 5.92081 4.63205C4.82947 5.33727 3.97881 6.33976 3.4764 7.51275C2.974 8.68568 2.84241 9.97647 3.09829 11.2218C3.35417 12.4672 3.98601 13.6111 4.91392 14.509C5.84184 15.4069 7.02412 16.0184 8.31129 16.2662C9.59846 16.514 10.9326 16.387 12.1451 15.9011C13.3577 15.4153 14.394 14.5925 15.1232 13.5368C15.8523 12.4811 16.2415 11.2399 16.2415 9.9702C16.2415 8.26792 15.5426 6.63527 14.2986 5.43143C13.0545 4.22759 11.3671 3.55111 9.60754 3.55078ZM9.60754 14.9908C8.58082 14.9911 7.57701 14.6967 6.72324 14.145C5.86944 13.5933 5.20393 12.809 4.8109 11.8913C4.41786 10.9736 4.31496 9.96379 4.51521 8.98956C4.71545 8.01527 5.20984 7.12036 5.93585 6.41799C6.66186 5.7156 7.58688 5.23729 8.59387 5.04356C9.60094 4.84983 10.6447 4.94938 11.5932 5.32963C12.5417 5.70987 13.3524 6.35373 13.9227 7.17974C14.4929 8.00579 14.7972 8.97688 14.7969 9.9702C14.7969 11.3018 14.2502 12.5787 13.277 13.5203C12.3038 14.4619 10.9838 14.9908 9.60754 14.9908Z" />
                  <path d="M29.7122 17.2788C29.0099 17.4182 28.3188 17.4733 27.639 17.4439C26.9591 17.4219 26.3502 17.2862 25.8123 17.0367C25.2818 16.7799 24.8821 16.3873 24.6132 15.859C24.3815 15.4041 24.2545 14.9455 24.2321 14.4832C24.2172 14.0135 24.2097 13.4779 24.2097 12.8762V2.08984H26.0252V12.8102C26.0252 13.3018 26.0289 13.7164 26.0364 14.0539C26.0513 14.3914 26.1298 14.6886 26.2718 14.9455C26.5407 15.4224 26.9666 15.7049 27.5493 15.793C28.1396 15.881 28.8605 15.859 29.7122 15.7269V17.2788ZM24.2099 6.89969V5.39179H29.7122V6.89969H24.2099Z" />
                  <path d="M35.5902 17.6099C34.6787 17.6099 33.9166 17.4485 33.304 17.1256C32.6914 16.8028 32.2319 16.3735 31.9256 15.8378C31.6193 15.2949 31.4661 14.7078 31.4661 14.0768C31.4661 13.4385 31.5931 12.8918 31.8471 12.4368C32.1011 11.9819 32.4523 11.604 32.9006 11.3032C33.3563 11.0023 33.8868 10.7675 34.4919 10.5988C35.0896 10.452 35.7508 10.3236 36.4755 10.2136C37.2003 10.0961 37.925 9.99341 38.6497 9.90538C39.3744 9.81728 40.0318 9.72926 40.6221 9.64123L39.9608 10.0154C39.9832 8.90746 39.7591 8.08559 39.2884 7.54997C38.8252 7.00696 38.0146 6.73549 36.8566 6.73549C36.0796 6.73549 35.4109 6.9116 34.8505 7.2638C34.2902 7.60866 33.898 8.16268 33.6739 8.92579L31.9144 8.4085C32.1983 7.35921 32.7586 6.54104 33.5954 5.95403C34.4396 5.35967 35.5379 5.0625 36.8901 5.0625C37.9885 5.0625 38.9261 5.26062 39.7031 5.65685C40.4876 6.05309 41.0479 6.63643 41.3841 7.40688C41.541 7.76645 41.6418 8.15898 41.6867 8.58455C41.7315 9.01019 41.754 9.45409 41.754 9.91639V17.2797H40.1289V14.363L40.5548 14.5832C40.1215 15.5737 39.4827 16.3258 38.6384 16.8395C37.7942 17.3531 36.7781 17.6099 35.5902 17.6099ZM35.8367 16.091C36.5839 16.091 37.2301 15.9626 37.7755 15.7058C38.3284 15.4416 38.7729 15.0894 39.1092 14.6492C39.4453 14.2016 39.662 13.7026 39.7591 13.1523C39.8637 12.7781 39.9197 12.3671 39.9272 11.9196C39.9421 11.4646 39.9497 11.1161 39.9497 10.874L40.6109 11.1931C39.9982 11.2738 39.3706 11.3545 38.7281 11.4353C38.0931 11.5086 37.4767 11.593 36.879 11.6884C36.2813 11.7765 35.7471 11.8865 35.2764 12.0186C34.9327 12.114 34.6078 12.2461 34.3014 12.4148C34.0026 12.5836 33.756 12.8037 33.5617 13.0753C33.375 13.3394 33.2816 13.6696 33.2816 14.0658C33.2816 14.4033 33.3675 14.7262 33.5394 15.0344C33.7112 15.3426 33.9839 15.5957 34.3575 15.7939C34.731 15.9919 35.2241 16.091 35.8367 16.091Z" />
                  <path d="M50.1499 17.6099C49.0292 17.6099 48.0804 17.3347 47.3034 16.7844C46.5338 16.2268 45.9511 15.4747 45.5551 14.5281C45.1591 13.5742 44.9611 12.5065 44.9611 11.3252C44.9611 10.1292 45.1591 9.05786 45.5551 8.11131C45.9511 7.16476 46.5376 6.41997 47.3146 5.87699C48.0991 5.334 49.0591 5.0625 50.1947 5.0625C51.3079 5.0625 52.2642 5.33766 53.0637 5.88799C53.8631 6.43831 54.4757 7.18678 54.9016 8.13333C55.3349 9.07257 55.5516 10.1365 55.5516 11.3252C55.5516 12.5139 55.3349 13.5815 54.9016 14.5281C54.4757 15.4747 53.8593 16.2268 53.0525 16.7844C52.253 17.3347 51.2855 17.6099 50.1499 17.6099ZM44.6473 22.5628V5.3927H46.2836V14.2419H46.4741V22.5628H44.6473ZM49.9482 15.9589C50.77 15.9589 51.4499 15.7571 51.9878 15.3536C52.5332 14.9427 52.9404 14.3887 53.2094 13.6916C53.4783 12.9872 53.6128 12.1984 53.6128 11.3252C53.6128 10.4593 53.4783 9.67789 53.2094 8.98085C52.9479 8.28374 52.5407 7.73341 51.9878 7.32987C51.4424 6.91893 50.7476 6.71347 49.9034 6.71347C49.089 6.71347 48.4128 6.90793 47.8749 7.29683C47.337 7.68574 46.9373 8.22868 46.6758 8.92579C46.4143 9.6229 46.2836 10.4227 46.2836 11.3252C46.2836 12.2204 46.4143 13.0165 46.6758 13.7136C46.9373 14.4107 47.337 14.961 47.8749 15.3646C48.4203 15.7608 49.1114 15.9589 49.9482 15.9589Z" />
                </g>
                <defs>
                  <clipPath id="clip0_10934_21552">
                    <rect width="56.0625" height="23" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </label> */}
      </div>

      {selectedOption === "card" && (
        <>
          <Heading
            variant="h2"
            className="text-gray-500 text-sm font-medium mb-2"
          >
            Card Information
          </Heading>
          <div className="grid grid-cols-12 border border-gray-200 rounded-xl">
            <div className="col-span-12 border-b border-b-gray-200">
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <div className="col-span-6 border-r border-r-gray-200">
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <div className="col-span-6 relative">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
              <div className="absolute z-10 group text-gray-400 right-4 top-2.5">
                <img src={Info} className="cursor-pointer" />
                <Tooltip
                  direction="left"
                  right="-right-5 after:right-[18px] before:right-4"
                >
                  The CVV is the 3 digit number on the back of your card.
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {selectedOption === "tap" && (
        <>
          <Heading
            variant="h2"
            className="text-gray-500 text-sm font-medium mb-2"
          >
            Card Information
          </Heading>
          <div className="relative">
            <div id="card-sdk-id"></div>
            <div className="absolute z-10 group text-gray-400 right-4 top-6">
              <img src={Info} className="cursor-pointer" />
              <Tooltip
                direction="left"
                right="-right-5 after:right-[18px] before:right-4"
              >
                The CVV is the 3 digit number on the back of your card.
              </Tooltip>
            </div>
          </div>

          <GoSellElements
            gateway={{
              publicKey: process.env.REACT_APP_TAP_PUBLIC_KEY,
              language: "en",
              supportedCurrencies: "all",
              supportedPaymentMethods: "all",
              notifications: "msg",
              callback: callbackFunc,
              labels: {
                cardNumber: "Card Number",
                expirationDate: "MM/YY",
                cvv: "CVV",
                cardHolder: "Name on Card",
                actionButton: "Pay",
              },
              style: {
                base: {
                  color: "#667085",
                  lineHeight: "18px",
                  fontSmoothing: "antialiased",
                  fontSize: "14px",
                  "::placeholder": {
                    color: "rgba(0, 0, 0, 0.26)",
                    fontSize: "14px",
                  },
                },
                invalid: {
                  color: "#FF2E00",
                  iconColor: "#fa755a",
                },
              },
            }}
          />
        </>
      )} */}
      {/* <p id="msg" className="text-sm mt-3 text-danger-900"></p> */}
      {error && <p className="text-sm mt-3 text-danger-900">{error}</p>}
      <div className="fixed bottom-0 lg:static w-full inset-x-0 px-4 py-6 lg:px-0 lg:py-0 bg-white shadow-payButton lg:shadow-none">
        {
          selectedOption === "card" ? (
            <Button
              type="button"
              className={`w-full lg:mt-10 ${
                selectedOption === "instalments"
                  ? "bg-gray-200 text-gray-400 cursor-default"
                  : "bg-teal-900 text-white hover:bg-teal-500"
              }`}
              onClick={()=>{
                handleSubmit();
                mixpanel?.track("clientBoughtCredits", {email, selectedOption});
              }}
              disabled={!stripe || loading}
            >
              {loading ? (
                <ButtonLoader />
              ) : (
                <>
                  Pay{" "}
                  {isMobile ? (
                    <span className="text-white/80">
                      $ {scheduledAppointment?.grand_total ?? "0"}
                    </span>
                  ) : (
                    <span className="text-white/80">& Book now</span>
                  )}
                </>
              )}
            </Button>
          ) : (
            <Button
              type="button"
              className={`w-full lg:mt-10 ${
                selectedOption === "instalments"
                  ? "bg-gray-200 text-gray-400 cursor-default"
                  : "bg-teal-900 text-white hover:bg-teal-500"
              }`}
              onClick={() => {window.CardSDK.tokenize();              
                 mixpanel?.track("clientBoughtCredits", {email, selectedOption});
              }}
            >
              {loading ? (
                <ButtonLoader />
              ) : (
                <>
                  Pay <span className="text-white/80">& Book now</span>
                </>
              )}
            </Button>
          )
          // (
          //   <Button
          //     type="button"
          //     className={`w-full lg:mt-10 ${
          //       selectedOption === "instalments"
          //         ? "bg-gray-200 text-gray-400 cursor-default"
          //         : "bg-teal-900 text-white hover:bg-teal-500"
          //     }`}
          //     onClick={() => GoSellElements.submit()}
          //   >
          //     {loading ? (
          //       <ButtonLoader />
          //     ) : (
          //       <>
          //         Pay <span className="text-white/80">& Book now</span>
          //       </>
          //     )}
          //   </Button>
          // )
        }
      </div>
    </div>
  );
};

export default CardSection;
