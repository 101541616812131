import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import { submitRescheduleSlots } from "../../redux/therapist/therapistThunks";
import mixpanel from "mixpanel-browser";

const RescheduleComponent = ({
  data,
  appointmentId,
  handleCloseModal,
  triggerViewChange,
}) => {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([{ date: "", slots: [] }]);
  const [availableDates, setAvailableDates] = useState([]);
  const [duplicateDateError, setDuplicateDateError] = useState(
    Array(selectedDates.length).fill("")
  );
  const [message, setMessage] = useState("");
  // const [availableSlots, setAvailableSlots] = useState([]);
  // const [slotsToAdd, setSlotsToAdd] = useState([]);
  const [dateError, setDateError] = useState("");
  const [slotsError, setSlotsError] = useState("");
  const [messageError, setMessageError] = useState("");
  // const [isAllSelected, setIsAllSelected] = useState(false);
  const fitcyUser = useSelector((state) => state.app.user);


  useEffect(() => {
    // Extract available dates and slots from the data
    const dates = data?.schedule?.map((item) => item.date);
    setAvailableDates(dates);

    // const slots = data?.schedule.reduce((acc, item) => {
    //   return acc.concat(item.slots?.map((slot) => slot.start_time));
    // }, []);
    // setAvailableSlots(slots);
  }, []);

  const handleAddMoreDates = () => {
    const newSelectedDates = [...selectedDates, { date: "", slots: [] }];
    setSelectedDates(newSelectedDates);
  };

  const handleDateChange = (index, selectedOption) => {
    const newSelectedDates = [...selectedDates];
    newSelectedDates[index].date = selectedOption.value;

    newSelectedDates[index].slots = [];
    // Check if the selected date is already used in other entries
    const isDuplicateDate = newSelectedDates
      .slice(0, index)
      .concat(newSelectedDates.slice(index + 1))
      .some((obj) => obj.date === selectedOption.value);

    const updatedDuplicateErrors = [...duplicateDateError];
    updatedDuplicateErrors[index] = isDuplicateDate ? "Duplicate entry" : "";

    setDuplicateDateError(updatedDuplicateErrors);
    setSelectedDates(newSelectedDates);
    setDateError("");
  };

  const handleSlotChange = (index, selectedOptions) => {
    const newSelectedDates = [...selectedDates];
    const prevDate = newSelectedDates[index].date;

    newSelectedDates[index].slots = selectedOptions?.map((option) => ({
      start_time: option.value.split(" - ")[0],
      end_time: option.value.split(" - ")[1],
    }));
    // Check if "All Day" is selected
    const isAllDaySelected = selectedOptions.some(
      (option) => option.value === "all_day"
    );
    // setIsAllSelected(isAllDaySelected);
    // If "All Day" is selected and a date is chosen, set all available slots for the date
    if (isAllDaySelected && prevDate) {
      const dateObj = data?.schedule?.find((item) => item.date === prevDate);
      if (dateObj) {
        newSelectedDates[index].slots = dateObj.slots?.map((slot) => ({
          start_time: slot.start_time,
          end_time: slot.end_time,
        }));
      }
    }

    setSelectedDates(newSelectedDates);
    setSlotsError("");
  };

  const formatDate = (date) => moment(date, "YYYY-MM-DD").format("DD MMM YYYY");
  const getFormattedSlotOptions = (selectedDate, selectedSlots) => {
    const dateObj = data?.schedule?.find((item) => item.date === selectedDate);
    if (dateObj) {
      // Extract existing start times from selected slots
      const selectedStartTimes = selectedSlots?.map(
        (slot) => slot.start_time.split(" - ")[0]
      );

      return dateObj.slots
        .filter((slot) => !selectedStartTimes.includes(slot.start_time))
        ?.map((slot) => ({
          value: `${slot.start_time} - ${slot.end_time}`,
          label: `${moment(slot.start_time).format("h:mm")}
          -
          ${moment(slot.end_time).format("h:mm a")}`,
        }));
    }
    return [];
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRequestReschedule = async () => {
    const isEmptyDate = selectedDates.some((entry) => !entry.date);
    const isEmptySlots = selectedDates.some(
      (entry) => entry.slots.length === 0
    );
    const isEmptyMessage = !message.trim();

    // Set error messages for empty fields
    setDateError(isEmptyDate ? "Please select a date" : "");
    setSlotsError(isEmptySlots ? "Please select at least one timeslot" : "");
    setMessageError(isEmptyMessage ? "Reason is required" : "");

    // If any field is empty, return without further processing
    if (isEmptyDate || isEmptySlots || isEmptyMessage) {
      return;
    }
    const slots = selectedDates.flatMap((entry) =>
      entry.slots?.map((slot) => {
        return {
          start_time: slot.start_time,
          end_time: slot.end_time,
        };
      })
    );

    const rescheduleRequest = {
      appointment_id: appointmentId,
      reschedule_slots: {
        joining: false,
        slots,
        message,
      },
    };
    try {
      // Dispatch the updateJoiningStatus action
      const response = await dispatch(submitRescheduleSlots(rescheduleRequest));

      if (response.payload) {
        triggerViewChange();

        mixpanel?.track("rescheduleInitiatedByTherapist", {
          appointment_id: appointmentId,
          reschedule_slots: {
            joining: false,
            slots,
            message,
          },
          role: fitcyUser.role,
          userId: fitcyUser.id
        });
      }
      handleCloseModal();
    } catch (error) {
      // Handle any errors that occur during the dispatch
      console.error("Error updating joining status:", error);
    }
    setSelectedDates([{ date: "", slots: [] }]);
    setMessage("");
  };
  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      height: "100%",
      borderRadius: "6px",
      borderColor:
        slotsError || dateError
          ? "#FF2616"
          : state.isFocused
          ? "#005996"
          : "#E4E7EC",
      ":hover": {
        borderColor:
          slotsError || dateError
            ? "#FF2616"
            : state.isFocused
            ? "#005996"
            : "#E4E7EC",
      },
      cursor: "pointer",
      boxShadow:
        slotsError || dateError
          ? "0px 0px 0px 4px rgba(255, 38, 22, 0.20)"
          : state.isFocused
          ? "0px 0px 0px 4px #BFDEF4"
          : "",
      minHeight: "36px",
    }),
    valueContainer: (base) => ({
      ...base,
      paddingRight: 0,
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#F2F4F7",
      borderRadius: "0.5rem",
      border: "1px solid #E4E7EC",
      fontSize: "12px",
      fontWeight: "500",
      padding: "0 2px",
    }),
    multiValueRemove: (css) => ({
      ...css,
      color: "#98A2B3",
      ":hover": { background: "transparent" },
    }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "#232428",
        backgroundColor: isSelected ? "#005996" : "initial",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { backgroundColor: isSelected ? "#005996" : "#F1F6F9" },
        fontWeight: isDisabled ? "700" : "400",
      };
    },
  };

  return (
    <div className="mt-4">
      {selectedDates?.map((selectedDate, index) => (
        <div key={index} className="mb-4">
          <div className="flex space-x-4">
            <div className="w-36">
              {index === 0 && (
                <label className="text-xs text-gray-700">
                  Date <span className="text-danger-900">*</span>
                </label>
              )}
              <Select
                options={availableDates?.map((date) => ({
                  value: date,
                  label: formatDate(date),
                }))}
                value={{
                  value: selectedDate.date,
                  label: selectedDate.date && formatDate(selectedDate.date),
                }}
                onChange={(selectedOption) =>
                  handleDateChange(index, selectedOption)
                }
                placeholder="Select Date"
                styles={customStyles}
                className="mt-1.5"
              />
              {dateError && (
                <p className="text-danger-900 text-sm mt-0.5">{dateError}</p>
              )}

              {duplicateDateError[index] && (
                <p className="text-danger-900 text-sm mt-0.5">
                  {duplicateDateError[index]}
                </p>
              )}
            </div>
            <div className="flex-1">
              {index === 0 && (
                <label className="text-xs text-gray-700">
                  Timeslot <span className="text-danger-900">*</span>
                </label>
              )}
              <Select
                options={[
                  ...(selectedDate.date
                    ? [{ value: "all_day", label: "All Day" }]
                    : []),
                  ...getFormattedSlotOptions(
                    selectedDate.date,
                    selectedDate.slots
                  ),
                ]}
                isMulti
                value={selectedDate.slots?.map((slot) => ({
                  value: `${slot.start_time} - ${slot.end_time}`,
                  label: `${moment(slot.start_time).format("h:mm")}
                  -
                  ${moment(slot.end_time).format("h:mm a")}`,
                }))}
                onChange={(selectedOptions) =>
                  handleSlotChange(index, selectedOptions)
                }
                placeholder="Select Timeslot"
                styles={customStyles}
                className="mt-1.5 slots-select"
                isClearable={false}
              />
              {slotsError && (
                <p className="text-danger-900 text-sm mt-0.5">{slotsError}</p>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-end">
        {" "}
        <button
          className="text-sm text-gray-600 flex items-center shadow-none"
          onClick={handleAddMoreDates}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className="mr-1"
          >
            <path
              d="M9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75V8.25H3.75C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75H8.25V14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25V9.75H14.25C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25H9.75V3.75Z"
              fill="#005996"
            />
          </svg>
          Add More Dates
        </button>
      </div>
      <label className="text-xs text-gray-700 mt-4">
        Reason <span className="text-danger-900">*</span>
      </label>
      <Input
        className="mt-2"
        type="textarea"
        name="message"
        placeholder="I'm requesting for reschedule because..."
        value={message}
        onChange={handleMessageChange}
        app="THERAPIST"
        inputClassName="px-4 py-2.5 h-28 resize-none"
        error={messageError}
      />

      <Button
        type="submit"
        className={`${
          duplicateDateError.includes("Duplicate entry")
            ? "bg-gray-200 text-gray-500"
            : "bg-blue-900 hover:bg-blue-500 hover:shadow-button text-white"
        }  transition-all mt-6`}
        app="THERAPIST"
        full
        onClick={handleRequestReschedule}
        disabled={duplicateDateError.includes("Duplicate entry") ? true : false}
      >
        Request Reschedule
      </Button>
    </div>
  );
};

export default RescheduleComponent;
