import React, { useEffect, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addAgoraVideoLogs, getRTCToken } from "../redux/agora/agoraThunks";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { videoCallLog } from "../redux/patient/patientThunks";
import LeaveIcon from "../assets/leave_icon.svg";
import UnMuteIcon from "../assets/unmute_icon.svg";
import MuteIcon from "../assets/mute_icon.svg";
import CameraOnIcon from "../assets/camera_on_icon.svg";
import CameraOffIcon from "../assets/camera_off_icon.svg";
import ScreenSharingOff from "../assets/screen_sharing_off.svg";
import ScreenSharingOn from "../assets/screen_sharing_on.svg";
import MaximizeIcon from "../assets/maximize.svg";
import MinimizeIcon from "../assets/minimize.svg";
import RetryIcon from "../assets/retry_icon.svg";
import AlertIcon from "../assets/alert-triangle.svg";
import VideoRecorder from "../assets/video-recorder-agora.svg";
import VideoSessionIcon from "../assets/video-recorder-white.svg";
import WarningIcon from "../assets/warning_icon.svg";
import Modal from "react-modal";
import Loader from "../components/atoms/Loader";
import { AgoraRTCProvider, LocalVideoTrack, RemoteUser } from "agora-rtc-react";
import { whatsappClientNumber } from "../services/utils/constants";
import moment from "moment-timezone";
import cross from "../assets/x-close.svg";
import blur1 from "../assets/blur1.svg";
import video_effect_off from "../assets/video_effect_off.svg";
import video_effect_on from "../assets/video_effect_on.svg";
import bg1 from "../assets/bg1.jpg";
import bg2 from "../assets/bg2.jpg";
import bg3 from "../assets/bg3.jpg";
import bg4 from "../assets/bg4.jpg";
import bg5 from "../assets/bg5.jpg";
import bg6 from "../assets/bg6.jpg";
import bg7 from "../assets/bg7.jpg";
import bg8 from "../assets/bg8.jpg";
import bg9 from "../assets/bg9.jpg";
import bg10 from "../assets/bg10.jpg";
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import security_blue_icon from "../assets/security_blue_icon.svg";
import AlertTriangle from "../assets/alert-triangle.svg";
import UnmuteMicIcon from "../assets/unmute_mic_icon.svg";
import MuteMicIcon from "../assets/mute_mic_icon.svg";
import VisualEffectGrayIcon from "../assets/visual_effect_gray_icon.svg";
import Draggable from 'react-draggable';
import InfoCircleYellow from "../assets/info_circle_yellow.svg";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = process.env.REACT_APP_AGORA_APP_ID; //ENTER APP ID HERE

let client = null;
let tracks = null;
let processor = null;
let isBackgroundApplied = false;
const BLUR = 'blur'
const IMAGE = 'img'

const AgoraVideoCall = () => {
  const dispatch = useDispatch();
  const params = useLocation();

  const [callEnded, setCallEnded] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [micAvailable, setMicAvailable] = useState(false);
  const [showMediaError, setShowMediaError] = useState(false);
  const [firstCameraDeviceId, setFirstCameraDeviceId] = useState(null);

  const token = useSelector((state) => state.agora.rtcToken);
  const fitycyUser = useSelector((state) => state.app.user);

  const uId = fitycyUser.id;

  const appointment_id = params?.search
    ?.split("appointment=")[1]
    ?.split("&")[0];
  const channel_name = `appointment_${appointment_id}`;

  useEffect(() => {
    checkMediaDevices();
    if (!token)
      dispatch(
        getRTCToken({
          appointment_id: appointment_id,
          channel_name: channel_name,
        })
      );
  }, []);

  const checkMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices?.filter((device) => device.kind === "videoinput");

      setFirstCameraDeviceId(videoInputDevices[0]?.deviceId || "");

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: videoInputDevices?.length > 0 ? { deviceId: videoInputDevices?.[0]?.deviceId || "" } : false
      });

      setMicAvailable(stream.getAudioTracks().length > 0);
      setCameraAvailable(stream.getVideoTracks().length > 0);

      stream.getTracks().forEach((track) => track.stop());

    } catch (error) {
      console.error("Error accessing media devices:", error);
      setShowMediaError(true);
    }
  };

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    let wakeLock = null;

    // Check if wake lock is supported
    if ("wakeLock" in navigator) {
      const requestWakeLock = async () => {
        try {
          // Request a wake lock to prevent the screen from sleeping
          wakeLock = await navigator.wakeLock.request("screen");
          console.log("Screen wake lock activated");
        } catch (err) {
          console.error("Error requesting wake lock:", err);
        }
      };

      // Call the requestWakeLock function when the component mounts
      requestWakeLock();

      // Release the wake lock when the component unmounts or when the video call ends
      return () => {
        if (wakeLock !== null) {
          wakeLock.release().then(() => {
            console.log("Screen wake lock released");
          });
        }
      };
    } else {
      console.warn("Screen wake lock not supported.");
    }
  }, []);

  if (showMediaError)
    return (
      <ErrorComponent
        title={"No Webcam or Mic Detected"}
        description={`We couldn't detect any Webcam/Mic connected to your device. Please ensure your Webcam/Mic is connected to your device properly and the website is allowed to access it and try again.`}
      />
    );

  if (!token || !micAvailable) return <Loader />;

  return (
    <>
      {callEnded ? (
        <CallEnded token={token} uId={uId} />
      ) : (
        <VideoCall
          setCallEnded={setCallEnded}
          channelName={channel_name}
          token={token}
          appointment_id={appointment_id}
          isOnline={isOnline}
          uId={uId}
          firstCameraDeviceId={firstCameraDeviceId}
          setFirstCameraDeviceId={setFirstCameraDeviceId}
          cameraAvailable={cameraAvailable}
        />
      )}
    </>
  );
};

const createMicrophoneAudioTrack = async (
  options = {
    encoderConfig: "music_standard",
  }
) => {
  return AgoraRTC.createMicrophoneAudioTrack(options);
};

const createCameraVideoTrack = async (opt = {}) => {
  const options = {
    encoderConfig: {
      width: 640,
      // Supports specifying a range and reference value, refer to the relevant API documentation for specific configurations.
      height:
        { ideal: 480, min: 400, max: 500 },
      frameRate: 15,
      bitrateMin: 600, bitrateMax: 1000,
    },
    optimizationMode: "motion",  // Please select an optimization mode 
    ...opt
  }
  return AgoraRTC.createCameraVideoTrack(options);
};

const VideoCall = ({
  channelName,
  token,
  setCallEnded,
  appointment_id,
  isOnline,
  uId,
  firstCameraDeviceId,
  setFirstCameraDeviceId,
  cameraAvailable
}) => {
  const fitycyUser = useSelector((state) => state.app.user);

  const [users, setUsers] = useState([]);
  const [usersReal, setUsersReal] = useState([]);
  const [showError, setShowError] = useState(false);
  const [start, setStart] = useState(false);
  const [maximizeView, setMaximizeView] = useState(true);
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [muteUser, setMuteUser] = useState(false);
  const [time, setTime] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [videoEffectDrawer, setVideoEffectDrawer] = useState(false)
  const [previewMode, setPreviewMode] = useState(true)
  const [isOngoingAction, setIsOngoingAction] = useState(false)
  const [earlyJoin, setEarlyJoin] = useState(true)
  const [bgEffectPopup, setBgEffectPopup] = useState(false)
  // const [duplicateTrack, setDuplicateTrack] = useState("")
  const [showLocalAction, setShowLocalAction] = useState("")
  const [showRemoteAction, setShowRemoteAction] = useState("")
  const [removeBgPopup, setRemoveBgPopup] = useState(false)

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const divRef = useRef(null);
  const videoCallScreenRef = useRef();

  const noUser = usersReal?.length == 0;

  const outerBoxRef = useRef();
  const isPatient = token?.appointment?.patient == uId

  const oppositePerson = isPatient ?
    (token?.appointment?.therapist_info?.first_name || "") +
    " " +
    (token?.appointment?.therapist_info?.last_name || "")
    :
    (token?.appointment?.patient_info?.first_name || "") +
    " " +
    (token?.appointment?.patient_info?.last_name || "")

  const selfUser = (fitycyUser?.first_name || "") + " " + (fitycyUser?.last_name || "")

  useEffect(() => {
    if (videoCallScreenRef?.current) {
      try {
        videoCallScreenRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      catch { console.log('error in scroll to top') }
    }
  }, [previewMode])

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = () => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setBgEffectPopup(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (trackState?.video && tracks?.[1] && !isMobile) {
  //     setDuplicateTrack(tracks?.[1]?.clone());
  //   }
  // }, [trackState?.video, tracks]);

  useEffect(() => {
    initTracks();
  }, []);

  const initTracks = async () => {
    try {
      client = AgoraRTC.createClient(config);
      tracks = [await createMicrophoneAudioTrack()]

      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices?.filter((device) => device.kind === "videoinput");

      setFirstCameraDeviceId(videoInputDevices?.[0]?.deviceId || "")

      if (cameraAvailable) {
        tracks = [...tracks, await createCameraVideoTrack({ cameraId: videoInputDevices?.[0]?.deviceId })]
      }

      if (tracks[1]) {
        await tracks[1]?.setEncoderConfiguration("720p");

        const extension = new VirtualBackgroundExtension();

        if (!extension.checkCompatibility()) {
          console.error("Does not support Virtual Background!");
          // Handle exit code
        }
        // Register the extension
        AgoraRTC.registerExtensions([extension]);
        processor = extension.createProcessor();

        await processor.init();

        const videoTrack = tracks[1];

        videoTrack.pipe(processor).pipe(videoTrack.processorDestination);
      }
    }
    catch { setShowError(true) }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        return prevTime + 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let init = async (name) => {
      try {
        client.on("user-published", async (user, mediaType) => {
          dispatch(
            addAgoraVideoLogs({
              event_type: "user-published",
              appointment_id: appointment_id,
            })
          );
          try {
            await client.subscribe(user, mediaType);
          }
          catch { console.log('error') }
          setMuteUser(!user?.audioTrack)

          if (mediaType === "video") {
            setUsers((prevUsers) => {
              if (!prevUsers.find((x) => x.uid === user.uid))
                return [...prevUsers, user];
              else return [...prevUsers];
            });
          }
          if (mediaType === "audio") {
            setShowRemoteAction('Microphone On')
            user.audioTrack?.play();
            setTimeout(() => {
              setShowRemoteAction('')
            }, 600)
          }
        });

        client.on("user-unpublished", (user, type) => {
          dispatch(
            addAgoraVideoLogs({
              event_type: "user-unpublished",
              appointment_id: appointment_id,
            })
          );

          setMuteUser(!user?.audioTrack)

          if (type === "audio") {
            setShowRemoteAction('Microphone Off')
            user.audioTrack?.stop();
            setTimeout(() => {
              setShowRemoteAction('')
            }, 600)
          }
          if (type === "video") {
            // setUsers((prevUsers) => {
            //   return prevUsers.filter((User) => User.uid !== user.uid);
            // });
          }
        });

        client.on("user-left", (user) => {
          dispatch(
            addAgoraVideoLogs({
              event_type: "user-left",
              appointment_id: appointment_id,
            })
          );

          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
          setUsersReal((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        });

        client.on("user-joined", () => {
          dispatch(
            addAgoraVideoLogs({
              event_type: "user-joined",
              appointment_id: appointment_id,
            })
          );

          setUsers(() => client.remoteUsers);
          setUsersReal(() => client.remoteUsers);
        });

        await client.join(appId, name, token.rtc_token, uId).catch((e) => {
          console.log(e);
          setShowError(true);
        });
        if (tracks[0]) await client.publish(tracks[0])
        if (tracks[1]) await client.publish(tracks[1])
        setStart(true);
      }
      catch { setShowError(true) }
    };

    if (tracks) {
      if (!previewMode) init(channelName);
      else setStart(true);
    }
  }, [channelName, client, tracks, previewMode]);

  const applyBackgroundEffect = async (type, value) => {
    try {
      if (type == BLUR) {
        processor.setOptions({ type: BLUR, blurDegree: value });
        await processor.enable();
        isBackgroundApplied = true;
        // setDuplicateTrack(tracks[1]?.clone());
      }
      else {
        let imgElement = document.createElement('img');
        imgElement.src = value;

        imgElement.onload = async () => {
          imgElement.style.objectFit = 'cover';
          imgElement.style.width = '100%';
          imgElement.style.height = '100%';

          processor.setOptions({ type: IMAGE, source: imgElement });
          await processor.enable();
          isBackgroundApplied = true;
          // setDuplicateTrack(tracks[1]?.clone());
        };

        imgElement.onerror = (error) => {
          console.error("Error loading image for background:", error);
        };
      }

    } catch (error) {
      console.error("Error applying background effect:", error);
      await processor.disable();
      isBackgroundApplied = false;
    }
  };

  const handleRemoveBackground = async () => {
    try {
      if (isBackgroundApplied) {
        setBgEffectPopup(false)
        setRemoveBgPopup(false)
        await processor.disable();
        isBackgroundApplied = false;
      }
    } catch (error) {
      console.error("Error removing background effect:", error);
    }
  }

  const audioOff = async () => {
    setShowLocalAction('Microphone Off')
    if (tracks[0]) {
      if (!previewMode) await client.unpublish(tracks[0]);
      tracks[0].stop();
      tracks[0].close();
      tracks[0] = null; // Clear the reference to the old track
    }
    setTrackState((ps) => ({ ...ps, audio: false }));
    setIsOngoingAction(false)
    setTimeout(() => {
      setShowLocalAction('')
    }, 600)
  }

  const audioOn = async () => {
    setShowLocalAction('Microphone On')
    if (tracks[0]) {
      if (!previewMode) await client.unpublish(tracks[0]);
      tracks[0].stop();
      tracks[0].close();
      tracks[0] = null; // Clear the reference to the old track
    }

    // Create and publish the new audio track
    try {
      tracks[0] = await createMicrophoneAudioTrack()
      if (!previewMode) await client.publish(tracks[0]);
      setTrackState((ps) => ({ ...ps, audio: true }));
      setIsOngoingAction(false)
      setTimeout(() => {
        setShowLocalAction('')
      }, 500)
    } catch (error) {
      console.error("Error creating or publishing audio track:", error);
      setIsOngoingAction(false)
      setShowLocalAction('')
      setShowError(true)
    }
  }

  const videoOff = async () => {
    // Stop and close the existing video track
    if (tracks[1]) {
      if (!previewMode) await client.unpublish(tracks[1]);
      tracks[1].stop();
      tracks[1].close();
      tracks[1] = null; // Clear the reference to the old track
    }
    setTrackState((ps) => ({ ...ps, video: false }));
    setIsOngoingAction(false)
  }

  const videoOn = async () => {
    // Ensure no existing video track is in use
    if (tracks[1]) {
      if (!previewMode) await client.unpublish(tracks[1]);
      tracks[1].stop();
      tracks[1].close();
      tracks[1] = null; // Clear the reference to the old track
    }

    // Create and publish the new video track
    try {
      tracks[1] = await createCameraVideoTrack({ cameraId: firstCameraDeviceId })
      await tracks[1]?.setEncoderConfiguration("720p");
      const videoTrack = tracks[1];
      videoTrack.pipe(processor).pipe(videoTrack.processorDestination);
      if (!previewMode) await client.publish(tracks[1]);
      setTrackState((ps) => ({ ...ps, video: true }));
      setIsOngoingAction(false)
    } catch (error) {
      setIsOngoingAction(false)
      setShowError(true)
      console.error("Error creating or publishing video track:", error);
    }
  }

  const mute = async (type) => {
    if (isOngoingAction) return
    setIsOngoingAction(true)
    try {
      if (type === "audio") {
        if (trackState.audio) await audioOff()
        else await audioOn()
      } else if (type === "video") {
        if (trackState.video) await videoOff()
        else await videoOn()
      }
    }
    catch {
      setIsOngoingAction(false)
      setShowError(true)
    }
  };

  const joinVideoSession = () => {
    setStart(false)
    setVideoEffectDrawer(false)
    setPreviewMode(false)
  }

  const checkTimeDifference = (time) => {
    const appointmentTime = new Date(time);
    const timeDifference = appointmentTime.getTime() - new Date().getTime();
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);
    return timeDifferenceInMinutes;
  };

  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (time % 60).toString().padStart(2, "0");

  const BackgroundComponent = () => {
    return (
      <>
        <div className="flex items-center justify-between">
          <div className="text-lg font-medium text-gray-800">Apply Video Effects </div>
          <img
            onClick={() => setVideoEffectDrawer(false)}
            src={cross}
            className="cursor-pointer h-4 w-4"
          />
        </div>
        {/* {tracks?.[1] &&
          <div
            style={{ height: isMobile ? `calc((${windowWidth}px - 16px) * (9/16))` : '155.25px' }}
            className="w-full lg:w-[276px] rounded-xl overflow-hidden drop-shadow my-4">
            <LocalVideoTrack
              track={duplicateTrack}
              play={trackState?.video}
            />
          </div>
        } */}
        <div className="py-2 px-3 drop-shadow rounded-lg bg-white mt-4 space-y-4">
          <div className="text-sm text-gray-800">Blur Background</div>
          <div className="space-x-2 flex items-center">
            <img
              onClick={() => applyBackgroundEffect(BLUR, 1)}
              src={blur1}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover blur-[0.5px]"
            />
            <img
              onClick={() => applyBackgroundEffect(BLUR, 2)}
              src={blur1}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover blur-[1px]"
            />
            <img
              onClick={() => applyBackgroundEffect(BLUR, 3)}
              src={blur1}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover blur-[1.5px]"
            />
          </div>
        </div>
        <div className="py-2 px-3 drop-shadow rounded-lg bg-white mt-4 space-y-4">
          <div className="text-sm text-gray-800">Backgrounds</div>
          <div className="space-x-2 flex items-center">
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg1)}
              src={bg1}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg2)}
              src={bg2}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg3)}
              src={bg3}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg4)}
              src={bg4}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
          </div>
          <div className="space-x-2 flex items-center">
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg5)}
              src={bg5}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg6)}
              src={bg6}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg7)}
              src={bg7}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg8)}
              src={bg8}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
          </div>
          <div className="space-x-2 flex items-center">
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg9)}
              src={bg9}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
            <img
              onClick={() => applyBackgroundEffect(IMAGE, bg10)}
              src={bg10}
              className="w-1/5 lg:w-14 h-10 cursor-pointer rounded-lg object-cover"
            />
          </div>
        </div>
      </>
    )
  }

  if (showError || !isOnline)
    return (
      <ErrorComponent
        title={!isOnline ? "You Are Offline" : "Something Went Wrong"}
        description={
          !isOnline
            ? `It seems you're not connected to the internet. The session cannot be conducted without internet access.`
            : "It seems something went wrong on our end. Please try refreshing the page by clicking on the button below."
        }
      />
    );

  if (previewMode) {
    return (
      <>
        {(start && tracks) ?
          <div className="flex items-center justify-center h-screen space-x-5 py-6 overflow-auto">
            <div className="w-11/12 lg:w-[740px]">
              <div className="flex items-center justify-center lg:justify-between">
                <div className="flex space-x-3 bg-[#EFF8FF] drop-shadow p-3 mb-4 rounded-xl">
                  <img
                    src={security_blue_icon}
                    alt={'Security Icon'}
                  />
                  <div>
                    <div className="text-base font-medium text-gray-800">Your Therapy Session is Secure</div>
                    <div className="text-sm text-gray-400">Your session is fully encrypted and confidential, ensuring privacy for you and your therapist.</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center lg:justify-between">
                <div className="space-y-4">
                  <div className="text-2xl lg:text-[28px] font-medium text-gray-800">Session with {oppositePerson}</div>
                </div>
              </div>
              <div className="flex justify-center my-6">
                <div className="relative w-3/4 lg:w-full">
                  {tracks?.[1] ?
                    <div className="h-[373px] lg:h-[416.25px] rounded-xl overflow-hidden">
                      <LocalVideoTrack
                        className={`${!trackState?.video && "hidden"}`}
                        track={tracks[1]}
                        play={trackState?.video}
                      />
                    </div>
                    :
                    <div
                      className={`agora-preview-bg-image h-[373px] lg:h-[416.25px] relative rounded-xl bg-center bg-no-repeat bg-auto self-center bg-[#222222]`}
                    />
                  }
                  <div className="text-sm text-white bg-[#101828] py-1.5 px-2 rounded-lg absolute z-50 top-3 right-2">{selfUser}</div>
                  <div className="flex items-center space-x-5 justify-center -mt-14 lg:-mt-16 absolute z-50 left-0 right-0">
                    <img
                      src={trackState.audio ? UnMuteIcon : MuteIcon}
                      className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
                      onClick={() => mute("audio")}
                    />
                    <div className="relative">
                      <img
                        src={(trackState.video && cameraAvailable) ? CameraOnIcon : CameraOffIcon}
                        className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
                        onClick={() => cameraAvailable ? mute("video") : {}}
                      />
                      {!cameraAvailable &&
                        <img
                          src={InfoCircleYellow}
                          className="absolute -top-1 -right-1"
                        />
                      }
                    </div>
                    {/* {isMobile && cameraAvailable &&
                      <img
                        src={isBackgroundApplied ? video_effect_on : video_effect_off}
                        className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
                        onClick={() => isBackgroundApplied ? setBgEffectPopup(true) : setVideoEffectDrawer(true)}
                      />
                    } */}
                  </div>
                  {!isMobile && cameraAvailable &&
                    <div className="-mt-16 absolute z-50 px-6 right-0">
                      <img
                        src={isBackgroundApplied ? video_effect_on : video_effect_off}
                        className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
                        onClick={() => isBackgroundApplied ? setBgEffectPopup(true) : setVideoEffectDrawer(true)}
                      />
                    </div>
                  }
                  {bgEffectPopup &&
                    <div
                      ref={divRef}
                      className="rounded-2xl bg-white absolute drop-shadow w-72 right-8 lg:-right-52 -bottom-24 z-50">
                      <div
                        onClick={handleRemoveBackground}
                        className="cursor-pointer flex items-center py-4 pl-4 space-x-2">
                        <img
                          src={cross}
                          alt={'cross'}
                          className="w-5 h-5"
                        />
                        <div className="text-sm font-medium text-gray-400">Remove Effects</div>
                      </div>
                      <div
                        onClick={() => {
                          setBgEffectPopup(false)
                          setVideoEffectDrawer(true)
                        }}
                        className="cursor-pointer flex items-center py-4 pl-4 space-x-2">
                        <img
                          src={VisualEffectGrayIcon}
                          alt={'visual effect'}
                        />
                        <div className="text-sm font-medium text-gray-400">Apply Video Effects</div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="flex justify-center mt-6">
                <div
                  className="bg-teal-900 py-3 px-4 lg:px-12 rounded-full flex items-center space-x-2 cursor-pointer"
                  onClick={() => joinVideoSession()}>
                  <img
                    alt={'Video icon'}
                    src={VideoSessionIcon}
                  />
                  <div className="text-white font-medium text-base lg:text-lg">Join Session</div>
                </div>
              </div>
            </div>
            {videoEffectDrawer && !isMobile &&
              <div
                style={{ height: `calc(${windowHeight}px - 120px)` }}
                className="w-80 bg-white rounded-2xl py-6 px-4 drop-shadow overflow-auto">
                {BackgroundComponent()}
              </div>
            }
            <Modal
              style={{
                overlay: {
                  zIndex: 999,
                }
              }}
              isOpen={videoEffectDrawer && isMobile}
              className={'w-full bg-white h-full py-6 px-4'}
            >
              {BackgroundComponent()}
            </Modal>
          </div>
          :
          <Loader />
        }
      </>
    )
  }

  const LocalViewComponent = () => {
    return (
      <div
        style={{
          transition: 'transform 0.2s ease'
        }}
        className={`transition-all duration-500 ${noUser
          ? "w-full h-full"
          : `${maximizeView
            ? `absolute h-36 w-36 lg:w-60 lg:h-60 lg:bottom-3 right-3 ${isMobile && "top-3"
            }`
            : "w-full h-1/2 lg:h-full lg:w-1/2 relative"
          }`
          }`}
      >
        {tracks[1] &&
          <LocalVideoTrack
            className={`${!trackState?.video && "hidden"}`}
            track={tracks[1]}
            play={trackState?.video}
          />
        }
        {(!trackState?.video || !cameraAvailable) && (
          <div
            className={`agora-preview-bg-image h-full relative rounded-lg bg-center bg-no-repeat bg-auto self-center ${(noUser || maximizeView) ? "bg-[#222222]" : "bg-[#161616]"
              }`}
          />
        )}
        {isPatient && noUser && checkTimeDifference(token?.appointment?.appointment_time) > 0 && earlyJoin &&
          <div className="flex absolute bottom-3 m-auto justify-center left-0 right-0 mx-2">
            <div className="flex items-center space-x-4 bg-[#FDF2E3] rounded-2xl p-4">
              <img
                src={AlertTriangle}
                alt={'warning icon'}
                className="w-6 h-6"
              />
              <div className="text-sm font-medium lg:w-56">
                You have joined the session early, therapist will join
                soon
              </div>
              <img
                onTouchStart={() => setEarlyJoin(false)}
                onClick={() => setEarlyJoin(false)}
                src={cross}
                alt={'cross icon'}
                className="w-4 h-4 cursor-pointer"
              />
            </div>
          </div>
        }
        <div className="text-sm text-white bg-[#101828] py-1.5 px-2 rounded-lg absolute z-50 top-3 right-3 space-x-2 flex items-center">
          <div>{(maximizeView && !noUser) ? 'You' : `${selfUser} (You)`}</div>
          <img
            src={trackState.audio ? UnmuteMicIcon : MuteMicIcon}
            className="w-4 h-4"
          />
        </div>
        {showLocalAction &&
          <div className="absolute z-50 top-0 bottom-0 flex items-center justify-center left-0 right-0">
            <div className="text-sm text-white bg-black px-3 py-2 rounded-full">{showLocalAction}</div>
          </div>
        }
      </div>
    )
  }

  return (
    <div
      ref={videoCallScreenRef}
      className="fixed top-0 left-0 w-full h-full bg-[#222222] px-2.5 overflow-hidden">
      {start && tracks ? (
        <>
          {!isPatient &&
            <div className="flex items-center justify-between h-[40px]">
              <div className="text-white text-sm">
                Session number: {token?.appointment?.sr_num || 0}
              </div>
              <div className="text-white text-sm">
                {`${hours}:${minutes}:${seconds}`}
              </div>
            </div>
          }
          <div className={`flex items-center space-x-2.5 ${isPatient && 'pt-2.5'}`}>
            <div
              ref={outerBoxRef}
              style={{ height: isMobile ? (isPatient ? `calc(${windowHeight}px - 100px)` : `calc(${windowHeight}px - 130px)`) : (isPatient ? `calc(${windowHeight}px - 90px)` : `calc(${windowHeight}px - 120px)`), width: (videoEffectDrawer && !isMobile) ? `calc(${windowWidth}px - 350px)` : '100%' }}
              className={`lg:flex relative space-y-2.5 lg:space-y-0 lg:space-x-3`}
            >
              {!noUser && (
                <div
                  className={`transition-all relative duration-500 ${maximizeView
                    ? "w-full h-full"
                    : "h-1/2 w-full lg:h-full lg:w-1/2"
                    }`}
                >
                  <AgoraRTCProvider client={client}>
                    <RemoteUser user={users[0]} className={!users?.[0]?.videoTrack && "remote-user-class"} />
                  </AgoraRTCProvider>
                  {!users[0]?.videoTrack &&
                    <div
                      className={`agora-preview-bg-image h-full relative rounded-lg bg-center bg-no-repeat bg-auto self-center bg-[#161616]`}
                    />
                  }
                  <div className="text-sm text-white bg-[#101828] py-1.5 px-2 rounded-lg absolute z-50 top-3 right-3 space-x-2 flex items-center">
                    <div>{oppositePerson} {`(${isPatient ? "Therapist" : "Patient"})`}</div>
                    <img
                      src={muteUser ? MuteMicIcon : UnmuteMicIcon}
                      className="w-4 h-4"
                    />
                  </div>
                  <div className="flex items-center space-x-2 absolute -mt-12">
                    <div
                      onClick={() => setMaximizeView(!maximizeView)}
                      className="bg-white border border-solid border-gray-200 rounded-full px-4 py-1.5 cursor-pointer z-50 ml-3"
                    >
                      <img src={maximizeView ? MinimizeIcon : MaximizeIcon} />
                    </div>
                  </div>
                  {showRemoteAction &&
                    <div className="absolute z-50 top-0 bottom-0 flex items-center justify-center left-0 right-0">
                      <div className="text-sm text-white bg-black px-3 py-2 rounded-full">{showRemoteAction}</div>
                    </div>
                  }
                </div>
              )}

              {isMobile && maximizeView ?
                <Draggable
                  defaultClassName={'z-[9999999999]'}
                  bounds="parent">
                  {LocalViewComponent()}
                </Draggable>
                :
                LocalViewComponent()
              }
            </div>

            {videoEffectDrawer && !isMobile &&
              <div
                style={{ height: isMobile ? (isPatient ? `calc(${windowHeight}px - 100px)` : `calc(${windowHeight}px - 130px)`) : (isPatient ? `calc(${windowHeight}px - 90px)` : `calc(${windowHeight}px - 120px)`) }}
                className="w-80 bg-white rounded-lg py-6 px-4 overflow-auto">
                {BackgroundComponent()}
              </div>
            }
          </div>
        </>
      ) : (
        <Loader />
      )}
      {start && tracks && (
        <Controls
          token={token}
          setCallEnded={setCallEnded}
          tracks={tracks}
          setStart={setStart}
          trackState={trackState}
          setVideoEffectDrawer={setVideoEffectDrawer}
          mute={mute}
          isOngoingAction={isOngoingAction}
          setIsOngoingAction={setIsOngoingAction}
          cameraAvailable={cameraAvailable}
          maximizeView={maximizeView}
          removeBgPopup={removeBgPopup}
          setRemoveBgPopup={setRemoveBgPopup}
          handleRemoveBackground={handleRemoveBackground}
        />
      )}
      <Modal
        isOpen={videoEffectDrawer && isMobile}
        style={{
          overlay: {
            zIndex: 99999999999,
          }
        }}
        className={'w-full bg-white h-full py-6 px-4'}
      >
        {BackgroundComponent()}
      </Modal>
    </div>
  );
};

export const Controls = (props) => {
  const dispatch = useDispatch();
  const params = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const { tracks, setStart, setCallEnded, trackState, token, setVideoEffectDrawer, mute = () => { }, isOngoingAction, setIsOngoingAction, cameraAvailable, maximizeView, removeBgPopup, setRemoveBgPopup, handleRemoveBackground } = props;
  const user = useSelector((state) => state.app.user);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const screenTrack = useRef(null);

  const startScreenShare = async () => {
    if (isOngoingAction) return
    setIsOngoingAction(true)
    try {
      screenTrack.current = await AgoraRTC.createScreenVideoTrack();
      if (tracks[1]) await client.unpublish(tracks[1]);
      await client.publish(screenTrack.current);

      screenTrack.current.on("track-ended", stopScreenShare);

      setIsScreenSharing(true);
      setIsOngoingAction(false)
    } catch (error) {
      setIsOngoingAction(false)
      console.error("Error starting screen share:", error);
    }
  };

  const stopScreenShare = async () => {
    try {
      await client.unpublish(screenTrack.current);
      if (tracks[1]) await client.publish(tracks[1]);

      screenTrack.current.close();
      setIsScreenSharing(false);
    } catch (error) {
      console.error("Error stopping screen share:", error);
    }
  };

  const leaveChannel = async () => {
    dispatch(
      videoCallLog({
        action: "LEAVE",
        user: user.id,
        appointment: params.search.split("?")[1].split("&")[1].split("=")[1],
      })
    );
    await client.leave();
    client.removeAllListeners();
    if (tracks[0]) tracks[0].close();
    if (tracks[1]) tracks[1].close();
    setStart(false);
    setCallEnded(true);
  };

  const warningModalStyles = {
    content: {
      maxWidth: isMobile ? "90%" : "400px",
      border: "none",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      margin: "auto",
      borderRadius: "12px",
      height: "max-content",
      background: "#fff",
      overflowY: "auto",
      padding: "24px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.09)",
      zIndex: 999999999
    },
  };

  const CommonControls = () => {
    return (
      <>
        <img
          src={trackState.audio ? UnMuteIcon : MuteIcon}
          className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
          onClick={() => mute("audio")}
        />
        <div className="relative">
          <img
            src={(trackState.video && cameraAvailable) ? CameraOnIcon : CameraOffIcon}
            className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
            onClick={() => cameraAvailable ? mute("video") : {}}
          />
          {!cameraAvailable &&
            <img
              src={InfoCircleYellow}
              className="absolute -top-1 -right-1"
            />
          }
        </div>
        {!isMobile &&
          <img
            src={isScreenSharing ? ScreenSharingOn : ScreenSharingOff}
            className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
            onClick={() => isScreenSharing ? stopScreenShare() : startScreenShare()}
          />
        }
        <div className="relative">
          {cameraAvailable && !isMobile &&
            <img
              src={isBackgroundApplied ? video_effect_on : video_effect_off}
              className="cursor-pointer w-10 h-10 lg:w-12 lg:h-12"
              onClick={() => isBackgroundApplied ? setRemoveBgPopup(true) : setVideoEffectDrawer(true)}
            />
          }
          {removeBgPopup &&
            <div
              className="rounded-2xl bg-white absolute drop-shadow w-72 bottom-14 z-50">
              <div
                onClick={handleRemoveBackground}
                className="cursor-pointer flex items-center py-4 pl-4 space-x-2">
                <img
                  src={cross}
                  alt={'cross'}
                  className="w-5 h-5"
                />
                <div className="text-sm font-medium text-gray-400">Remove Effects</div>
              </div>
              <div
                onClick={() => {
                  setRemoveBgPopup(false)
                  setVideoEffectDrawer(true)
                }}
                className="cursor-pointer flex items-center py-4 pl-4 space-x-2">
                <img
                  src={VisualEffectGrayIcon}
                  alt={'visual effect'}
                />
                <div className="text-sm font-medium text-gray-400">Apply Video Effects</div>
              </div>
            </div>
          }
        </div>
      </>
    )
  }

  return (
    <>
      {isMobile ? (
        <div className={`flex items-center space-x-3 justify-center ${maximizeView ? 'h-[90px]' : 'h-[80px] mt-2.5'}`}>
          {CommonControls()}
          <img
            src={LeaveIcon}
            className="cursor-pointer h-10 w-24"
            onClick={() => setConfirmationModal(true)}
          />
        </div>
      ) : (
        <div className="flex justify-between items-center h-[80px]">
          <div className="flex items-center space-x-3">
            <div className="text-sm text-white">
              {moment().tz(user?.timezone).format("hh:mm A ddd, DD MMM")}
            </div>
          </div>
          <div className="flex item-center space-x-5">
            {CommonControls()}
          </div>
          <img
            src={LeaveIcon}
            className="cursor-pointer"
            onClick={() => setConfirmationModal(true)}
          />
        </div>
      )}

      <Modal
        isOpen={confirmationModal}
        onRequestClose={() => setConfirmationModal(false)}
        style={warningModalStyles}
      // className={`translate-y-full lg:translate-y-0 w-full transform transition-transform fixed bottom-0 outline-none`}
      >
        <img src={WarningIcon} />
        <div className="text-lg font-medium mb-2 mt-4">{`Headsup, you're leaving the session!`}</div>
        <div className="text-sm text-gray-500">
          {token?.appointment?.patient == user.id
            ? `Oops! It seems you're attempting to leave the session before it's completed. Are you sure you want to leave anyway?`
            : `Your presence and guidance are vital to your client's well-being. Are you sure you want to exit the session early?`}
        </div>
        <div className="flex items-center justify-between space-x-4 mt-7">
          <div
            onClick={() => setConfirmationModal(false)}
            className="cursor-pointer rounded-full bg-[#D8EEEC] w-full justify-center flex text-teal-900 font-medium text-base py-2"
          >
            No
          </div>
          <div
            onClick={() => {
              setConfirmationModal(false);
              leaveChannel();
            }}
            className="cursor-pointer rounded-full bg-teal-900 w-full justify-center flex text-white font-medium text-base py-2"
          >
            Yes
          </div>
        </div>
      </Modal>
    </>
  );
};

const CallEnded = ({ token, uId }) => {
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate(`/login`);
  };
  return (
    <div className="h-screen w-screen items-center justify-center flex flex-col px-5 lg:px-0">
      <div className="flex items-start space-x-4">
        <img src={VideoRecorder} />
        <div className="lg:w-96 space-y-3">
          <div className="text-xl font-medium">{`Bravo, session completed!`}</div>
          <div className="text-base text-gray-500">
            {token?.appointment?.patient == uId
              ? `You have successfully completed the session!`
              : `You have successfully completed the session with your client.`}
          </div>
          {token?.appointment?.patient != uId && (
            <div className="text-base text-gray-500">{`Thank you for your dedication to supporting your client's mental health journey.`}</div>
          )}
          <div className="flex justify-start">
            <div
              onClick={handleRoute}
              className="cursor-pointer text-white text-base font-medium bg-teal-900 rounded-full py-2 px-4"
            >
              Go back to dashboard{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorComponent = ({ title, description }) => {
  return (
    <div className="h-screen w-screen items-center justify-center flex flex-col px-5 lg:px-0">
      <div className="flex items-start space-x-4">
        <img src={AlertIcon} />
        <div className="lg:w-96 space-y-3">
          <div className="text-xl font-medium">{title}</div>
          <div className="text-base text-gray-500">{description}</div>
          <div className="text-base text-gray-500">
            For additional assistance,{" "}
            <span
              onClick={() =>
                window.open(`https://wa.me/${whatsappClientNumber}`)
              }
              className="text-teal-500 cursor-pointer"
            >
              contact us
            </span>
          </div>
          <div className="flex justify-start">
            <div
              onClick={() => window.location.reload()}
              className="flex items-center space-x-2 border border-solid py-1.5 px-4 rounded-lg border-gray-200 bg-gray-100 cursor-pointer"
            >
              <img src={RetryIcon} />
              <div className="text-sm font-medium">Retry</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgoraVideoCall;
