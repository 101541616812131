import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import Button from "../atoms/Button";
import Heading from "../atoms/Heading";
import happy from "../../assets/happy-mood.svg";
import overjoyed from "../../assets/overjoyed-mood.svg";
import neutral from "../../assets/neutral-mood.svg";
import sad from "../../assets/sad-mood.svg";
import notHappy from "../../assets/solid-mood-depressed-small.svg";
import announcement from "../../assets/announcement02.svg";
import Input from "../atoms/Input";
import { submitFeedback } from "../../redux/patient/patientThunks";
import Tooltip from "../atoms/Tooltip";
import { useMediaQuery } from "react-responsive";
import mixpanel from "mixpanel-browser";

const RatingStars = ({ onChange, value, setTag }) => {
  const maxStars = 5;

  const handleStarClick = (rating) => {
    onChange(rating);
    setTag(getRatingText(rating));
  };

  const handleStarHover = (rating) => {
    setTag(getRatingText(rating));
  };

  const getRatingText = (rating) => {
    switch (rating) {
      case 1:
        return "Poor";
      case 2:
        return "Fair";
      case 3:
        return "Average";
      case 4:
        return "Good";
      case 5:
        return "Excellent";
      default:
        return "";
    }
  };
  return (
    <div className="flex gap-4 mt-4">
      {[...Array(maxStars)].map((_, index) => {
        const starValue = index + 1;
        const isSelected = starValue <= value;

        return (
          <div key={starValue} className="relative group">
            <span
              onClick={() => handleStarClick(starValue)}
              onMouseEnter={() => handleStarHover(starValue)}
              style={{
                color: isSelected ? "#009688" : "gray",
                cursor: "pointer",
              }}
            >
              {/* Replace with your SVG component */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
                fill="none"
              >
                <g filter="url(#filter0_d_1295_10487)">
                  <g clipPath="url(#clip0_1295_10487)">
                    <rect
                      x="2"
                      y="1"
                      width="40"
                      height="40"
                      rx="8"
                      fill={isSelected ? "#009688" : "#D8EEEC"}
                    />
                    <g filter="url(#filter1_d_1295_10487)">
                      <path
                        d="M21.4294 6.75623C21.609 6.20344 22.391 6.20344 22.5706 6.75623L25.4575 15.6411C25.5378 15.8884 25.7682 16.0557 26.0282 16.0557H35.3703C35.9515 16.0557 36.1932 16.7995 35.723 17.1411L28.165 22.6323C27.9547 22.7851 27.8667 23.0559 27.947 23.3031L30.8339 32.188C31.0135 32.7408 30.3809 33.2005 29.9106 32.8589L22.3527 27.3677C22.1424 27.2149 21.8576 27.2149 21.6473 27.3677L14.0894 32.8589C13.6191 33.2005 12.9865 32.7408 13.1661 32.188L16.053 23.3031C16.1333 23.0559 16.0453 22.7851 15.835 22.6323L8.27703 17.1411C7.8068 16.7995 8.04847 16.0557 8.62971 16.0557H17.9719C18.2318 16.0557 18.4622 15.8884 18.5425 15.6411L21.4294 6.75623Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <rect
                    x="2.25"
                    y="1.25"
                    width="39.5"
                    height="39.5"
                    rx="7.75"
                    stroke={isSelected ? "#009688" : "#D8EEEC"}
                    strokeWidth="0.5"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_1295_10487"
                    x="0"
                    y="0"
                    width="44"
                    height="44"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1295_10487"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1295_10487"
                      result="shape"
                    />
                  </filter>
                  <filter
                    id="filter1_d_1295_10487"
                    x="-91.9717"
                    y="-85.6582"
                    width="227.943"
                    height="226.635"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="8" />
                    <feGaussianBlur stdDeviation="50" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1295_10487"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1295_10487"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_1295_10487">
                    <rect
                      x="2"
                      y="1"
                      width="40"
                      height="40"
                      rx="8"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <Tooltip
              position="bottom"
              className="!py-1 top-12 !-left-1"
              pin="top"
            >
              {getRatingText(starValue)}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

const FeedbackModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  data,
  setRatingValue,
  selectedTherapist,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const customStyles = {
    content: {
      maxWidth: "800px",
      width: "100%",
      top: isMobile ? 0 : "50%",
      left: isMobile ? 0 : "50%",
      transform: isMobile ? "none" : "translate(-50%, -50%)",
      border: "none",
      borderRadius: isMobile ? 0 : "16px",
      padding: isMobile ? "2rem 1rem" : "2.5rem",
      height: isMobile ? "100%" : "90%",
      overflow: "auto",
      bottom: isMobile ? 0 : "auto",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      zIndex: "20",
    },
  };

  const [rating, setRating] = useState(0); // Default rating
  const [tag, setTag] = useState("");
  const [selectedFeeling, setSelectedFeeling] = useState(null);
  const [feelings, setFeelings] = useState({
    overjoyed: false,
    happy: false,
    neutral: false,
    sad: false,
    notHappy: false,
  });
  const [sessionText, setSessionText] = useState("");
  const [isRatingAnswered, setIsRatingAnswered] = useState(false);
  const [areFeelingsAnswered, setAreFeelingsAnswered] = useState(false);

  const feelingImage = {
    overjoyed: overjoyed,
    happy: happy,
    neutral: neutral,
    sad: sad,
    notHappy: notHappy,
  };
  const feelingText = {
    overjoyed: "Overjoyed",
    happy: "Happy",
    neutral: "Neutral",
    sad: "Sad",
    notHappy: "Confused",
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
    setIsRatingAnswered(true);
  };

  const handleFeelingsChange = (feeling) => {
    setFeelings({
      overjoyed: false,
      happy: false,
      neutral: false,
      sad: false,
      notHappy: false,
      [feeling]: true,
    });
    setSelectedFeeling(feeling);
    setAreFeelingsAnswered(true);
  };

  const handleSessionTextChange = (e) => {
    setSessionText(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ rating, feelings, sessionText });
    setRatingValue(rating);
    dispatch(
      submitFeedback({
        appointment_id: data.id,
        rating_star: rating,
        rating_tag: tag.toUpperCase(),
        rating_emoji: selectedFeeling.toUpperCase(),
        rating_content: sessionText,
      })
    );

    mixpanel?.track("feedbackSubmitted", {
      appointment_id: data.id,
      rating_star: rating,
      rating_tag: tag.toUpperCase(),
      rating_emoji: selectedFeeling.toUpperCase(),
      rating_content: sessionText,
    })
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <button
        onClick={onRequestClose}
        className="shadow-none absolute right-4 lg:right-10 top-4 lg:top-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div>
        <Heading variant="h2" className="text-lg lg:text-2xl font-medium">
          Tell us about your experience with {selectedTherapist?.first_name}{" "}
          {selectedTherapist?.last_name}
        </Heading>
        <div className="text-gray-500 font-medium mt-3">
          {data?.session_date} {data?.start_time}
        </div>

        <div className="flex p-2 rounded-2xl mt-3 bg-teal-50 items-center space-x-2">
          <img src={announcement} />
          <div className="text-xs font-medium tracking-tight">
            Your feedback is anonymous and will not be shared with therapist
          </div>
        </div>

        {/* Rating question */}
        <div className="mt-8 lg:mt-12">
          <Heading variant="h3" className="text-sm lg:text-base font-medium">
            How do you rate the session?
          </Heading>
          <p className="text-xs text-gray-500 mt-1 tracking-tight">
            Your rating means how satisfied are you with the therapist and our
            services, use the description box below to write your heart out.
          </p>
          <RatingStars
            onChange={handleRatingChange}
            value={rating}
            setTag={setTag}
          />
        </div>
        {/* Feelings question */}
        <div className="mt-4 lg:mt-10">
          <Heading variant="h3" className="text-sm lg:text-base  font-medium">
            How are you feeling after the session?
          </Heading>
          <p className="text-xs text-gray-500 mt-1 tracking-tight">
            Therapy is an incremental process, progress after every session
            counts and contributes to the larger good.
          </p>
          <div className="flex mt-4">
            {Object.keys(feelings).map((feeling) => (
              <label
                key={feeling}
                className={`flex flex-col flex-1 items-center rounded-lg p-2 cursor-pointer relative ${selectedFeeling === feeling ? "bg-teal-50" : ""
                  }`}
              >
                <input
                  type="radio"
                  name="feelings"
                  checked={feelings[feeling]}
                  onChange={() => handleFeelingsChange(feeling)}
                  className="appearance-none hidden checked:bg-teal-500 checked:flex border-gray-200 w-5 h-5 items-center justify-center checked:before:w-[.30rem] checked:before:h-[.60rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 cursor-pointer rounded-full absolute top-2 right-2"
                />
                <img
                  src={feelingImage[feeling]}
                  alt={feeling}
                  className="w-12 h-12"
                />
                <span className="text-xs text-gray-400">
                  {feelingText[feeling]}
                </span>
              </label>
            ))}
          </div>
        </div>
        {/* Session text question */}
        <div className="mt-4 lg:mt-10">
          <Heading variant="h3" className="text-sm lg:text-base font-medium">
            Please write few lines on how was your session
          </Heading>

          <Input
            className="mt-4"
            type="textarea"
            name="views"
            placeholder="The session went..."
            value={sessionText}
            onChange={handleSessionTextChange}
            inputClassName="px-3.5 py-2.5 resize-none"
          />
        </div>
        {/* Submit button */}
        <div className="flex justify-end mt-3 lg:mt-8">
          <Button
            onClick={handleSubmit}
            className={`!text-base ${!isRatingAnswered || !areFeelingsAnswered
              ? "text-gray-400 bg-gray-200 cursor-auto"
              : "text-white bg-teal-900 hover:bg-teal-500"
              }`}
            disabled={!isRatingAnswered || !areFeelingsAnswered}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
