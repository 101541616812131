import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import platform from "platform";
import {
  signinUser,
  signinTherapist,
  sendOtp,
  checkBetaUser,
} from "../../redux/app/appThunks";
import { useNavigate } from "react-router-dom";
import Header from "../../components/molecules/Header";
import pattern from "../../assets/auth-pattern.png";
import therapistPattern from "../../assets/circles.png";
import Input from "../../components/atoms/Input";
import Form from "../../components/molecules/Form";
import Button from "../../components/atoms/Button";
import Swiper from "../../components/molecules/Swiper";
import EmailIcon from "../../assets/mail.svg";
import passwordIcon from "../../assets/passcode-lock.svg";
import calendar from "../../assets/calendar.svg";
import barChart from "../../assets/bar-chart.svg";
import shieldDollar from "../../assets/shield-dollar.svg";
import TherapistImage from "../../assets/therapistAppMain3.png";
import { stopLoading } from "../../redux/app/appSlice";
import ButtonLoader from "../../components/atoms/ButtonLoader";
import { MetaTags } from "../../services/globalFunctions";
import TherapistLogin from "../../assets/therapist_login.svg";
import mixpanel from "mixpanel-browser";

// Define regex patterns for username and password validation
const usernamePattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// const passwordPattern =
//   process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "dev"
//     ? /.*/
//     : /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const passwordPattern = /.*/;

const Login = ({ app }) => {
  localStorage.removeItem("passwordResetInProgress");
  const userEmail = localStorage.getItem("user-email");
  const [username, setUsername] = useState(userEmail || "");
  const [password, setPassword] = useState("");
  const [browserInfo, setBrowserInfo] = useState({
    osName: "",
    osVersion: "",
    browserName: "",
    browserVersion: "",
    userAgent: "",
    appName: "",
    appVersion: "",
    platform: "",
    vendor: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // const signinError = useSelector((state) => state.app.signinError);
  const stopLoader = useSelector((state) => state.app.stop_loading);
  const wpRedirected = localStorage.getItem("wp-redirect-user");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/forgot-password`);
    } else {
      navigate(`/forgot-password`);
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/sign-up`);
    } else {
      navigate(`/sign-up`);
    }
  };

  useEffect(() => {
    const parsedPlatform = platform.parse(window.navigator.userAgent);
    setBrowserInfo({
      "os-name": parsedPlatform.os.family,
      "os-version": parsedPlatform.os.version,
      "browser-name": parsedPlatform.name,
      "browser-version": parsedPlatform.version,
      "user-agent": navigator.userAgent,
      "app-version": navigator.appVersion,
      platform: navigator.platform,
      vendor: navigator.vendor,
    });
  }, []);

  useEffect(() => {
    if (stopLoader) {
      setLoading(false);
      dispatch(stopLoading());
    }
  }, [stopLoader]);

  const goToLandingPage = (e) => {
    e.preventDefault();
    navigate(`/login`);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const nameError = checkUsernameValidation(username);
    const passwordError = checkPasswordValidation(password);
    const mergedObject = { ...nameError, ...passwordError };

    const payload = {
      username: String(e.target.username.value),
      password: e.target.password.value,
    };

    const headers = {
      "os-name": browserInfo["os-name"],
      "os-version": browserInfo["os-version"],
      "browser-name": browserInfo["browser-name"],
      "browser-version": browserInfo["browser-version"],
      "user-agent": browserInfo["user-agent"],
      "app-version": browserInfo["app-version"],
      platform: browserInfo["platform"],
      vendor: browserInfo["vendor"],
    };

    if (Object.keys(mergedObject).length === 0) {
      setLoading(true);
      const betaUserRes = await dispatch(checkBetaUser({ email: username }));
      if (betaUserRes?.payload?.success) {
        // No validation errors, proceed with dispatch
        if (app == "THERAPIST") {
          const res = await dispatch(
            signinTherapist({
              payload,
              headers,
            })
          );
          if (res?.payload.password) {
            setErrors({
              passwordError: "Password is not correct. Please try again",
            });
          } else if (res?.payload.username) {
            setErrors({
              usernameError: "Email is not correct. Please try again",
            });
          }
          else {
            mixpanel?.identify(username)
            mixpanel?.people.set({'$email': username, 'role' : 'Therapist'});
            mixpanel.track("therapistLogin", {email: username});
          }
        } else {
          const res = await dispatch(
            signinUser({
              payload,
              headers,
            })
          );
          if (res?.payload.password) {
            setErrors({
              passwordError: "Password is not correct. Please try again",
            });
          } else if (res?.payload.username) {
            setErrors({
              usernameError: "Email is not correct. Please try again",
            });
          }
          else{
            mixpanel.identify(username)
            mixpanel.people.set({'$email': username, 'role' : 'Client'});
            mixpanel.track("clientLogin", {email: username});
          }
          
          if (res?.payload?.user) {
            if (!res?.payload?.user?.is_email_verified) {
              setLoading(true);
              const otpRes = await dispatch(sendOtp({ email: username }));
              if (otpRes) {
                setLoading(false);
                navigate("/verify-otp", {
                  state: { email: username, password: password, signUp: false },
                });
              } else {
                setLoading(false);
              }
            }
          }
        }
      } else {
        setLoading(false);
        window.location.href = "https://fitcyhealth.com/";
      }
    } else {
      // Set validation errors in state
      setErrors(mergedObject);
    }
  };

  const checkUsernameValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.usernameError = "Please input the email";
    else if (!usernamePattern.test(value))
      Errors.usernameError = "Please input a valid email";
    else delete Errors.usernameError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const checkPasswordValidation = (value, validator = {}) => {
    let Errors = validator;
    if (!value) Errors.passwordError = "Please input the password";
    else if (!passwordPattern.test(value))
      Errors.passwordError = "Please input a valid password";
    else delete Errors.passwordError;
    setErrors(Object.assign({}, Errors));
    return Errors;
  };

  const handleNavigation = () => {
    if (app == "THERAPIST") navigate(`/login`);
    else navigate(`/therapist/login`);
  };

  return (
    <div className="h-100 lg:overflow-hidden">
      <MetaTags
        title="Login | Fitcy Health"
        description="Login to your Fitcy Health account"
        url={`${app == "THERAPIST" ? "/therapist/login" : "/login"}`}
      />
      <Header className="lg:absolute" />
      <div className="flex flex-col lg:flex-row">
        <div className="flex justify-center lg:w-1/2 pb-24 lg:pb-0 pt-16 lg:pt-24">
          <div className="lg:w-[26rem] w-full overflow-auto h-[80vh] px-4">
            {/* <GoogleButton /> */}
            {/* <OrDivider /> */}
            <Form
              heading={
                wpRedirected && app != "THERAPIST"
                  ? "You’re almost there!"
                  : "Welcome to Fitcy Health"
              }
              peragraph="Log in to your account"
              method="POST"
              onSubmit={handleLogin}
              className="mt-6"
            >
              <Input
                icon={EmailIcon}
                label="Email Address"
                placeholder="Email"
                type="email"
                name="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  checkUsernameValidation(e.target.value, errors);
                }}
                error={errors.usernameError}
                app={app}
                onBlur={() => checkUsernameValidation(username, errors)}
              />

              <Input
                icon={passwordIcon}
                label="Password"
                placeholder="&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;"
                type="password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  checkPasswordValidation(e.target.value, errors);
                }}
                className="mt-6"
                inputClassName={`placeholder:text-xs`}
                error={errors.passwordError}
                app={app}
                onBlur={() => checkPasswordValidation(password, errors)}
              />

              <div
                className="text-sm mt-1 text-gray-500 cursor-pointer"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </div>

              <Button
                type="submit"
                className={`${
                  app === "THERAPIST"
                    ? "bg-blue-900 hover:bg-blue-500"
                    : "bg-teal-900 hover:bg-teal-500"
                }  hover:shadow-button transition-all mt-10 text-white`}
                app={app}
                full
              >
                {loading ? <ButtonLoader /> : "Login"}
              </Button>
              <div
                onClick={handleSignUp}
                className="text-gray-400 cursor-pointer mt-4 text-center"
              >
                Create new account
              </div>
            </Form>
            {app === "THERAPIST" ? (
              <div className="bg-teal-50 p-6 mt-6 space-y-2 rounded-xl">
                <div className="text-sm font-medium text-gray-400">
                  *If you’re not a therapist click below
                </div>
                <div
                  onClick={handleNavigation}
                  className="text-base font-medium text-teal-900 bg-teal-300 rounded-xl py-2.5 px-5 cursor-pointer flex justify-center"
                >
                  Login as Client
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between bg-blue-50 rounded-xl p-6 mt-6">
                <div className="space-y-2">
                  <div className="text-sm font-medium text-gray-400">
                    *If you’re a therapist click below
                  </div>
                  <div
                    onClick={handleNavigation}
                    className="text-base font-medium text-blue-600 bg-blue-300 rounded-xl py-2.5 px-5 cursor-pointer"
                  >
                    Login as Therapist
                  </div>
                </div>
                <img className="rounded-xl" src={TherapistLogin} />
              </div>
            )}
          </div>
        </div>
        {app === "THERAPIST" ? (
          <div
            className="h-screen flex-1 bg-cover lg:w-1/2 justify-end items-center hidden lg:flex overflow-hidden py-24 lg:py-0"
            style={{ backgroundImage: `url(${therapistPattern})` }}
          >
            <div className="relative">
              <img
                src={TherapistImage}
                className="w-9/12 ml-auto rounded-tl-xl rounded-bl-xl shadow-2xl"
              />
              <div className="p-3 rounded-lg  top-[-2.5rem]  bg-white/100 shadow-therapistInfoCard w-56 absolute  left-[40%]">
                <img src={calendar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Manage & grow your practice effortlessly
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/100 shadow-therapistInfoCard w-52 absolute top-[40%] left-[90px]">
                <img src={shieldDollar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Seamless payment system
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/100 shadow-therapistInfoCard w-[18.5rem] absolute bottom-[-5%]  left-[10%] lg:left-1/2">
                <img src={barChart} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Meaningful insights about your practice & client’s behavior
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="h-screen flex-1 bg-cover bg-teal-900 lg:w-1/2 justify-center items-center flex overflow-hidden px-4 py-24 lg:py-0 bg-center"
            style={{ backgroundImage: `url(${pattern})` }}
          >
            <Swiper />
          </div>
        )}
        <button
          onClick={goToLandingPage}
          className="absolute w-12 h-12 flex justify-center items-center left-4 lg:left-10 lg:top-24 rounded-full drop-shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
              fill="#101828"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Login;
