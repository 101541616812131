import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import dashboardLogo from "../../assets/logo.png";
import { logOutUser } from "../../redux/app/appSlice";
import Link from "../atoms/Link";
import notification from "../../assets/bell.svg";
import chevronDown from "../../assets/chevronDown.svg";
import help from "../../assets/help-circle.svg";
import userEdit from "../../assets/user-edit.svg";
import emptyNotificationList from "../../assets/empty_notification_list.svg";
import emptyTherapistNotificationList from "../../assets/empty_therapist_notification.svg";
import calendar from "../../assets/calendar-gray.svg";
import settings from "../../assets/settings.svg";
import logout from "../../assets/log-out.svg";
import shield from "../../assets/shield-02.svg";
import cross from "../../assets/x-close.svg";
import InfoIcon from "../../assets/info-circle-02.svg";
import { whatsappClientNumber } from "../../services/utils/constants";
import userAvatar from "../../assets/user-large.svg";
import Modal from "react-modal";
import Tooltip from "../atoms/Tooltip";
import {
  getUserNotification,
  readNotification,
} from "../../redux/onboarding/onboardingThunks";
import {
  calculateTimeDifference,
  getNotificationIcon,
} from "../../services/globalFunctions";
import { useMediaQuery } from "react-responsive";
import Loader from "../atoms/Loader";
import moment from "moment";
import { RTMClientContext } from "../../contexts/RTMClientContext";
import mixpanel from "mixpanel-browser";

const Header = ({
  className,
  dashboard,
  app,
  backButton,
  setStep,
  step,
  section,
}) => {
  const { rtmClient, isLoggedIn } = useContext(RTMClientContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.app.user);
  const notifications = useSelector((state) => state.onboarding.notifications);
  const unreadNotification = useSelector(
    (state) => state.onboarding.unreadNotification
  );
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(formatCurrentTime(new Date()));
  const [notificationModal, setNotificationModal] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const isTherapist = app === "THERAPIST"

  function formatCurrentTime(time) {
    return user?.timezone && moment(time).tz(user?.timezone).format("hh:mm A");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(formatCurrentTime(new Date()));
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     const apiInterval = setInterval(() => {
  //       dispatch(getUserNotification());
  //     }, 30000); // Update every 30 second

  //     return () => clearInterval(apiInterval);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (user) dispatch(getUserNotification());
  // }, [notificationModal]);

  useEffect(() => {
    if (user) dispatch(getUserNotification());
  }, []);

  useEffect(() => {
    const initializeRTM = async () => {
      try {
        await subscribeCall();
        setSubscribed(true);
        rtmClient.addEventListener("message", () => {
          dispatch(getUserNotification());
        });
      } catch (error) {
        console.error("Error Subscribe Notification channel", error);
      }
    };
    if (user && rtmClient && !subscribed && isLoggedIn) {
      initializeRTM();
    }
  }, [user, rtmClient, isLoggedIn]);

  const subscribeCall = async () => {
    try {
      const channelName = `noti_${user.id}`;
      const subscribeOptions = {
        withMessage: true,
        withPresence: true,
        withMetadata: true,
        withLock: true,
      };
      await rtmClient.subscribe(channelName, subscribeOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    dispatch(logOutUser());
    mixpanel?.reset();
  };
  const handleProfile = () => {
    if (isTherapist) navigate(`/therapist/profile`);
    else navigate(`/profile`);
  };
  const handleSettings = () => {
    if (isTherapist) navigate(`/dashboard/therapist-settings`);
    else navigate(`/settings`);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1); // This function navigates back in the history stack
  };

  const openDropdown = () => {
    setNotificationModal(true);
  };

  const commonModalStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "395px",
      width: "100%",
      top: isMobile ? "auto" : `3.8rem`,
      right: isMobile ? "0px" : `7.5rem`,
      border: "none",
      bottom: isMobile ? "0" : `"auto"`,
      borderRadius: isMobile ? "0px" : "1rem",
      height: "auto",
      maxHeight: isMobile ? "90vh" : "500px",
      background: "#fff",
      boxShadow: "0px 0px 36px 0px #00000014",
    },
    arrow: {
      display: isMobile ? "none" : "block",
      position: "absolute",
      border: "10px solid transparent",
      top: "-1.25rem",
      width: "1.25rem",
      height: "1.25rem",
      borderBottomColor: "#fff",
      zIndex: 2,
    },
    overlay: {
      background: isMobile ? "rgba(0, 0, 0, 0.09)" : "transparent",
      zIndex: "99999",
    },
  };

  const markReadNotification = async (value) => {
    setIsLoading(true);
    const res = await dispatch(
      readNotification({
        mark_read: value,
      })
    );
    if (res) setIsLoading(false);
  };

  const handleNotification = (item) => {
    if (!item?.notification_status) markReadNotification(item?.id?.toString())

    if ([1, 2, 3, 4, 8, 9, 12, 14]?.includes(item?.notification_iconcode)) {
      setNotificationModal(false)
      if (isTherapist) navigate("/therapist/sessions")
      else navigate("/sessions")
    }
    else if (item?.notification_iconcode === 13) {
      setNotificationModal(false)
      if (isTherapist) navigate("/therapist/messages")
      else navigate("/messages")
    } 
    else if (item?.notification_iconcode === 5) {
      setNotificationModal(false)
      navigate("/home")
    } 
    else if (item?.notification_iconcode === 11 && !isTherapist) {
      setNotificationModal(false)
      navigate("/sessions", { state: { pastTab: true } })
    }
    else if ([6, 7]?.includes(item?.notification_iconcode) && !isTherapist) {
      setNotificationModal(false)
      navigate("/resources", { state: { checkout: true } })
    }
  }

  return (
    <div
      className={`${dashboard ? "px-4 lg:pl-0 lg:pr-10" : "px-4 lg:px-10"}  ${
        backButton ? "justify-end" : "justify-between"
      } z-20 h-18 flex items-center w-full ${className}`}
    >
      {backButton && (
        <button
          onClick={() =>
            section === "profile"
              ? handleGoBack()
              : section === "sessions"
              ? setStep(1)
              : setStep(step > 3 ? step - 1 : 1)
          }
          className="absolute w-10 h-10 flex justify-center items-center left-4 lg:left-8 lg:top-4 rounded-full border border-solid border-gray-200 shadow-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M13.3333 8.66667H4M4 8.66667L8.66667 13.3333M4 8.66667L8.66667 4"
              stroke="#667085"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}

      {dashboard ? (
        <div
          className={`${
            isTherapist ? "lg:bg-blue-50" : ""
          } lg:w-24 h-full lg:border-r flex justify-center items-center`}
        >
          <Link href="/login">
            <img src={dashboardLogo} className="xs:w-16 w-20" />
          </Link>
        </div>
      ) : !backButton ? (
        <Link href="/login">
          <img src={logo} className="w-20" />
        </Link>
      ) : null}
      {user ? (
        <ul className="flex items-center text-sm space-x-3 lg:space-x-5">
          <li className="text-gray-400 pr-3 lg:pr-5 border-r border-gray-300 items-center whitespace-nowrap xs:max-w-20 max-w-24 lg:max-w-full overflow-hidden text-ellipsis block group">
            {currentTime} {user?.timezone}
            {isMobile && (
              <Tooltip
                pin="top center"
                position="bottom"
                className="left-1 right-1 mx-auto mt-2 max-w-max whitespace-normal w-[calc(100%-1rem)]"
              >
                {currentTime} {user?.timezone}
              </Tooltip>
            )}
          </li>
          {isTherapist ? (
            <li className="hidden lg:flex items-center text-gray-500 manage-availability bg-white">
              <a href="/therapist/profile/?availability">Manage Availability</a>{" "}
            </li>
          ) : (
            <li
              className="hidden lg:flex items-center text-gray-500 space-x-2 cursor-pointer"
              onClick={() =>
                window.open(`https://wa.me/${whatsappClientNumber}`)
              }
            >
              <span>Help & Support</span>{" "}
              <img src={help} className="cursor-pointer" />
            </li>
          )}

          <li onClick={openDropdown} className="relative cursor-pointer">
            {unreadNotification > 0 && (
              <span className="bg-red-500 w-4 h-4 flex items-center justify-center font-semibold text-[10px] text-white rounded-full absolute -top-2 -right-2">
                {unreadNotification}
              </span>
            )}
            <img src={notification} />
          </li>
          <li className="lg:relative">
            <span
              className="flex items-center space-x-2 cursor-pointer"
              onClick={() => setOpen(!open)}
            >
              {user?.profile_picture?.full_url ? (
                <img
                  src={user?.profile_picture?.full_url}
                  className="w-5 h-5 object-cover rounded-full"
                />
              ) : (
                <div className="bg-gray-100 rounded-full p-1">
                  <img
                    src={userAvatar}
                    className="w-4 h-4 object-cover rounded-full"
                  />
                </div>
              )}

              <span>{user.first_name}</span>
              <img src={chevronDown} />
            </span>
            <ul
              className={`fixed lg:absolute lg:top-8 bg-white lg:w-72 w-full rounded-lg shadow-therapistCard left-0 right-0 top-0 bottom-0 lg:bottom-auto  lg:right-auto lg:-left-40 z-20 ${
                open ? "block" : "hidden"
              }`}
              ref={dropdownRef}
            >
              <div style={commonModalStyles.arrow} className={`right-6`}></div>
              <li className="px-4 py-6 flex space-x-2">
                {user?.profile_picture?.full_url ? (
                  <img
                    src={user?.profile_picture?.full_url ?? userAvatar}
                    className="rounded-full w-12 h-12 object-cover"
                  />
                ) : (
                  <div className="bg-gray-100 rounded-full p-2">
                    <img
                      src={user?.profile_picture?.full_url ?? userAvatar}
                      className="rounded-full w-8 h-8 object-cover"
                    />
                  </div>
                )}

                <div>
                  <div className="font-medium text-sm ">
                    {user.first_name} {user.last_name}
                  </div>
                  <div className="text-xs text-gray-500 mt-0.5">
                    {user.username}
                  </div>
                </div>
                <img
                  src={cross}
                  onClick={() => setOpen(false)}
                  className="absolute right-4 top-4 cursor-pointer lg:hidden"
                />
              </li>
              <li
                className={`p-4 ${
                  isTherapist
                    ? "hover:bg-blue-100"
                    : "hover:bg-teal-500/10"
                } text-sm hover:text-gray-500 cursor-pointer`}
              >
                <button
                  className="font-medium shadow-none flex items-center space-x-2 w-full"
                  onClick={handleProfile}
                >
                  <img src={userEdit} />
                  <span>Profile</span>
                </button>
              </li>
              {isTherapist ? (
                <li
                  className={`p-4 ${
                    isTherapist
                      ? "hover:bg-blue-100"
                      : "hover:bg-teal-500/10"
                  } text-sm hover:text-gray-500 cursor-pointer`}
                >
                  <a
                    href="/therapist/profile/?availability"
                    className="font-medium flex items-center space-x-2"
                  >
                    <img src={calendar} />
                    <span>Manage Availability</span>
                  </a>
                </li>
              ) : null}

              <li
                className={`p-4 ${
                  isTherapist
                    ? "hover:bg-blue-100"
                    : "hover:bg-teal-500/10"
                } text-sm hover:text-gray-500 cursor-pointer`}
              >
                {isTherapist ? (
                  <a
                    href="/therapist/settings/"
                    className="font-medium flex items-center space-x-2"
                  >
                    <img src={settings} />
                    <span>Settings</span>
                  </a>
                ) : (
                  <button
                    className="font-medium shadow-none flex items-center space-x-2 w-full"
                    onClick={handleSettings}
                  >
                    <img src={settings} />
                    <span>Settings</span>
                  </button>
                )}
              </li>
              <li
                className={`p-4 ${
                  isTherapist
                    ? "hover:bg-blue-100"
                    : "hover:bg-teal-500/10"
                } text-sm hover:text-gray-500 cursor-pointer`}
              >
                <a
                  onClick={() =>
                    window.open(`https://fitcyhealth.com/privacy-policy/`)
                  }
                  className="font-medium flex items-center space-x-2"
                >
                  <img src={shield} />
                  <span>Privacy Policy</span>
                </a>
              </li>
              <li
                className={`p-4 ${
                  isTherapist
                    ? "hover:bg-blue-100"
                    : "hover:bg-teal-500/10"
                } text-sm hover:text-gray-500 cursor-pointer`}
              >
                <a
                  className="font-medium flex items-center space-x-2 cursor-pointer"
                  onClick={() =>
                    window.open(`https://wa.me/${whatsappClientNumber}`)
                  }
                >
                  <img src={InfoIcon} />
                  <span>Help & Support </span>
                </a>
              </li>
              <li
                className={`p-4 ${
                  isTherapist
                    ? "hover:bg-blue-100"
                    : "hover:bg-teal-500/10"
                } text-sm hover:text-gray-500 cursor-pointer`}
              >
                <button
                  className="font-medium shadow-none flex items-center space-x-2 w-full"
                  onClick={handleLogout}
                >
                  <img src={logout} />
                  <span>Log out</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      ) : null}
      <Modal
        isOpen={notificationModal}
        onRequestClose={() => {
          setNotificationModal(false);
        }}
        style={commonModalStyles}
        className={`${
          isMobile && notificationModal
            ? "translate-y-0"
            : "translate-y-full lg:translate-y-0"
        } w-full transform transition-transform fixed  bottom-0 outline-none `}
      >
        <div style={commonModalStyles.arrow} className={`right-[2.9rem]`}></div>
        <div className="py-6">
          <div className="flex items-center justify-between mb-5 px-6">
            <div className="text-2xl font-medium">Notifications</div>
            {unreadNotification > 0 && (
              <div
                onClick={() => markReadNotification("all")}
                className="text-xs text-gray-500 cursor-pointer"
              >
                Mark all as read
              </div>
            )}
          </div>
          {notifications?.length > 0 ? (
            <div
              className={`scroll-track overflow-y-auto h-full ${
                isMobile ? "max-h-[60vh]" : "max-h-[26rem]"
              }`}
            >
              {notifications?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleNotification(item) }
                    key={index}
                    className={` ${
                      isTherapist
                        ? "hover:bg-blue-100 "
                        : "hover:bg-teal-100 "
                    } relative  flex gap-x-4 hover:cursor-pointer items-start py-2 px-6`}
                  >
                    {item?.notification_iconcode == 13 ? (
                      <img
                        className="h-10 w-10 rounded-xl"
                        src={item?.notification_dump?.icon_url || userAvatar}
                      />
                    ) : (
                      <img
                        src={getNotificationIcon(item?.notification_iconcode)}
                      />
                    )}

                    <div className="w-3/4 lg:w-64">
                      <div className="text-sm">
                        {item?.notification_text || ""}
                      </div>
                      <div className="text-[10px] text-gray-500">
                        {calculateTimeDifference(item?.created_at)}
                      </div>
                      <div className="flex items-center space-x-2">
                        {item?.notification_action?.map((i) => {
                          return (
                            <div
                              className="text-sm font-medium text-teal-900 rounded-full bg-teal-100 py-1 px-2 mt-1"
                              key={i}
                            >
                              {i}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {!item?.notification_status && (
                      <div
                        className={`${
                          isTherapist ? "bg-blue-900" : "bg-teal-500"
                        } h-1.5 w-1.5 rounded-full mt-1`}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center flex-col pt-12 pb-12 lg:pb-0">
              <img
                src={
                  isTherapist
                    ? emptyTherapistNotificationList
                    : emptyNotificationList
                }
              />
              <div className="text-sm font-medium mt-4">
                Your notifications will appear here
              </div>
              {/* <div className="text-xs text-gray-400 text-center w-2/3 mt-1.5">
              Et commodi et ad qui quo reiciendis et. Veritatis dolor iure
              commodi.
            </div> */}
            </div>
          )}
          {isLoading && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

export default Header;
