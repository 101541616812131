import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/molecules/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSessionsList,
  getSessionsList,
  getPackages,
  deleteAppointment,
  rescheduleSession,
  submitPatientProfile,
} from "../../redux/patient/patientThunks";
import {
  getPackageDetails,
  submitSession,
} from "../../redux/onboarding/onboardingThunks";
import Checkout from "../../components/molecules/Checkout";
import ScheduleSession from "../../components/molecules/ScheduleSession";
import PaymentStatus from "../../components/molecules/PaymentStatus";
import SideMenu from "../../components/molecules/SideMenu";
import Heading from "../../components/atoms/Heading";
import Button from "../../components/atoms/Button";
import SessionCard from "../../components/molecules/SessionCard";
import backgroundImage from "../../assets/onboarding/bg-shadow.png";
import PlayButton from "../../assets/onboarding/play-circle.svg";
import Notes from "../../assets/notes.png";
import Check from "../../assets/check.svg";
import User from "../../assets/user.svg";
import calendarGray from "../../assets/calendar-gray.svg";
import calendarGreen from "../../assets/calendarGreen.svg";
import starsGreen from "../../assets/stars-green.svg";
import close from "../../assets/x-close.svg";
import VideRecorderLarge from "../../assets/video-recorder-large.svg";
import VideRecorder from "../../assets/video-session-icon.svg";
import clock from "../../assets/clock-dark-gray.svg";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import AnnaouncementIcon from "../../assets/announcement.svg";
import BlueIcon from "../../assets/Blue.png";
import GoldIcon from "../../assets/Gold.png";
import SilverIcon from "../../assets/Silver.png";
import BlueIco from "../../assets/blue-tier-ico.svg";
import GoldIco from "../../assets/Gold-tier-ico.svg";
import SilverIco from "../../assets/silver-tier-ico.svg";
import moment from "moment";
import { whatsappClientNumber } from "../../services/utils/constants";
import ProductTour from "../../components/molecules/ProductTour";
import SkeletonComponent from "../../components/atoms/SkeletonComponent";
import SuccessScreen from "../../components/molecules/SuccessScreen";
import { useLocation } from "react-router-dom";
import NoSessions from "../../assets/No_past_session.svg";
import { MetaTags } from "../../services/globalFunctions";
// import priceTag from "../../assets/price-tag-fill.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import mixpanel from "mixpanel-browser";

const PatientSessions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.app.user);
  const [appointmentsData, setAppointmentsData] = useState(null);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [loader, setLoader] = useState(false);
  const [scheduleLoader, setScheduleLoader] = useState(false);
  const [reScheduleLoader, setReScheduleLoader] = useState(false);
  const [packagesLoader, setPackagesLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [successData, setSuccessData] = useState("");
  const [tourGuide, setTourGuide] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [sessionData, setSessionData] = useState("");
  const [selectedProposedSlot, setSelectedProposedSlot] = useState("");
  const [selectedProposedTime, setSelectedProposedTime] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [targetTherapist, setTargetTherapist] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [selectedDisplayTherapist, setSelectedDisplayTherapist] =
    useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [packages, setPackages] = useState(null);
  const [reschedule, setReschedule] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const buttonRef = useRef(null);
  const nextSessionRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the container
        threshold: 0, // Trigger when the button is fully out of view
      }
    );

    const currentRef =
      sessions && selectedPackage?.credit_available > 0
        ? nextSessionRef.current
        : buttonRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [selectedPackage, sessions, activeTabIndex]);
  const handleShowAll = () => {
    setShowAll(true);
  };
  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };
  const divRef = useRef();
  const maxCategoriesToShow = 3;
  const certifiedKeys = [];
  const expertise =
    typeof targetTherapist?.certified_on === "string"
      ? JSON.parse(targetTherapist?.certified_on)
      : targetTherapist?.certified_on;

  expertise &&
    Object.entries(expertise).forEach(([, disorders]) => {
      Object.entries(disorders).forEach(([disorder, status]) => {
        if (status === "Certified") {
          certifiedKeys.push(disorder);
        }
      });
    });
  const visibleCategories = certifiedKeys
    .slice(0, maxCategoriesToShow)
    .sort((a, b) => {
      return a.localeCompare(b);
    });
  // const hiddenCategories = certifiedKeys.slice(maxCategoriesToShow);
  const scheduledRecords = sessions?.upcoming?.filter(
    (record) => record.status === "scheduled"
  );

  useEffect(() => {
    if (location?.state?.pastTab) setActiveTabIndex(1)
    localStorage.removeItem("wp-redirect-user");
    localStorage.removeItem("user-email");
    document.body.classList.remove("overflow-hidden");
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAllSessionsList());
        if (response.payload) {
          setAppointmentsData(response.payload);
          setSelectedTherapist(response.payload.data.latest_therapist);
          setSelectedDisplayTherapist(response.payload.data.latest_therapist);
          setSelectedPackage(
            response.payload.data.latest_therapist?.ongoing[0]
          );
          setSessions(response.payload.data.latest_therapist?.ongoing[0]);
          setLoadingSkeleton(false);
        }
        // After getAllSessionsList completes, you can access the updated state
      } catch (error) {
        // Handle any errors that might occur during getAllSessionsList
        console.error("Error fetching appointment list:", error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);

  useEffect(() => {
    if (!tourGuide) {
      dispatch(
        submitPatientProfile({
          tour_guide: false,
        })
      );
    }
  }, [tourGuide]);

  const handlePurchaseNow = () => {
    window.location.replace("/resources/?skip_questions");
  };
  const handleScheduleSession = async (id) => {
    setScheduleLoader(true);

    const response = await dispatch(
      getPackageDetails({
        package_id: id,
        patient_id: user.id,
      })
    );
    if (response.payload) {
      setSchedule(true);
      setStep(4);
      setScheduleLoader(false);
    }
  };

  const handleBuyCredits = async (id) => {
    await dispatch(
      submitSession({
        package_id: id,
      })
    );
    setStep(5);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openCreditsModal = async (buttonId) => {
    setPackagesLoader((prevLoaders) => ({
      ...prevLoaders,
      [buttonId]: true,
    }));
    try {
      const response = await dispatch(
        getPackages({ id: selectedTherapist.id })
      );
      if (response.payload) {
        setPackages(response.payload.data);
        setIsCreditsModalOpen(true);
        setPackagesLoader((prevLoaders) => ({
          ...prevLoaders,
          [buttonId]: false,
        }));
        setIsDropdownOpen(false);
      }
      // After getPackages completes, you can access the updated state
    } catch (error) {
      // Handle any errors that might occur during getPackages
      console.error("Error fetching packages list:", error);
    }
  };

  const closeCreditsModal = () => {
    setIsCreditsModalOpen(false);
  };

  const openDropdown = (modal) => {
    const boundingBox = divRef.current.getBoundingClientRect();

    // Calculate the top position by considering both bounding box and scroll position
    const topPosition = boundingBox.top + window.scrollY + 64 - window.scrollY;

    // Calculate the left position based on the modal type
    const leftPosition =
      modal === "therapist" ? boundingBox.left + window.scrollX : "initial";

    setModalPosition({
      top: topPosition,
      left: leftPosition,
    });

    setIsDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openTherapist = (therapist) => {
    setTargetTherapist(therapist);
  };

  const handleChangeTherapist = async (therapist) => {
    setSelectedTherapist(therapist);
    if (therapist.ongoing.length > 0) {
      setSelectedDisplayTherapist(therapist);
      handleChangePackage(therapist.ongoing[0], therapist);
    }
  };

  const handleChangePackage = async (item, therapist) => {
    if (!therapist) {
      setSelectedDisplayTherapist(selectedTherapist);
    }
    setSelectedPackage(item);
    // setIsDropdownOpen(false);
    setSessions(null);
    setLoadingSkeleton(true);
    setActiveTabIndex(item.therapist_state === "Completed" ? 1 : 0);
    try {
      const response = await dispatch(
        getSessionsList({
          id: therapist ? therapist.id : selectedTherapist.id,
          slotId: item.package.slot_id,
          serviceId: item.package.service_id,
        })
      );
      if (response.payload) {
        setSessions(response.payload.data);
        setLoadingSkeleton(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = async (id) => {
    try {
      await dispatch(deleteAppointment({ id: id }));
      dispatch(getSessionsList({ id: selectedTherapist.id }));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRescheduleRequest = (data, index) => {
    setReScheduleLoader(true);
    if (data?.reschedule_req_data) {
      data?.reschedule_req_data?.proposed_slots?.sort((a, b) => new Date(a.date) - new Date(b.date))
      let item = { ...data, sessionIndex: index + 1 };
      setIsRescheduleModalOpen(true);
      setSessionData(item);
      setSelectedProposedSlot(
        data?.reschedule_req_data?.proposed_slots?.[0] || ""
      );
      setSelectedProposedTime(
        data?.reschedule_req_data?.proposed_slots?.[0]?.slots?.[0] || {}
      );
    } else {
      handleReschedule({
        appointment: "reschedule",
        appointment_id: data?.id,
        session_date: data.session_date,
        start_time: data.start_time,
      });
    }
  };
  const handleRescheduleClose = () => {
    setIsRescheduleModalOpen(false);
    setReScheduleLoader(false);
  };

  const onRescheduleSession = async () => {
    try {
      await dispatch(
        rescheduleSession({
          package_id: sessionData?.package_id,
          selected_slot: {
            ...selectedProposedTime,
            appointment: "reschedule",
            appointment_id: sessionData?.id,
          },
        })
      );

      mixpanel?.track("rescheduleConfirmed", {
        package_id: sessionData?.package_id,
        selected_slot: {
          ...selectedProposedTime,
          appointment: "reschedule",
          appointment_id: sessionData?.id,
        },
        role: user.role,
        userId: user.id
      });

      setIsRescheduleModalOpen(false);
    } catch (error) {
      console.error("Error reschedule session:", error);
    }
  };
  const handleReschedule = async (obj) => {
    setLoader(true);
    const response = await dispatch(
      getPackageDetails({
        package_id: selectedPackage?.package.id,
        patient_id: user.id,
      }),
      window.localStorage.setItem("appointment_id", obj.appointment_id)
    );
    if (response.payload) {
      setSchedule(false);
      setReschedule(obj);
      setStep(4);
      setLoader(false);
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const customStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "390px",
      width: "100%",
      top: isMobile ? "auto" : `${modalPosition.top}px`,
      // left: isMobile ? "0" : `${modalPosition.left}px`,
      right: isMobile ? "0" : "1.5rem",
      border: "none",
      borderRadius: isMobile ? "0" : "16px",
      padding: "0",
      height: targetTherapist
        ? "auto"
        : isMobile
          ? "100vh"
          : `calc(99vh - ${modalPosition.top}px)`,
      maxHeight:
        selectedTherapist &&
          selectedTherapist.ongoing &&
          selectedTherapist.ongoing.length > 2 &&
          !showAll
          ? "40rem"
          : targetTherapist
            ? ""
            : "38rem",
      // overflow: "auto",
      bottom: isMobile ? "0" : "auto",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.3)",
      zIndex: "20",
    },
  };
  const creditsModalStyles = {
    content: {
      maxWidth: "50.6rem",
      width: "100%",
      left: 0,
      right: 0,
      margin: "auto",
      border: "none",
      borderRadius: isMobile ? "0" : "16px",
      maxHeight: isMobile ? "100%" : "calc(100% - 6rem)",
      height: "auto",
      background: "#fff",
      overflow: "auto",
    },
    overlay: {
      display: "flex",
    },
  };

  const rescheduleModalStyles = {
    content: {
      maxWidth: "455px",
      width: "100%",
      left: 0,
      right: 0,
      margin: "auto",
      border: "none",
      borderRadius: isMobile ? "0" : "16px",
      maxHeight: isMobile ? "100%" : "calc(100% - 6rem)",
      height: "auto",
      background: "#fff",
      overflowY: "auto",
    },
    overlay: {
      display: "flex",
    },
  };

  return (
    <div>
      <MetaTags
        title="Sessions | Fitcy Health"
        description="Manage your Fitcy Health sessions"
        url="/sessions"
      />
      <Header
        className="border-b border-gray-200 fixed z-20 bg-white"
        dashboard={step === 1 ? true : false}
        backButton={step > 1 && step < 7 ? true : false}
        setStep={(value) => setStep(value)}
        section="sessions"
      />{" "}
      <div className={`${step === 1 ? "lg:flex lg:pl-24" : ""} pt-[4.5rem]`}>
        {step === 1 ? (
          <>
            <SideMenu className="bg-white left-0 lg:top-[4.45rem]" />
            <div className="w-full relative min-h-[calc(100vh-4.5rem)]">
              <div className="pb-[4.5rem] p-4 lg:p-6 border-b border-b-gray-100 bg-white relative z-[1] flex lg:block flex-col-reverse">
                <Heading
                  variant="h1"
                  className="text-lg lg:text-2xl font-medium mt-4 lg:mt-0"
                >
                  My sessions
                </Heading>
                <div className="flex gap-2 lg:gap-3 relative lg:max-w-max w-full lg:ml-auto">
                  <div
                    className="px-2 py-1.5 bg-teal-50 rounded-xl flex flex-1 gap-x-1 lg:gap-x-2 items-center cursor-pointer my-therapist"
                    onClick={() => openDropdown("therapist")}
                    ref={divRef}
                  >
                    <div className="bg-white border border-gray-100 rounded-xl flex items-center justify-center w-8 h-8 lg:w-11 lg:h-11 relative">
                      {selectedDisplayTherapist?.profile_picture?.full_url ? (
                        <img
                          src={
                            selectedDisplayTherapist?.profile_picture?.full_url
                          }
                          className="rounded-xl w-full h-full object-cover"
                        />
                      ) : (
                        <img src={User} />
                      )}
                      <div className="w-4 h-4 bg-white rounded-full shadow-md absolute flex items-center justify-center -bottom-0.5 -right-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.60345 1.96484H6.11183H6.11184C6.48012 1.96484 6.78407 1.96483 7.03168 1.98506C7.28885 2.00608 7.52525 2.05117 7.74729 2.16431C8.09164 2.33976 8.37161 2.61973 8.54706 2.96408C8.6602 3.18613 8.7053 3.42253 8.72631 3.6797C8.74507 3.90934 8.74643 4.18745 8.74652 4.52041L9.64033 3.6266C9.72778 3.53913 9.81373 3.45315 9.88908 3.39006C9.95631 3.33378 10.0979 3.22091 10.294 3.20547C10.513 3.18824 10.727 3.27689 10.8697 3.44393C10.9974 3.59352 11.0177 3.77344 11.0255 3.86077C11.0341 3.95866 11.0341 4.08025 11.0341 4.20395V4.20396V7.04594V7.04595C11.0341 7.16965 11.0341 7.29123 11.0255 7.38911C11.0177 7.47645 10.9974 7.65637 10.8697 7.80595C10.727 7.973 10.513 8.06165 10.294 8.04441C10.0979 8.02898 9.95631 7.91611 9.88908 7.85982C9.81373 7.79674 9.72778 7.71076 9.64033 7.62329L9.6403 7.62326L8.74652 6.72948C8.74643 7.06246 8.74507 7.3406 8.72631 7.57025C8.7053 7.82742 8.6602 8.06382 8.54706 8.28587C8.37161 8.63022 8.09164 8.91019 7.74729 9.08564C7.52525 9.19878 7.28885 9.24387 7.03168 9.26489C6.78407 9.28512 6.48011 9.28511 6.11182 9.2851H6.11182H3.60345H3.60344C3.23516 9.28511 2.93121 9.28512 2.6836 9.26489C2.42643 9.24387 2.19003 9.19878 1.96798 9.08564C1.62363 8.91019 1.34367 8.63022 1.16822 8.28587C1.05508 8.06382 1.00998 7.82742 0.98897 7.57025C0.96874 7.32265 0.968745 7.01869 0.96875 6.65041V6.65041V4.59954V4.59953C0.968745 4.23125 0.96874 3.9273 0.98897 3.6797C1.00998 3.42253 1.05508 3.18613 1.16822 2.96408C1.34367 2.61973 1.62363 2.33976 1.96798 2.16431C2.19003 2.05117 2.42643 2.00608 2.6836 1.98506C2.9312 1.96483 3.23516 1.96484 3.60344 1.96484H3.60345ZM4.6992 4.01932C4.94934 3.76918 5.35489 3.76919 5.60503 4.01933L6.75802 5.17231C7.00816 5.42245 7.00816 5.82801 6.75802 6.07815L5.60503 7.23114C5.35489 7.48128 4.94934 7.48127 4.6992 7.23113L3.54621 6.07815C3.29607 5.82801 3.29607 5.42245 3.54621 5.17231L4.6992 4.01932Z"
                            fill="#009688"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-xs text-gray-500 font-medium">
                        My Assigned Therapist
                      </div>

                      <div className="whitespace-nowrap text-ellipsis w-24 lg:w-28 overflow-hidden text-sm lg:text-base font-medium">
                        {selectedDisplayTherapist
                          ? `${selectedDisplayTherapist?.first_name}
                        ${selectedDisplayTherapist?.last_name}`
                          : "Not Assigned"}
                      </div>
                    </div>
                    <div className="p-2 pl-0 lg:pl-2 ml-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 15L12 20L17 15M7 9L12 4L17 9"
                          stroke="#009688"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <Modal
                    isOpen={isDropdownOpen}
                    onRequestClose={handleCloseDropdown}
                    modalPosition={modalPosition}
                    style={customStyles}
                    className={`${isMobile && isDropdownOpen
                        ? "translate-y-0"
                        : "translate-y-full lg:translate-y-0"
                      } w-full transform transition-transform fixed bottom-0 outline-none`}
                  >
                    <div className="p-4 rounded-t-2xl lg:rounded-2xl bg-white therapistCard border border-gray-200 w-full h-full">
                      <div className="transition-all h-full">
                        {targetTherapist ? (
                          <div>
                            <div className="bg-teal-50 h-24 -mx-4 -mt-4 rounded-t-2xl relative">
                              <div
                                className="cursor-pointer absolute left-4 top-4"
                                onClick={() => setTargetTherapist(null)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M20 12H4M4 12L10 18M4 12L10 6"
                                    stroke="#667085"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="flex items-center space-x-3 -mt-14 relative z-10">
                              <div className="bg-white border-2 border-white rounded-full flex items-center justify-center w-20 h-20 shadow-dp">
                                <img
                                  src={
                                    targetTherapist?.profile_picture
                                      ?.full_url ?? User
                                  }
                                  className="rounded-full w-full h-full"
                                />
                              </div>
                              <div className="px-2 py-1 rounded-3xl bg-teal-100 text-xs text-gray-400 border border-teal-300 hidden">
                                <span className="text-teal-900 font-medium">
                                  97%
                                </span>{" "}
                                client satisfaction
                              </div>
                            </div>
                            <div className="mt-3 text-2xl font-medium flex  items-center space-x-1">
                              <span>
                                {targetTherapist?.first_name}{" "}
                                {targetTherapist?.last_name}
                              </span>
                              <img
                                src={
                                  targetTherapist.tier === "BLUE"
                                    ? BlueIcon
                                    : targetTherapist.tier === "GOLD"
                                      ? GoldIcon
                                      : SilverIcon
                                }
                              />
                            </div>
                            <div className="text-sm mt-0.5 font-medium">
                              {targetTherapist?.designation}
                            </div>
                            <div className="flex items-start space-x-3.5 mt-8">
                              {visibleCategories.length > 0 && (
                                <img src={starsGreen} />
                              )}
                              <div>
                                {visibleCategories.length > 0 && (
                                  <div className="text-sm font-medium">
                                    Specialized in treating
                                  </div>
                                )}
                                <ul className="text-sm text-gray-500 font-medium mt-1 space-y-1">
                                  {visibleCategories?.map((item, index) => (
                                    <li
                                      key={index}
                                      className="pr-2 flex items-start"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="3"
                                        height="4"
                                        viewBox="0 0 3 4"
                                        fill="none"
                                        className="mr-1 mt-2"
                                      >
                                        <circle
                                          cx="1.5"
                                          cy="2.08301"
                                          r="1.5"
                                          fill="#98A2B3"
                                        />
                                      </svg>
                                      <span className="flex-1">{item}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>

                            <div className="px-2 py-2.5 border bg-[#F7F7F8] border-gray-200 rounded-2xl max-w-max mt-4">
                              <div className="font-semibold">
                                {selectedPackage?.package.product_count < 10
                                  ? "0"
                                  : ""}
                                {selectedPackage?.package.product_count}
                              </div>
                              <div className="text-gray-400 text-xs font-semibold uppercase">
                                sessions with me
                              </div>
                            </div>
                            <div
                              className="pt-4 text-xs text-gray-500 font-medium text-center border-t border-t-gray-200 mt-4 cursor-pointer"
                              onClick={() =>
                                window.open(
                                  `https://wa.me/${whatsappClientNumber}`
                                )
                              }
                            >
                              Want to change your therapist?
                            </div>
                          </div>
                        ) : (
                          <>
                            <button
                              onClick={handleCloseDropdown}
                              className="shadow-none absolute right-4 lg:hidden"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M18 6L6 18M6 6L18 18"
                                  stroke="#667085"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <Heading
                              variant="h3"
                              className="text-xl font-medium"
                            >
                              Switch therapist
                            </Heading>
                            <p className="mt-1 text-gray-500 font-normal">
                              Switch between your available therapists
                            </p>
                            <Swiper
                              autoHeight={true}
                              slidesPerView={2.2}
                              spaceBetween={8}
                              className="w-full mt-2 !py-1"
                            >
                              {appointmentsData?.data.my_therapists.map(
                                (item, index) => {
                                  return (
                                    <SwiperSlide
                                      key={index}
                                      className={`px-3 py-2 rounded-lg cursor-pointer shadow ${item?.id === selectedTherapist?.id
                                          ? "bg-teal-500 text-teal-50"
                                          : "bg-white"
                                        }`}
                                      onClick={() =>
                                        handleChangeTherapist(item)
                                      }
                                    >
                                      <div className="flex items-center space-x-3">
                                        <div className="bg-white border-2 border-gray-100 rounded-md flex items-center justify-center w-6 h-6 shadow-dp">
                                          {item.profile_picture?.full_url ? (
                                            <img
                                              src={
                                                item.profile_picture?.full_url
                                              }
                                              className="rounded-full w-full h-full object-cover"
                                            />
                                          ) : (
                                            <img src={User} />
                                          )}
                                        </div>
                                        <div className="flex-1">
                                          <div className="text-sm font-medium flex items-center space-x-1">
                                            <span className="text-ellipsis max-w-20 overflow-hidden whitespace-nowrap">
                                              {item.first_name} {item.last_name}
                                            </span>
                                            <img
                                              src={
                                                item.tier === "BLUE"
                                                  ? BlueIcon
                                                  : item.tier === "GOLD"
                                                    ? GoldIcon
                                                    : SilverIcon
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                }
                              )}
                            </Swiper>
                            <Tabs
                              className={`w-full simple-tabs mt-3 px-3 py-2 border border-gray-300 rounded-2xl bg-[#F9F9F9] h-[calc(100%-12.5rem)]`}
                            >
                              <TabList className="flex text-center border-b border-b-gray-200">
                                <Tab className="px-4 font-medium py-3 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs flex-1">
                                  Ongoing
                                </Tab>
                                <Tab className="px-4 font-medium py-3 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs flex-1">
                                  Expired/Paused
                                </Tab>
                              </TabList>

                              <TabPanel className="mt-4 max-h-[calc(100%-3.5rem)] overflow-auto">
                                <ul className="space-y-3">
                                  {selectedTherapist &&
                                    selectedTherapist.ongoing &&
                                    selectedTherapist.ongoing.length > 0 ? (
                                    (showAll
                                      ? selectedTherapist.ongoing
                                      : selectedTherapist.ongoing.slice(0, 2)
                                    ).map((item, index) => (
                                      <li
                                        key={index}
                                        className={`p-4 border rounded-2xl cursor-pointer ${item.package &&
                                            item.package.id ===
                                            selectedPackage?.package.id
                                            ? "border-teal-500 bg-teal-100"
                                            : "border-gray-200 bg-white"
                                          }`}
                                        onClick={() =>
                                          handleChangePackage(item)
                                        }
                                      >
                                        <div className="flex items-center justify-between">
                                          <div className="flex space-x-2 items-center w-[calc(100%-9rem)]">
                                            <div
                                              className={`w-4.5 h-4.5 border border-gray-300 rounded-full flex items-center justify-center ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "bg-teal-500"
                                                  : "border-gray-200 bg-white"
                                                }`}
                                            >
                                              {item.package &&
                                                item.package.id ===
                                                selectedPackage?.package
                                                  .id && (
                                                  <div className="w-2 h-2 bg-white rounded-full"></div>
                                                )}
                                            </div>
                                            <div className="text-sm font-medium text-ellipsis whitespace-nowrap flex-1 overflow-hidden">
                                              {item?.package?.product_name}
                                            </div>
                                          </div>
                                          <div className="flex space-x-2">
                                            <div
                                              className={`text-xs font-medium rounded-3xl px-1.5 py-0.5 max-w-max ${item.therapist_state ===
                                                  "Ongoing" ||
                                                  item.therapist_state ===
                                                  "Unutilized"
                                                  ? "bg-teal-50 text-teal-500"
                                                  : item.therapist_state ===
                                                    "Completed"
                                                    ? "bg-teal-100 text-teal-900"
                                                    : item.therapist_state ===
                                                      "Paused"
                                                      ? "bg-warning-100 text-warning-900"
                                                      : "bg-gray-200 text-gray-400"
                                                }`}
                                            >
                                              {item.therapist_state ===
                                                "Unutilized"
                                                ? "Ongoing"
                                                : item.therapist_state}
                                            </div>

                                            {item.package && (
                                              <div
                                                className={`text-xs font-medium rounded-3xl px-1.5 py-0.5 max-w-max bg-teal-500 text-white `}
                                              >
                                                {item.package.slot_minutes} mins
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <ul className="mt-4 text-sm space-y-1">
                                          <li className="flex space-x-4 items-center">
                                            <span
                                              className={
                                                item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-500"
                                                  : "text-gray-400"
                                              }
                                            >
                                              Total Sessions
                                            </span>
                                            <span
                                              className={`border border-dashed flex-1 h-[1px] ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "border-white"
                                                  : ""
                                                }`}
                                            ></span>
                                            <span
                                              className={`font-medium ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-900"
                                                  : "text-gray-400"
                                                }`}
                                            >
                                              {item.total_credits < 10
                                                ? "0"
                                                : ""}
                                              {item.total_credits}
                                            </span>
                                          </li>
                                          <li className="flex space-x-4 items-center">
                                            <span
                                              className={
                                                item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-500"
                                                  : "text-gray-400"
                                              }
                                            >
                                              Credits Available
                                            </span>
                                            <span
                                              className={`border border-dashed flex-1 h-[1px] ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "border-white"
                                                  : ""
                                                }`}
                                            ></span>
                                            <span
                                              className={`font-medium ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-900"
                                                  : "text-gray-400"
                                                }`}
                                            >
                                              {item.credit_available < 10
                                                ? "0"
                                                : ""}
                                              {item.credit_available}
                                            </span>
                                          </li>

                                          <li className="flex space-x-4 items-center">
                                            <span
                                              className={
                                                item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-500"
                                                  : "text-gray-400"
                                              }
                                            >
                                              Expiry
                                            </span>
                                            <span
                                              className={`border border-dashed flex-1 h-[1px] ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "border-white"
                                                  : ""
                                                }`}
                                            ></span>
                                            <span
                                              className={`font-medium ${item.package &&
                                                  item.package.id ===
                                                  selectedPackage?.package.id
                                                  ? "text-gray-900"
                                                  : "text-gray-400"
                                                }`}
                                            >
                                              {item?.expiring_soon
                                                ? item.expiring_soon.expiry_date
                                                : "N/A"}
                                            </span>
                                          </li>
                                        </ul>
                                      </li>
                                    ))
                                  ) : (
                                    <li className="text-sm tex-gray-500 mt-10 text-center">
                                      No Active Packages available
                                    </li>
                                  )}
                                </ul>
                                {selectedTherapist &&
                                  selectedTherapist.ongoing &&
                                  selectedTherapist.ongoing.length > 2 &&
                                  !showAll && (
                                    <Button
                                      type="button"
                                      className="w-full !px-2 !py-1 mt-3 bg-teal-100 text-teal-900 !text-xs shadow-none"
                                      onClick={handleShowAll}
                                    >
                                      View More
                                    </Button>
                                  )}
                              </TabPanel>

                              <TabPanel className="max-h-[calc(100%-3.5rem)] overflow-auto">
                                <ul className="space-y-3">
                                  {selectedTherapist?.paused.length > 0 ? (
                                    selectedTherapist?.paused.map(
                                      (item, index) => {
                                        return (
                                          <li
                                            key={index}
                                            className={`p-4 border rounded-2xl cursor-pointer ${item?.package?.id ===
                                                selectedPackage?.package.id
                                                ? "border-teal-500 bg-teal-100"
                                                : "border-gray-200 bg-white"
                                              }`}
                                            onClick={() =>
                                              handleChangePackage(item)
                                            }
                                          >
                                            <div className="flex items-center justify-between">
                                              <div className="flex space-x-2 items-center w-[calc(100%-9rem)]">
                                                <div
                                                  className={`w-4.5 h-4.5 border border-gray-300 rounded-full flex items-center justify-center ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "bg-teal-500"
                                                      : "border-gray-200 bg-white"
                                                    }`}
                                                >
                                                  {item?.package?.id ===
                                                    selectedPackage?.package
                                                      .id && (
                                                      <div className="w-2 h-2 bg-white rounded-full"></div>
                                                    )}
                                                </div>
                                                <div className="text-sm font-medium text-ellipsis whitespace-nowrap flex-1 overflow-hidden">
                                                  {item?.package?.product_name}
                                                </div>
                                              </div>
                                              <div className="flex space-x-2">
                                                <div
                                                  className={`text-xs font-medium rounded-3xl px-1.5 py-0.5 max-w-max mb-0.5 ${item.therapist_state ===
                                                      "Ongoing" ||
                                                      item.therapist_state ===
                                                      "Unutilized"
                                                      ? "bg-blue-100 text-blue-900"
                                                      : item.therapist_state ===
                                                        "Completed"
                                                        ? "bg-teal-100 text-teal-900"
                                                        : item.therapist_state ===
                                                          "Paused"
                                                          ? "bg-warning-100 text-warning-900"
                                                          : "bg-gray-200 text-gray-400"
                                                    }`}
                                                >
                                                  {item.therapist_state ===
                                                    "Unutilized"
                                                    ? "Ongoing"
                                                    : item.therapist_state}
                                                </div>
                                                {item.package && (
                                                  <div
                                                    className={`text-xs font-medium rounded-3xl px-1.5 py-0.5 max-w-max bg-teal-500 text-white `}
                                                  >
                                                    {item.package.slot_minutes}{" "}
                                                    mins
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <ul className="mt-4 text-sm space-y-1">
                                              <li className="flex space-x-4 items-center">
                                                <span
                                                  className={
                                                    item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-500"
                                                      : "text-gray-400"
                                                  }
                                                >
                                                  Total Sessions
                                                </span>
                                                <span
                                                  className={`border border-dashed flex-1 h-[1px] ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "border-white"
                                                      : ""
                                                    }`}
                                                ></span>
                                                <span
                                                  className={`font-medium ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-900"
                                                      : "text-gray-400"
                                                    }`}
                                                >
                                                  {item?.total_credits < 10
                                                    ? "0"
                                                    : ""}
                                                  {item.total_credits}
                                                </span>
                                              </li>
                                              <li className="flex space-x-4 items-center">
                                                <span
                                                  className={
                                                    item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-500"
                                                      : "text-gray-400"
                                                  }
                                                >
                                                  Credits Available
                                                </span>
                                                <span
                                                  className={`border border-dashed flex-1 h-[1px] ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "border-white"
                                                      : ""
                                                    }`}
                                                ></span>
                                                <span
                                                  className={`font-medium ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-900"
                                                      : "text-gray-400"
                                                    }`}
                                                >
                                                  {item?.credit_available < 10
                                                    ? "0"
                                                    : ""}
                                                  {item.credit_available}
                                                </span>
                                              </li>
                                              <li className="flex space-x-4 items-center">
                                                <span
                                                  className={
                                                    item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-500"
                                                      : "text-gray-400"
                                                  }
                                                >
                                                  Expiry
                                                </span>
                                                <span
                                                  className={`border border-dashed flex-1 h-[1px] ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "border-white"
                                                      : ""
                                                    }`}
                                                ></span>
                                                <span
                                                  className={`font-medium ${item?.package?.id ===
                                                      selectedPackage?.package.id
                                                      ? "text-gray-900"
                                                      : "text-gray-400"
                                                    }`}
                                                >
                                                  {selectedPackage?.expiring_soon
                                                    ? selectedPackage
                                                      .expiring_soon
                                                      .expiry_date
                                                    : "Expired"}
                                                </span>
                                              </li>
                                            </ul>
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li className="text-sm tex-gray-500 mt-10 text-center">
                                      No Expired/Paused Packages available
                                    </li>
                                  )}
                                </ul>
                              </TabPanel>
                            </Tabs>
                          </>
                        )}
                        {!targetTherapist && (
                          <>
                            {" "}
                            <Button
                              type="button"
                              className={`w-full mt-3 bg-teal-900 text-white !text-base shadow-none disabled:bg-gray-200 disabled:text-gray-400`}
                              onClick={() => openCreditsModal(2)}
                              disabled={
                                selectedTherapist?.is_active ? false : true
                              }
                            >
                              {packagesLoader[2] ? (
                                <div className="flex items-center justify-center">
                                  <div className="border-t-2 border-teal-100 border-solid rounded-full h-6 w-6 animate-spin"></div>
                                </div>
                              ) : (
                                "Buy more session credits"
                              )}
                            </Button>
                            <div
                              className="font-medium text-teal-500 mt-2 text-center text-sm cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                openTherapist(selectedTherapist);
                              }}
                            >
                              View Therapist Profile
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={isCreditsModalOpen}
                    onRequestClose={closeCreditsModal}
                    overlayClassName="fixed inset-0 bg-black/10 lg:backdrop-blur-sm z-20 transition-all"
                    style={creditsModalStyles}
                    className={`${isMobile && isCreditsModalOpen
                        ? "translate-y-0 top-[4.5rem]"
                        : "translate-y-full lg:translate-y-0"
                      } w-full transform transition-transform fixed bottom-0 outline-none p-4 lg:p-10 lg:relative scroll-track`}
                  >
                    <button
                      onClick={closeCreditsModal}
                      className="shadow-none absolute right-4 lg:right-10 top-4 lg:top-10"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18 6L6 18M6 6L18 18"
                          stroke="#667085"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <Heading variant="h3" className="text-2xl font-medium">
                      Buy More Credits
                    </Heading>
                    {/* <div className="font-normal mt-3 text-gray-500">
                      Take a [ Condition ] test to assess your mental
                      well-being, seek help, and empower yourself.{" "}
                    </div> */}
                    {/* <div className="p-2 bg-warning-100 text-xs font-medium mt-3 space-x-2 rounded-lg flex items-center">
                      <img src={priceTag} />
                      <span>Get 20% off: Use Code Ahmed1X</span>
                    </div> */}
                    <div className="font-medium mt-8">
                      Choose how many credits you want to buy
                    </div>
                    <div className="grid lg:grid-cols-3 gap-4 mt-4">
                      {packages?.flatMap((tier, tierIndex) =>
                        tier.products.flatMap((product, productIndex) =>
                          product.slots.map((slot, slotIndex) => (
                            <div
                              key={`${tierIndex}-${productIndex}-${slotIndex}`}
                              className="p-4 bg-white rounded-2xl relative shadow-therapistCard flex-1 pb-40"
                            >
                              <div className="block">
                                <div className="flex justify-between items-start">
                                  <div
                                    className="p-4 rounded-2xl font-semibold text-teal-900 text-xs"
                                    style={{
                                      backgroundImage: `linear-gradient(118deg, #D7FDF9 8.09%, #FFE1BC 130.78%)`,
                                    }}
                                  >
                                    <img
                                      src={VideRecorderLarge}
                                      className="mb-2"
                                    />
                                    {product?.product_count}{" "}
                                    <div className="leading-3 uppercase">
                                      {product?.product_count > 1
                                        ? `Credits`
                                        : `Credit`}
                                    </div>
                                  </div>

                                  {selectedPackage?.package.id ===
                                    slot.package_id ? (
                                    <div className="px-3 py-1.5 bg-gray-100 border border-gray-200 rounded-lg text-xs font-medium">
                                      Current
                                    </div>
                                  ) : null}
                                </div>
                                <div className="flex items-center space-x-1 mt-6">
                                  <h3 className="font-medium">
                                    {product?.product_count > 1
                                      ? `Bundle Package`
                                      : product?.product_name}
                                  </h3>
                                  <img
                                    src={
                                      selectedTherapist?.tier === "BLUE"
                                        ? BlueIco
                                        : selectedTherapist?.tier === "GOLD"
                                          ? GoldIco
                                          : SilverIco
                                    }
                                  />
                                </div>
                                <div className="text-sm text-gray-500 flex space-x-1.5 mt-1.5">
                                  <span className="font-semibold">
                                    {product?.product_count}x
                                  </span>{" "}
                                  <img src={VideRecorder} />{" "}
                                  <span className="font-normal">
                                    Session credit
                                  </span>
                                </div>
                                <div className="flex uppercase font-medium text-gray-500 text-[11px] space-x-2 mt-2">
                                  <p className="flex items-center space-x-1">
                                    <img src={clock} />{" "}
                                    <span>{slot?.slot_time} mins</span>
                                  </p>
                                  <span className="text-gray-400">|</span>
                                  <span>
                                    <span className="text-gray-400">
                                      Validity:
                                    </span>{" "}
                                    {slot?.validity_months}{" "}
                                    {slot?.validity_months > 1
                                      ? "Months"
                                      : "Month"}
                                  </span>
                                </div>
                              </div>
                              <div className="w-[calc(100%-2rem)] absolute bottom-4">
                                <div className="text-2xl font-medium text-gray-500 lg:mt-4">
                                  {slot.save_amount > 0 && (
                                    <div className="py-1.5 text-teal-500 flex items-center text-xs rounded-lg max-w-max">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.60022 1.4375L14.1996 2.38083L15.1422 8.98083L9.01422 15.1088C8.88921 15.2338 8.71967 15.304 8.54289 15.304C8.36612 15.304 8.19658 15.2338 8.07156 15.1088L1.47156 8.50883C1.34658 8.38382 1.27637 8.21428 1.27637 8.0375C1.27637 7.86072 1.34658 7.69119 1.47156 7.56617L7.60022 1.4375ZM9.48556 7.09483C9.60939 7.21863 9.7564 7.31681 9.91818 7.38379C10.08 7.45077 10.2534 7.48523 10.4285 7.4852C10.6036 7.48517 10.7769 7.45065 10.9387 7.38362C11.1005 7.31658 11.2474 7.21834 11.3712 7.0945C11.495 6.97066 11.5932 6.82366 11.6602 6.66187C11.7272 6.50009 11.7616 6.3267 11.7616 6.1516C11.7616 5.9765 11.727 5.80312 11.66 5.64136C11.593 5.4796 11.4947 5.33263 11.3709 5.20883C11.2471 5.08504 11.1 4.98685 10.9383 4.91987C10.7765 4.85289 10.6031 4.81843 10.428 4.81847C10.0744 4.81853 9.73524 4.95907 9.48522 5.20917C9.23521 5.45927 9.09479 5.79844 9.09486 6.15207C9.09492 6.5057 9.23546 6.84482 9.48556 7.09483Z"
                                          fill="#009688"
                                        />
                                      </svg>
                                      <span className="ml-1.5">
                                        Save {slot.currency === "USD" && "$"}
                                        {Math.floor(
                                          slot.save_amount /
                                          product?.product_count
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  {slot.crossed_price > 0 && (
                                    <span className="text-gray-400 line-through text-sm mr-2 mt-3 font-normal">
                                      {slot.currency === "USD" && "$"}
                                      {Math.floor(
                                        slot.crossed_price /
                                        product?.product_count
                                      )}
                                    </span>
                                  )}
                                  <span className="text-gray-900">
                                    {slot.currency === "USD" && "$"}
                                    {Math.floor(
                                      slot?.slot_price / product?.product_count
                                    )}
                                  </span>
                                  {product?.product_count > 1 && (
                                    <span className="ml-0 mb-[0.1rem] font-normal text-sm text-gray-500">
                                      {" "}
                                      Billed ${slot?.slot_price}
                                    </span>
                                  )}
                                </div>
                                <button
                                  className={`${selectedPackage?.id === slot.package_id
                                      ? "bg-teal-900 text-white"
                                      : "text-teal-900"
                                    } border-2 border-solid border-teal-900  hover:shadow-button transition-all px-2 py-1.5 rounded-3xl font-medium w-full mt-3`}
                                  onClick={() =>
                                    handleBuyCredits(slot.package_id)
                                  }
                                >
                                  Buy Now
                                </button>
                              </div>
                            </div>
                          ))
                        )
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="flex">
                <div
                  className={`lg:flex flex-1 bg-cover px-4 lg:px-6 py-8 -mt-20 lg:-mt-20 relative pt-24 lg:pt-28 mobile:bg-center`}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                >
                  <Tabs
                    className="w-full shadow-tabs"
                    selectedIndex={activeTabIndex}
                    onSelect={handleTabSelect}
                  >
                    <TabList className="flex absolute top-4 z-[2] p-1 bg-gray-100 rounded-xl space-x-2">
                      <Tab className="px-4 py-2 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-sm lg:text-base">
                        Upcoming
                      </Tab>

                      <Tab className="px-4 py-2 lg:py-2.5 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-sm lg:text-base">
                        Past
                      </Tab>
                    </TabList>

                    <TabPanel>
                      {loadingSkeleton ? (
                        <SkeletonComponent />
                      ) : (
                        <div className="flex gap-2 lg:gap-6">
                          <ul className="hidden lg:block">
                            {sessions?.upcoming?.length > 0 ? (
                              <li className="space-y-[4.44rem]">
                                {sessions?.upcoming.map((item, index) => {
                                  return (
                                    <div key={index} className="min-h-[100px]">
                                      <div className="rounded-lg border border-gray-200 text-center max-w-[3.25rem]">
                                        <div className="px-3 py-0.5 bg-teal-500 text-teal-50 text-xs font-medium rounded-t-lg">
                                          {item.session_month}
                                        </div>
                                        <div className="bg-white px-3 py-0.5 text-lg font-semibold rounded-b-lg">
                                          {item.session_day}
                                        </div>
                                      </div>
                                      {item?.status == "cancelled" ? (
                                        <div className="mt-1.5 text-sm font-medium">
                                          Cancelled
                                        </div>
                                      ) : (
                                        <>
                                          <div className="mt-1.5 text-sm font-medium">
                                            Session{" "}
                                            {item?.sr_num < 10 ? "0" : ""}
                                            {item?.sr_num}
                                          </div>
                                          <div className="text-gray-400 text-xs mt-1 font-medium">
                                            {item?.days}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </li>
                            ) : (
                              <li>
                                <div className="mt-1.5 text-sm font-medium">
                                  No Session
                                </div>
                              </li>
                            )}
                          </ul>
                          <div className="bg-gradient-to-b from-teal-500 to-[#f0fffe] w-5 rounded-t-full rounded-b-full time-line"></div>
                          <div className="space-y-4 lg:space-y-8 last-of-type:mb-40 flex-1">
                            {sessions?.upcoming.length > 0 ? (
                              <>
                                {sessions?.upcoming.map((item, index) => {
                                  return (
                                    <SessionCard
                                      key={index}
                                      data={item}
                                      selectedTherapist={selectedTherapist}
                                      handleCancel={handleCancel}
                                      handleRescheduleRequest={(data) =>
                                        handleRescheduleRequest(data, index)
                                      }
                                      handleReschedule={handleReschedule}
                                      loader={loader}
                                      reScheduleLoader={reScheduleLoader}
                                    />
                                  );
                                })}
                                <Modal
                                  isOpen={isRescheduleModalOpen}
                                  onRequestClose={handleRescheduleClose}
                                  overlayClassName="fixed inset-0 bg-black/10 backdrop-blur-sm z-20 transition-all"
                                  style={rescheduleModalStyles}
                                  className={`${isMobile && isRescheduleModalOpen
                                      ? "translate-y-0"
                                      : "translate-y-full lg:translate-y-0"
                                    } w-full transform transition-transform fixed bottom-0 outline-none p-4 lg:p-10 lg:relative`}
                                >
                                  <div className="flex justify-between">
                                    <div className="text-2xl font-medium">
                                      Review Reschedule Request
                                    </div>
                                    <img
                                      src={close}
                                      className="cursor-pointer"
                                      onClick={handleRescheduleClose}
                                    />
                                  </div>
                                  <div className="flex my-3 space-x-2">
                                    <img
                                      src={
                                        selectedTherapist?.profile_picture
                                          ?.full_url ?? User
                                      }
                                      className="w-8 h-8 rounded-full"
                                    />
                                    <div>
                                      <div className="text-sm text-gray-500 font-medium mt-[-2px]">
                                        {(sessionData?.reschedule_req_data
                                          ?.therapist?.first_name || "") +
                                          " " +
                                          (sessionData?.reschedule_req_data
                                            ?.therapist?.last_name || "")}
                                      </div>

                                      <div className="text-sm ">
                                        {sessionData?.reschedule_req_data
                                          ?.therapist?.message || ""}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex rounded-lg p-2 items-center bg-teal-200 space-x-2">
                                    <img src={AnnaouncementIcon} />
                                    <div className="text-xs font-medium">
                                      You can chat with your therapist if the
                                      suggested time doesn&apos;t work for you
                                    </div>
                                  </div>
                                  <div className="flex items-center my-10 space-x-2">
                                    <img
                                      src={calendarGray}
                                      className="w-5 h-5"
                                    />
                                    <div>
                                      <div className="font-medium text-base">{`Reschedule Session ${sessionData?.sessionIndex || 1
                                        }`}</div>
                                      <div className="flex items-center space-x-1">
                                        <div className="text-sm font-medium">
                                          Original time:
                                        </div>
                                        <div className="text-sm text-gray-600">
                                          {sessionData?.session_date +
                                            ", " +
                                            sessionData?.start_time +
                                            " - " +
                                            sessionData?.end_time}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="px-7">
                                    <div className="text-base font-medium mb-1">
                                      Suggested date by&nbsp;
                                      {sessionData?.reschedule_req_data
                                        ?.therapist?.first_name || ""}
                                    </div>
                                    <div className="flex items-center space-x-2 overflow-auto w-max">
                                      {sessionData?.reschedule_req_data?.proposed_slots?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              onClick={() => {
                                                setSelectedProposedSlot(item);
                                                setSelectedProposedTime(
                                                  item?.slots?.[0] || {}
                                                );
                                              }}
                                              key={index}
                                              className={`border-solid border border-gray-200 rounded-lg w-12 cursor-pointer ${selectedProposedSlot == item &&
                                                "shadow"
                                                }`}
                                            >
                                              <div
                                                className={`rounded-tl-lg rounded-tr-lg py-0.5 text-center ${selectedProposedSlot == item
                                                    ? "bg-teal-500"
                                                    : "bg-gray-200"
                                                  }`}
                                              >
                                                <div
                                                  className={`text-xs font-medium ${selectedProposedSlot == item
                                                      ? "text-white"
                                                      : "text-gray-400"
                                                    }`}
                                                >
                                                  {moment(item?.date).format(
                                                    "MMM"
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className={`text-lg font-medium text-center my-0.5 ${selectedProposedSlot == item
                                                    ? "text-gray-900"
                                                    : "text-gray-500"
                                                  }`}
                                              >
                                                {moment(item?.date).format(
                                                  "DD"
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="px-7 my-10">
                                    <div className="text-base font-medium">
                                      Suggested time by&nbsp;
                                      {sessionData?.reschedule_req_data
                                        ?.therapist?.first_name || ""}
                                    </div>
                                    <div className="flex items-center flex-wrap">
                                      {selectedProposedSlot?.slots?.sort((a, b) => new Date(a.start_time) - new Date(b.start_time))?.map(
                                        (item, index) => (
                                          <div
                                            onClick={() =>
                                              setSelectedProposedTime(item)
                                            }
                                            key={index}
                                            className={`cursor-pointer text-xs font-medium border border-solid rounded-lg border-teal-300 mr-2 py-2 px-3 mt-2 ${selectedProposedTime?.start_time ==
                                                item?.start_time
                                                ? "bg-teal-500 text-white"
                                                : "bg-teal-50 text-teal-500"
                                              }`}
                                          >
                                            {`${moment(item.start_time).format(
                                              "h:mm"
                                            )}
                                            -
                                            ${moment(item.end_time).format(
                                              "h:mm a"
                                            )}`}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex items-center justify-end space-x-1">
                                    <div
                                      onClick={() =>
                                        window.open(
                                          `https://wa.me/${whatsappClientNumber}`
                                        )
                                      }
                                      className="cursor-pointer text-base font-medium px-2 py-1"
                                    >
                                      Contact Support
                                    </div>
                                    <div
                                      className="text-base font-medium text-white bg-teal-900 px-2 py-1 rounded-full cursor-pointer"
                                      onClick={onRescheduleSession}
                                    >
                                      Confirm Reschedule
                                    </div>
                                  </div>
                                </Modal>
                              </>
                            ) : null}
                            {sessions?.upcoming.length === 0 &&
                              sessions?.past.length === 0 ? (
                              <div className="before:w-3 before:h-3 before:rounded-full before:bg-white before:absolute before:-left-[41px] before:top-1 relative bg-white rounded-2xl border border-gray-200 p-6 flex gap-6">
                                <div
                                  className="cursor-pointer w-[132px] h-22 lg:w-50 lg:h-[133px] relative"
                                  onClick={openModal}
                                >
                                  <img
                                    src="https://img.youtube.com/vi/kGPe1oy6jLU/maxresdefault.jpg"
                                    alt="Custom Thumbnail"
                                    className="h-full object-cover rounded-2xl w-full"
                                  />
                                  <div className="absolute top-0 bottom-0 right-0 left-0 m-auto w-14 h-14">
                                    <img src={PlayButton} />
                                  </div>
                                </div>
                                <Modal
                                  isOpen={isModalOpen}
                                  onRequestClose={closeModal}
                                  className="flex items-center justify-center h-full"
                                  overlayClassName="fixed inset-0 bg-black/10 backdrop-blur-sm z-20 transition-all"
                                >
                                  <div className="relative">
                                    <div className="rounded-2xl overflow-hidden">
                                      <iframe
                                        className="max-w-full"
                                        title="Embedded YouTube Video"
                                        width="872"
                                        height="490"
                                        src="https://www.youtube.com/embed/kGPe1oy6jLU"
                                        frameBorder="0"
                                        allowFullScreen
                                      ></iframe>
                                    </div>

                                    <button
                                      onClick={closeModal}
                                      className="w-9 h-9 bg-white border border-gray-200 rounded-full flex justify-center items-center absolute -top-12 right-0"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.6665 4.66699L4.6665 12.667M4.6665 4.66699L12.6665 12.667"
                                          stroke="#667085"
                                          strokeWidth="1.33333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </Modal>
                                <div>
                                  <Heading variant="h3" className="font-medium">
                                    {`You're about to have a great start with ${selectedTherapist.first_name}. Here's what to expect:`}
                                  </Heading>
                                  <ul className="mt-6 space-y-2">
                                    <li className="space-x-1.5 text-sm font-medium flex">
                                      <img src={Check} />
                                      <span>Easy to start</span>
                                    </li>
                                    <li className="space-x-1.5 text-sm font-medium flex">
                                      <img src={Check} />
                                      <span>
                                        Therapist will meet you on time
                                      </span>
                                    </li>
                                    <li className="space-x-1.5 text-sm font-medium flex">
                                      <img src={Check} />
                                      <span>
                                        Message your Therapist anytime
                                      </span>
                                    </li>
                                  </ul>

                                  <Button
                                    className="bg-teal-900 hover:shadow-button hover:bg-teal-500 text-white transition-all flex justify-between space-x-2 items-center rounded-xl w-full lg:w-auto !px-3 !text-base mt-6"
                                    onClick={() =>
                                      handleScheduleSession(
                                        selectedPackage?.package.id
                                      )
                                    }
                                  >
                                    Schedule your first session
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="29"
                                      height="28"
                                      viewBox="0 0 29 28"
                                      fill="none"
                                    >
                                      <path
                                        d="M15.3253 5.00772C14.8697 4.55211 14.131 4.55211 13.6754 5.00772C13.2198 5.46334 13.2198 6.20203 13.6754 6.65764L19.8504 12.8327H6.33366C5.68933 12.8327 5.16699 13.355 5.16699 13.9993C5.16699 14.6437 5.68933 15.166 6.33366 15.166H19.8504L13.6754 21.3411C13.2198 21.7967 13.2198 22.5354 13.6754 22.991C14.131 23.4466 14.8697 23.4466 15.3253 22.991L23.492 14.8243C23.9476 14.3687 23.9476 13.63 23.492 13.1744L15.3253 5.00772Z"
                                        fill="#D8EEEC"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            ) : selectedPackage?.credit_available > 0 ? (
                              <div
                                className="px-4 py-3 bg-white border border-gray-100 rounded-xl relative flex flex-wrap lg:flex-wrap-reverse gap-x-3 items-center"
                                ref={nextSessionRef}
                              >
                                <img src={calendarGreen} />
                                <div>
                                  <div className="font-medium">
                                    Schedule next session
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    Available session credits:{" "}
                                    {selectedPackage?.credit_available < 10
                                      ? "0"
                                      : ""}
                                    {selectedPackage?.credit_available}
                                  </div>
                                </div>
                                <Button
                                  className={`text-teal-900 bg-teal-100 flex items-center space-x-2 shadow-none lg:ml-auto mt-4 lg:mt-0 justify-center ${isSticky
                                      ? "fixed !py-3 text-md bottom-20 lg:bottom-6 shadow-xl right-4 lg:right-[21.5rem] w-auto"
                                      : "w-full lg:w-40 text-sm"
                                    }`}
                                  onClick={() =>
                                    handleScheduleSession(
                                      selectedPackage?.package.id
                                    )
                                  }
                                >
                                  {scheduleLoader ? (
                                    <div className="flex items-center justify-center">
                                      <div className="border-t-2 border-teal-900 border-solid rounded-full h-6 w-6 animate-spin"></div>
                                    </div>
                                  ) : (
                                    <>
                                      {isSticky
                                        ? "Schedule Next Session"
                                        : "Schedule Now"}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className="ml-1"
                                      >
                                        <path
                                          d="M4.16699 10.0013H15.8337M15.8337 10.0013L10.0003 4.16797M15.8337 10.0013L10.0003 15.8346"
                                          stroke="#01554D"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </Button>
                              </div>
                            ) : (
                              <div
                                className="px-4 py-3 bg-white border border-gray-100 rounded-xl relative flex flex-wrap gap-x-3 items-center"
                                ref={buttonRef}
                              >
                                <img src={calendarGray} />
                                <div>
                                  {/* <div className="text-sm text-gray-500">
                                    Buy more session credits
                                  </div> */}
                                  <div>
                                    Available session credits:{" "}
                                    {selectedPackage?.credit_available}
                                  </div>
                                </div>
                                <Button
                                  className={`text-teal-900 bg-teal-100 flex items-center justify-center space-x-2 shadow-none w-full lg:w-44 lg:ml-auto text-sm mt-4 lg:mt-0 disabled:bg-gray-200 disabled:text-gray-400 ${isSticky
                                      ? "fixed bottom-6 shadow-xl right-[21.5rem]"
                                      : ""
                                    }`}
                                  onClick={() => openCreditsModal(1)}
                                  disabled={
                                    selectedTherapist?.is_active ? false : true
                                  }
                                >
                                  {packagesLoader[1] ? (
                                    <div className="flex items-center justify-center">
                                      <div className="border-t-2 border-teal-900 border-solid rounded-full h-5 w-5 animate-spin"></div>
                                    </div>
                                  ) : (
                                    "Buy session credits"
                                  )}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {loadingSkeleton ? (
                        <SkeletonComponent />
                      ) : (
                        <div>
                          {sessions?.past.length > 0 ? (
                            <div className="flex gap-2 lg:gap-6">
                              <ul className="space-y-[4.44rem] hidden lg:block">
                                {sessions?.past.map((item, index) => {
                                  return (
                                    <li key={index} className="min-h-[100px]">
                                      <div className="rounded-lg border border-gray-200 text-center max-w-[3.25rem]">
                                        <div className="px-3 py-0.5 bg-gray-200 text-gray-400 text-xs font-medium rounded-t-lg">
                                          {item.session_month}
                                        </div>
                                        <div className="bg-white px-3 py-0.5 text-lg font-semibold rounded-b-lg">
                                          {item.session_day}
                                        </div>
                                      </div>
                                      <div className="mt-1.5 text-sm font-medium">
                                        Session {item?.sr_num < 10 ? "0" : ""}
                                        {item.sr_num}
                                      </div>
                                      {/* <div className="text-gray-400 text-xs mt-1 font-medium">
                                  {item.days}
                                </div> */}
                                    </li>
                                  );
                                })}
                              </ul>
                              <div className="bg-gradient-to-b from-[#E7E6E6] to-[#F2F2F2] w-5 rounded-t-full rounded-b-full"></div>
                              <div className="space-y-4 lg:space-y-8 last-of-type:mb-40 flex-1">
                                {sessions?.past.length > 0 ? (
                                  <>
                                    {sessions?.past.map((item, index) => {
                                      return (
                                        <SessionCard
                                          key={index}
                                          data={item}
                                          selectedTherapist={selectedTherapist}
                                        />
                                      );
                                    })}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="font-medium  grid items-center justify-center h-[500px]">
                              <div className="text-center">
                                <img src={NoSessions} className="mx-auto" />
                                <h3 className="text-[16px] font-medium mt-[1rem]">
                                  No past session available
                                </h3>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </TabPanel>
                  </Tabs>
                </div>
                <div className="w-80 border-l border-l-gray-100 hidden lg:block">
                  <Tabs className="simple-tabs">
                    <TabList className="flex mb-12 text-center">
                      <Tab className="px-2 lg:px-4 py-1.5 lg:py-3 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs shadow-none flex-1 font-medium">
                        Notes
                      </Tab>
                      <Tab className="px-2 lg:px-4 py-1.5 lg:py-3 whitespace-nowrap text-gray-500 cursor-pointer outline-none text-xs shadow-none flex-1 font-medium">
                        Journal
                      </Tab>
                    </TabList>

                    <TabPanel className="px-10 text-center flex flex-col justify-center items-center">
                      <img src={Notes} />
                      <Heading
                        variant="h3"
                        className="text-sm font-medium mt-6"
                      >
                        Coming Soon!
                      </Heading>
                      <p className="text-xs text0-gray-400 mt-2">
                        You will be able to add notes against sessions, reflect
                        your thoughts and get input by your therapist.
                      </p>
                    </TabPanel>
                    <TabPanel className="px-10 text-center flex flex-col justify-center items-center">
                      <img src={Notes} />
                      <Heading
                        variant="h3"
                        className="text-sm font-medium mt-6"
                      >
                        Coming Soon!
                      </Heading>
                      <p className="text-xs text0-gray-400 mt-2">
                        You will be able to have a personalized space for
                        journaling; capture thoughts, reflections, and action
                        plans.
                      </p>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              {!user.onboarding && (
                <div className="flex justify-center items-center absolute inset-0 bg-black/10 backdrop-blur-sm z-10 px-4 lg:px-0">
                  <div className="p-4 pt-8 lg:p-5 lg:pt-10 rounded-2xl max-w-sm w-full bg-white lg:-mt-20 lg:-ml-24 relative">
                    <div
                      className="cursor-pointer absolute right-4 top-4"
                      onClick={handlePurchaseNow}
                    >
                      <img src={close} />
                    </div>
                    <Heading variant="h3" className="font-medium text-lg">
                      Your upcoming sessions will appear here
                    </Heading>
                    <p className="mt-2 text-sm text-gray-500">
                      {`You'll be able to access this section as soon as you buy a session`}
                    </p>
                    <ul className="mt-6 space-y-2">
                      <li className="space-x-1.5 text-sm font-medium flex">
                        <img src={Check} />
                        <span>No cancellation fee</span>
                      </li>
                      <li className="space-x-1.5 text-sm font-medium flex">
                        <img src={Check} />
                        <span>Free to start</span>
                      </li>
                      <li className="space-x-1.5 text-sm font-medium flex">
                        <img src={Check} />
                        <span>No pesky contracts</span>
                      </li>
                      <li className="space-x-1.5 text-sm font-medium flex">
                        <img src={Check} />
                        <span>Peace of mind with fitcy health</span>
                      </li>
                    </ul>
                    <div className="mt-10 lg:mt-28 flex lg:flex-row items-center gap-4 lg:gap-6 flex-col-reverse">
                      <a
                        className="text-sm text-gray-500 font-medium cursor-pointer"
                        onClick={() =>
                          window.open(`https://wa.me/${whatsappClientNumber}`)
                        }
                      >
                        Talk to our support
                      </a>
                      <Button
                        className="bg-teal-900 hover:shadow-button hover:bg-teal-500 text-white transition-all flex justify-between space-x-2 items-center rounded-xl w-full lg:w-auto !px-3 !text-base"
                        onClick={handlePurchaseNow}
                      >
                        Find Therapists
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="28"
                          viewBox="0 0 29 28"
                          fill="none"
                        >
                          <path
                            d="M15.3253 5.00772C14.8697 4.55211 14.131 4.55211 13.6754 5.00772C13.2198 5.46334 13.2198 6.20203 13.6754 6.65764L19.8504 12.8327H6.33366C5.68933 12.8327 5.16699 13.355 5.16699 13.9993C5.16699 14.6437 5.68933 15.166 6.33366 15.166H19.8504L13.6754 21.3411C13.2198 21.7967 13.2198 22.5354 13.6754 22.991C14.131 23.4466 14.8697 23.4466 15.3253 22.991L23.492 14.8243C23.9476 14.3687 23.9476 13.63 23.492 13.1744L15.3253 5.00772Z"
                            fill="#D8EEEC"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {user.onboarding && user.tour_guide && !loadingSkeleton && (
                <ProductTour
                  sessionData={
                    selectedTherapist?.ongoing?.[0]?.upcoming.length > 0 &&
                    selectedTherapist?.ongoing?.[0]?.upcoming[0]
                  }
                  setTourGuide={(value) => setTourGuide(value)}
                />
              )}
            </div>
          </>
        ) : step === 4 ? (
          <div
            className="flex-1 bg-cover mobile:bg-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <ScheduleSession
              setStep={(value) => setStep(value)}
              reschedule={reschedule}
              schedule={schedule}
              selectedTherapist={selectedPackage}
              setSuccessData={(value) => setSuccessData(value)}
              expiryLimit={true}
            />
            {scheduledRecords.length > 0 ? (
              <div className="max-w-5xl w-full mx-auto pt-12 px-4">
                <Heading variant="h3" className="text-lg font-medium">
                  Upcoming Session
                </Heading>

                <ul>
                  {scheduledRecords.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="flex py-6 border-t border-t-gray-200 first-of-type:border-t-0"
                      >
                        <div className="text-xs uppercase font-semibold text-gray-500 w-40">
                          {item.session_date}
                        </div>
                        <div>
                          <div className="text-sm font-medium">
                            Session {index + 1}
                          </div>
                          <div className="font-medium mt-1 text-gray-500">
                            <span>{item.start_time}</span> <span>to</span>{" "}
                            <span>{item.end_time}</span>
                          </div>
                        </div>
                        {item.therapist_verification && (
                          <div className="px-3 py-3.5 flex items-center space-x-1 text-sm text-gray-500 ml-auto">
                            <img src={Check} />
                            <span>Verified by therapist</span>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        ) : step === 5 ? (
          <div
            className="flex-1 bg-cover mobile:bg-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <Checkout setStep={(value) => setStep(value)} />
          </div>
        ) : step === 6 ? (
          <div
            className="flex-1 bg-cover mobile:bg-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <PaymentStatus />
          </div>
        ) : step === 7 ? (
          <div
            className="w-full bg-cover px-4 mobile:bg-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <SuccessScreen
              data={successData?.data}
              message={successData?.message}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default PatientSessions;
