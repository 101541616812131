import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SemiCircleProgressBar from "../molecules/SemiCircleProgressBar";
import {
  getQuestionnaire,
  submitAnswer,
} from "../../redux/onboarding/onboardingThunks";
import Loader from "../atoms/Loader";
import AnswersList from "../molecules/AnswersList";
import Heading from "../atoms/Heading";
import StarRating from "../atoms/StarRating";

const Questionnaire = ({ setStep, data }) => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.onboarding.questionnaire_dict);
  const questionnaireToUse = questions?.filter(
    (record) => record?.type === data?.type && record?.id === data?.id
  );
  const [questionNo, setQuestionNo] = useState(1);
  const [loading, setLoading] = useState(true);
  const [trigger, setTrigger] = useState(0);

  const user = useSelector((state) => state.app.user);
  useEffect(() => {
    if (user && questionnaireToUse) {
      setLoading(false);
    } else {
      setTrigger(trigger + 1);
    }
  }, [questionNo, trigger]);

  useEffect(() => {
    if (!questionnaireToUse?.[0]?.questions) dispatch(getQuestionnaire());
  }, []);

  const handleSubmitAnswer = (e) => {
    if (e.preventDefault) e.preventDefault();
    let answerToSubmit = [];

    if (
      questionnaireToUse?.[0]?.questions[questionNo - 1].type !== "MULTISELECT"
    ) {
      answerToSubmit.push(e.target.answer.value);
    }

    if (
      questionnaireToUse?.[0]?.questions[questionNo - 1].type === "MULTISELECT"
    ) {
      for (let i = 0; i < e.target.length; i++) {
        if (e.target[i].checked) {
          answerToSubmit.push(JSON.parse(e.target[i].value));
        }
      }
    }
    dispatch(
      submitAnswer({
        question: questionnaireToUse?.[0]?.questions[questionNo - 1].id,
        answers: answerToSubmit,
      })
    );

    if (questionNo < questionnaireToUse?.[0]?.questions.length)
      setQuestionNo(questionNo + 1);
    else {
      setStep(3);
    }
  };

  const renderQuestionChoices = (question) => {
    if (question?.type === "SINGLESELECT")
      return (
        <div className="mt-10">
          <p className="text-2xl text-gray-500 font-medium text-center">
            {questionnaireToUse?.[0]?.questions[questionNo - 1].title}
          </p>
          <AnswersList
            question={question}
            handleSubmitAnswer={handleSubmitAnswer}
          />
        </div>
      );

    if (question?.type === "MULTISELECT")
      return (
        <div className="mt-10">
          <p className="text-2xl text-gray-500 font-medium text-center">
            {questionnaireToUse?.[0]?.questions[questionNo - 1].title}
          </p>
          <AnswersList
            type={question.type}
            question={question}
            handleSubmitAnswer={handleSubmitAnswer}
            buttonState={
              questionNo < questionnaireToUse?.[0]?.questions.length
                ? "Continue"
                : "Complete"
            }
          />
        </div>
      );
  };

  if (!questionnaireToUse || !user || loading) return <Loader />;

  return (
    <div className="pt-10 px-4 lg:px-0">
      <div className="text-center">
        <Heading variant="h1" className="text-2.5xl leading-tight font-medium">
          {questionnaireToUse?.[0]?.name}
        </Heading>
        <p className="text-gray-500 text-lg font-normal mt-3">
          {questionnaireToUse?.[0]?.description}
        </p>
      </div>
      <div className="max-w-md w-full mx-auto mt-10">
        <div className="bg-white shadow-questionnaireBox px-4 py-9 lg:px-9 rounded-3xl relative">
          {questionNo !== 1 ? (
            <div
              className="absolute top-11 cursor-pointer"
              onClick={() => {
                // setFromBack(true);
                setQuestionNo(questionNo - 1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 12L5.5 12M5.5 12L12.5 19M5.5 12L12.5 5"
                  stroke="#667085"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : null}
          <div className="w-[76px] mx-auto relative">
            <SemiCircleProgressBar
              percentage={
                (questionNo / questionnaireToUse?.[0]?.questions.length) * 100
              }
              width={64}
              height={36}
              className="w-[64px] h-[36px]"
              strokeWidth={6}
              graphRadius={28}
              gradient={true}
            />
            <span className="text-sm text-gray-500 font-medium absolute left-0 right-0 text-center top-2/4">
              {questionNo}/{questionnaireToUse?.[0]?.questions.length}
            </span>
          </div>
          {renderQuestionChoices(
            questionnaireToUse?.[0]?.questions[questionNo - 1]
          )}
        </div>
        <div className="mt-12 flex items-center space-x-3">
          <StarRating rating={4.5} maxRating={5} />
          <p className="text-xs text-gray-500">
            Fitcy has helped <span className="text-teal-500">1000+</span>
            customers dealing with depression
          </p>
        </div>
      </div>
    </div>
  );
};
export default Questionnaire;
