import React, { useState, useEffect, useRef } from "react";
import chevronSelectorVertical from "../../assets/chevron-selector-vertical.svg";
import googleCalendarIcon from "../../assets/google-calendar-icon.png";

const CustomDropdown = ({
  views,
  onChange,
  view,
  dropdown,
  className,
  selectedEmails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedView, setSelectedView] = useState(view);
  const dropdownRef = useRef(null);

  const handleViewChange = (view) => {
    setSelectedView(view);
    onChange(view);
    closeDropdown();
  };
  const handleCheckboxChange = (email) => {
    if (selectedEmails.includes(email)) {
      onChange(selectedEmails.filter((e) => e !== email));
    } else {
      onChange([...selectedEmails, email]);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={className} ref={dropdownRef}>
      <div
        className="px-3 py-1.5 bg-white text-sm text-gray-500 font-medium border border-gray-200 rounded-lg shadow-featureCard cursor-pointer  flex items-center capitalize"
        onClick={toggleDropdown}
      >
        {dropdown === "email" && (
          <img src={googleCalendarIcon} className="w-4 h-4 mr-2" />
        )}

        {selectedView}
        <img src={chevronSelectorVertical} className="ml-2" />
      </div>
      {isOpen && (
        <div
          className={`bg-white rounded-2xl absolute shadow-therapistCard z-10 ${
            dropdown === "email" ? "" : "right-6 capitalize"
          } w-full max-w-[278px] mt-2`}
        >
          {dropdown === "status"
            ? views.map((view) => (
                <div
                  key={view}
                  className="p-4 cursor-pointer hover:bg-blue-50 text-sm text-gray-500"
                  onClick={() => handleViewChange(view)}
                >
                  {view}
                </div>
              ))
            : dropdown === "email"
            ? views.map((view) => (
                <div
                  key={view}
                  className="p-4 cursor-pointer hover:bg-blue-50 text-sm text-gray-500 flex items-center"
                >
                  <input
                    type="checkbox"
                    checked={selectedEmails.includes(view)}
                    onChange={() => handleCheckboxChange(view)}
                    className="appearance-none checked:bg-blue-500 border-gray-200 w-4 h-4 flex items-center justify-center checked:before:w-[.25rem] checked:before:h-[.60rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 cursor-pointer mr-1"
                  />
                  {view}
                </div>
              ))
            : Object.keys(views).map((view) => (
                <div
                  key={view}
                  className="p-4 cursor-pointer hover:bg-blue-50 text-sm text-gray-500"
                  onClick={() => handleViewChange(view)}
                >
                  {view}
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
