import React, { useState, useEffect } from "react";
import {
  ACTION_POPUP,
  CONDITIONS_FILTER,
  COUNTRY_FILTER,
  SESSION_FILTER,
  STATUS_FILTER,
  SORTING_FILTER,
  conditionOptions,
  sessionCountOptions,
  statusOptions,
  sortingOptions,
} from "../../services/utils/constants";
import MessageIcon from "../../assets/message-chat-square.svg";
import ProfileIcon from "../../assets/profile-icon.svg";
import Heading from "../atoms/Heading";

const sortingValues = {
  "Most Recent": "latest",
  "Least Recent": "oldest",
  "Alphabetically Ascending": "ascending",
  "Alphabetically Descending": "descending",
};

const TherapistClientsFilter = ({
  isModalType,
  setFilters,
  appliedFilters,
  setIsCommonModal,
  setSelectedSorting,
  selectedSorting,
  countryOptions,
  selectedClient,
  handleOpenPanel,
}) => {
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSessionCounts, setSelectedSessionCounts] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const getData = () => {
    if (isModalType == SESSION_FILTER) return sessionCountOptions;
    else if (isModalType == STATUS_FILTER) return statusOptions;
    else if (isModalType == CONDITIONS_FILTER) return conditionOptions;
    else if (isModalType == COUNTRY_FILTER) return countryOptions;
  };

  const filterOptions = getData()?.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );

  const getStatusBgColor = (type) => {
    if (type == "Ongoing") return "bg-blue-100";
    else if (type == "Abandoned") return "bg-orange-100";
    else if (type == "Completed") return "bg-teal-100";
    else if (type == "Paused") return "bg-gray-200";
    else return "bg-white";
  };

  const getStatusTextColor = (type) => {
    if (type == "Ongoing") return "text-blue-500";
    else if (type == "Abandoned") return "text-orange-500";
    else if (type == "Completed") return "text-teal-500";
    else if (type == "Paused") return "text-gray-400";
    else return "text-gray-500";
  };

  useEffect(() => {
    // Update state based on appliedFilters when modal is opened
    if (isModalType === CONDITIONS_FILTER) {
      setSelectedConditions(appliedFilters.medical_conditions || []);
    } else if (isModalType === STATUS_FILTER) {
      setSelectedStatuses(appliedFilters.patient_status || []);
    } else if (isModalType === SESSION_FILTER) {
      setSelectedSessionCounts(appliedFilters.session_count || []);
    } else if (isModalType === COUNTRY_FILTER) {
      setSelectedCountries(appliedFilters.country || []);
    }
  }, [isModalType, appliedFilters]);

  // Add similar state variables for other filter types if needed

  const handleConditionChange = (condition) => {
    if (condition === "All") {
      setSelectedConditions(
        selectedConditions.length === conditionOptions.length
          ? []
          : conditionOptions
      );
    } else {
      // Toggle the condition in the selectedConditions array
      const updatedConditions = selectedConditions.includes(condition)
        ? selectedConditions.filter((c) => c !== condition)
        : [...selectedConditions, condition];

      // Update the state
      setSelectedConditions(updatedConditions);
    }
  };

  const handleStatusChange = (status) => {
    if (status === "All") {
      setSelectedStatuses(
        selectedStatuses.length === statusOptions.length ? [] : statusOptions
      );
    } else {
      // Toggle the status in the selectedStatuses array
      const updatedStatuses = selectedStatuses.includes(status)
        ? selectedStatuses.filter((s) => s !== status)
        : [...selectedStatuses, status];

      // Update the state
      setSelectedStatuses(updatedStatuses);
    }
  };

  const handleSessionCountChange = (sessionCount) => {
    // Toggle the sessionCount in the selectedSessionCounts array
    const updatedSessionCounts = selectedSessionCounts.includes(sessionCount)
      ? selectedSessionCounts.filter((s) => s !== sessionCount)
      : [...selectedSessionCounts, sessionCount];

    // Update the state
    setSelectedSessionCounts(updatedSessionCounts);
  };

  const handleCountryChange = (country) => {
    // Toggle the country in the selectedCountries array
    const updatedCountries = selectedCountries.includes(country)
      ? selectedCountries.filter((c) => c !== country)
      : [...selectedCountries, country];

    // Update the state
    setSelectedCountries(updatedCountries);
  };

  const handleApplyFilters = (item) => {
    // Merge the previously selected filters with the newly selected ones
    const prevMedicalConditions = Array.isArray(
      appliedFilters.medical_conditions
    )
      ? appliedFilters.medical_conditions
      : [];

    // Ensure appliedFilters.patient_status is an array
    const prevPatientStatuses = Array.isArray(appliedFilters.patient_status)
      ? appliedFilters.patient_status
      : [];

    const prevSessionCounts = Array.isArray(appliedFilters.session_count)
      ? appliedFilters.session_count
      : [];

    const prevCountries = Array.isArray(appliedFilters.country)
      ? appliedFilters.country
      : [];

    const updatedFilters = {
      medical_conditions:
        selectedConditions.length > 0 || isModalType === CONDITIONS_FILTER
          ? selectedConditions
          : prevMedicalConditions,
      patient_status:
        selectedStatuses.length > 0 || isModalType === STATUS_FILTER
          ? selectedStatuses
          : prevPatientStatuses,
      session_count:
        selectedSessionCounts.length > 0 || isModalType === SESSION_FILTER
          ? selectedSessionCounts
          : prevSessionCounts,
      country:
        selectedCountries.length > 0 || isModalType === COUNTRY_FILTER
          ? selectedCountries
          : prevCountries,
      sorting: item.value ?? sortingValues[selectedSorting],
      // Add other filters if needed
    };
    setSelectedSorting(item.label ?? selectedSorting);
    setFilters(updatedFilters);
    setIsCommonModal(false);
  };
  return (
    <div className="px-5 py-3">
      {isModalType == ACTION_POPUP ? (
        <>
          <a
            className="flex items-center space-x-4 cursor-pointer"
            href={`/therapist/messages/?id=${selectedClient.id}`}
          >
            <img src={MessageIcon} />
            <div className="text-base font-medium text-gray-500 w-full">
              Message
            </div>
          </a>
          <div className="border-b border-b-solid ml-7 my-4"></div>
          <div
            className="flex items-center space-x-4 cursor-pointer"
            onClick={() => handleOpenPanel(selectedClient)}
          >
            <img src={ProfileIcon} />
            <div className="text-base font-medium text-gray-500 w-full">
              View Profile
            </div>
          </div>
        </>
      ) : isModalType === SORTING_FILTER ? (
        <>
          <Heading variant="h2" className="text-xl font-medium mb-5">
            {isModalType}
          </Heading>
          <ul className="divide-y">
            {sortingOptions?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="text-base font-medium text-gray-500 w-full py-4 cursor-pointer"
                  onClick={() => handleApplyFilters(item)}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <>
          <Heading variant="h2" className="text-xl font-medium mb-5">
            {isModalType}
          </Heading>
          {(isModalType === CONDITIONS_FILTER ||
            isModalType === COUNTRY_FILTER) && (
            <div className="border border-solid border-gray-200 rounded-lg py-2.5 px-3.5 mb-5">
              <input
                placeholder={`Type ${
                  isModalType === CONDITIONS_FILTER ? "condition" : "country"
                } here`}
                className="outline-none border-none w-full"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          )}
          {filterOptions?.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex items-center space-x-4">
                  <input
                    type="checkbox"
                    checked={
                      isModalType === CONDITIONS_FILTER
                        ? selectedConditions.includes(item)
                        : isModalType === STATUS_FILTER
                        ? selectedStatuses.includes(item)
                        : isModalType === SESSION_FILTER
                        ? selectedSessionCounts.includes(item)
                        : isModalType === COUNTRY_FILTER
                        ? selectedCountries.includes(item)
                        : false
                    }
                    onChange={() => {
                      if (isModalType === CONDITIONS_FILTER) {
                        handleConditionChange(item);
                      } else if (isModalType === STATUS_FILTER) {
                        handleStatusChange(item);
                      } else if (isModalType === SESSION_FILTER) {
                        handleSessionCountChange(item);
                      } else if (isModalType === COUNTRY_FILTER) {
                        handleCountryChange(item);
                      }
                    }}
                    className="rounded appearance-none checked:bg-blue-900 border-gray-200 w-5 h-5 flex items-center justify-center checked:before:-mt-0.5 checked:before:w-[.30rem] checked:before:h-[.70rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 cursor-pointer"
                  />
                  {isModalType == STATUS_FILTER ? (
                    <div
                      className={`text-xs font-medium ${getStatusBgColor(
                        item
                      )} ${getStatusTextColor(item)} rounded-full py-1.5 px-3`}
                    >
                      {item}
                    </div>
                  ) : (
                    <div className="text-base font-medium text-gray-500 flex-1">
                      {item}
                    </div>
                  )}
                </div>
                <div className="border-b border-b-solid ml-7 my-4"></div>
              </div>
            );
          })}
          <div className="text-end">
            <button
              className="cursor-pointer text-white bg-blue-900 rounded-lg py-2 px-4"
              onClick={handleApplyFilters}
            >
              Apply Filters
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TherapistClientsFilter;
