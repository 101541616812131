import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
// import { getErrorDetailsforNotif } from "../../services/globalFunctions";
import { patchOnboardingStatus } from "../onboarding/onboardingThunks";
import { updatePatientPicture } from "../patient/patientThunks";
import { updateTherapistPicture } from "../therapist/therapistThunks";
import {
  addTransactionHistory,
  forgotPassword,
  getLatestMessagesTime,
  getMe,
  getMeEasy,
  getPackagesList,
  getPatientList,
  getSinglePatient,
  getTherapistListAdmin,
  getUnreadMessagesCount,
  resetPassword,
  scheduleNewAppointmentAdmin,
  sendOnboardingEmail,
  sendSetPasswordLink,
  setNewPassword,
  signinUser,
  signupUser,
  signinTherapist,
  signupTherapist,
  signupUserAdmin,
  forgotTherapistPassword,
  resetTherapistPassword,
  setNewTherapistPassword,
  verifyOtp,
  verifyOtpViaSignup,
} from "./appThunks";

const initialState = {
  isInitialized: false,
  user: null,
  patient_list: null,
  current_password_link: null,
  packages_list: null,
  current_patient: null,
  therapist_list: null,
  stop_loading: false,
  latest_times: null,
  signinError: {
    usernameError: null,
    passwordError: null,
  },
  signupError: {
    emailError: null,
    passwordError: null,
  },
  userType: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    initializeApp: (state) => {
      state.isInitialized = true;
    },
    stopLoading: (state) => {
      state.stop_loading = false;
    },
    logOutUser: (state) => {
      state.user = null;
      localStorage.clear();
    },
    clearPasswordLink: (state) => {
      state.current_password_link = null;
    },
    clearCurrentPatient: (state) => {
      state.current_patient = null;
    },
    clearSigninError: (state) => {
      state.signinError.usernameError = null;
      state.signinError.passwordError = null;
    },
  },
  extraReducers: {
    [signinUser.fulfilled]: (state, { payload }) => {
      if (payload?.user?.is_email_verified) {
        localStorage.setItem("fitcyAccessToken", payload.token.access);
        localStorage.setItem("fitcyRefreshToken", payload.token.refresh);
        state.user = payload.user;
      }
      state.signinError = {
        usernameError: null, // Clear the username error if the login is successful
        passwordError: null, // Clear the password error if the login is successful
      };
    },
    [signinUser.rejected]: (state, action) => {
      state.stop_loading = true;
      const errorResponse = action.payload;
      if (errorResponse?.usernameError) {
        state.signinError.usernameError = errorResponse?.usernameError;
      } else {
        state.signinError.usernameError = "Email is incorrect";
      }

      if (errorResponse.passwordError) {
        state.signinError.passwordError = errorResponse.passwordError;
      } else {
        state.signinError.passwordError = "Password is incorrect";
      }
      // toast(
      //   "If you are an existing customer/specialist accessing the Fitcy Health 2.0 platform, please click on Forgot Password, and enter your email address. This will allow you to log in for the first time & set a new password."
      // );
    },
    [signupUser.rejected]: (state, action) => {
      return {
        ...state,
        stop_loading: true,
        signupError: {
          emailError: action.payload?.email?.[0], // Clear the username error if the login is successful
          passwordError: action.payload?.password?.[0], // Clear the password error if the login is successful
        },
      };
    },
    [signupUser.fulfilled]: async (state, { payload }) => {
      if (payload?.user?.is_email_verified) {
        localStorage.setItem("fitcyAccessToken", payload.token.access);
        localStorage.setItem("fitcyRefreshToken", payload.token.refresh);

        if (payload.status === 200) window.location.replace("/login");
        if (payload.status === 201)
          window.location.replace("/therapist/onboarding?skip_questions");
      }
      return {
        ...state,
        signupError: {
          emailError: null, // Clear the username error if the login is successful
          passwordError: null, // Clear the password error if the login is successful
        },
      };
    },
    [setNewPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [setNewPassword.fulfilled]: async (state, { payload }) => {
      localStorage.setItem("fitcyAccessToken", payload.token.access);
      localStorage.setItem("fitcyRefreshToken", payload.token.refresh);

      window.location.replace("/login");
    },

    [signinTherapist.fulfilled]: (state, { payload }) => {
      localStorage.setItem("fitcyAccessToken", payload.token.access);
      localStorage.setItem("fitcyRefreshToken", payload.token.refresh);
      state.user = payload.user;
      state.signinError = {
        usernameError: null, // Clear the username error if the login is successful
        passwordError: null, // Clear the password error if the login is successful
      };
    },
    [signinTherapist.rejected]: (state, action) => {
      state.stop_loading = true;
      const errorResponse = action;
      if (errorResponse?.usernameError) {
        state.signinError.usernameError = errorResponse?.usernameError;
      } else {
        state.signinError.usernameError = "Email is incorrect";
      }

      if (errorResponse.passwordError) {
        state.signinError.passwordError = errorResponse.passwordError;
      } else {
        state.signinError.passwordError = "Password is incorrect";
      }
    },
    [signupTherapist.rejected]: (state, action) => {
      state.stop_loading = true;
      state.signupError = {
        emailError: action.payload?.email?.[0], // Clear the username error if the login is successful
        passwordError: action.payload?.password?.[0], // Clear the password error if the login is successful
      };
    },
    [signupTherapist.fulfilled]: async (state, { payload }) => {
      const result = await payload.json();

      localStorage.setItem("fitcyAccessToken", result.token.access);
      localStorage.setItem("fitcyRefreshToken", result.token.refresh);

      if (payload.status === 200) window.location.replace("/login");

      if (payload.status === 201)
        window.location.replace("/resources?skip_questions");
      state.signupError = {
        emailError: null, // Clear the username error if the login is successful
        passwordError: null, // Clear the password error if the login is successful
      };
    },
    [setNewTherapistPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [setNewTherapistPassword.fulfilled]: async (state, { payload }) => {
      localStorage.setItem("fitcyAccessToken", payload.token.access);
      localStorage.setItem("fitcyRefreshToken", payload.token.refresh);

      window.location.replace("/login");
    },
    [signupUserAdmin.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [signupUserAdmin.fulfilled]: () => {
      // toast.success("Patient added");
    },
    [getMe.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isInitialized = true;
    },
    [getMe.rejected]: (state, { payload }) => {
      if (payload?.status && payload.status === 400 && payload.status === 500) {
        // Remove tokens from local storage
        localStorage.removeItem("fitcyAccessToken");
        localStorage.removeItem("fitcyRefreshToken");
        state.user = null;
        state.isInitialized = true;
      }
    },
    [getMeEasy.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isInitialized = true;
    },
    [getMeEasy.rejected]: (state) => {
      state.isInitialized = true;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgotEmailError = action.payload?.email?.[0];
    },
    [forgotPassword.fulfilled]: () => {
      // setTimeout(() => {
      window.location.reload();
      localStorage.setItem("passwordResetInProgress", true);
      // }, 2000)
      // toast.success(
      //   "Please check your email for reset link. Redirecting to login page",
      //   {
      //     onClose: () => {

      //     }
      //   }
      // );
    },
    [resetPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [resetPassword.fulfilled]: () => {
      // setTimeout(() => {
      window.location.replace("/login");
      // }, 2000)
      // toast.success("Password reset succesfully. Redirecting to login page", {
      //   onClose: () => {

      //   }
      // });
    },
    [forgotTherapistPassword.rejected]: (state, action) => {
      state.forgotEmailError = action.payload?.email?.[0];
    },
    [forgotTherapistPassword.fulfilled]: () => {
      // setTimeout(() => {
      window.location.reload();
      localStorage.setItem("passwordResetInProgress", true);
      // }, 2000)
      // toast.success(
      //   "Please check your email for reset link. Redirecting to login page",
      //   {
      //     onClose: () => {

      //     }
      //   }
      // );
    },
    [resetTherapistPassword.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [resetTherapistPassword.fulfilled]: () => {
      // setTimeout(() => {
      window.location.replace("/therapist/login");
      // }, 2000)
      // toast.success("Password reset succesfully. Redirecting to login page", {
      //   onClose: () => {

      //   }
      // });
    },
    [patchOnboardingStatus.fulfilled]: (state, action) => {
      state.user = action.payload;
    },

    [updateTherapistPicture.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [updateTherapistPicture.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [updatePatientPicture.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [updatePatientPicture.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [getUnreadMessagesCount.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getUnreadMessagesCount.fulfilled]: (state, action) => {
      state.unread_count = action.payload;
    },
    [getLatestMessagesTime.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getLatestMessagesTime.fulfilled]: (state, action) => {
      state.latest_times = action.payload;
    },
    [getPatientList.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [getPatientList.fulfilled]: (state, action) => {
      state.patient_list = action.payload;
    },
    [sendSetPasswordLink.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [sendSetPasswordLink.fulfilled]: (state, action) => {
      state.current_password_link = action.payload;
    },
    [sendOnboardingEmail.rejected]: () => {
      // toast.error(getErrorDetailsforNotif(action.payload));
    },
    [sendOnboardingEmail.fulfilled]: () => {
      // toast.success("Onboarding email sent");
    },
    [scheduleNewAppointmentAdmin.rejected]: () => {
      // toast.error(action.payload);
    },
    [scheduleNewAppointmentAdmin.fulfilled]: () => {
      // toast.success("Appointment set");
    },
    [getPackagesList.rejected]: () => {
      // toast.error(action.payload);
    },
    [getPackagesList.fulfilled]: (state, action) => {
      state.packages_list = action.payload;
    },

    [addTransactionHistory.rejected]: () => {
      // toast.error(action.payload);
    },
    [addTransactionHistory.fulfilled]: () => {
      // toast.success("Transaction added");
    },
    [getSinglePatient.rejected]: () => {
      // toast.error(action.payload);
    },
    [getSinglePatient.fulfilled]: (state, action) => {
      state.current_patient = action.payload;
    },
    [getTherapistListAdmin.rejected]: () => {
      // toast.error(action.payload);
    },
    [getTherapistListAdmin.fulfilled]: (state, action) => {
      state.therapist_list = action.payload;
    },
    [verifyOtp.fulfilled]: (state, { payload }) => {
      localStorage.setItem("fitcyAccessToken", payload.token.access);
      localStorage.setItem("fitcyRefreshToken", payload.token.refresh);
      state.user = payload.user;
    },
    [verifyOtpViaSignup.fulfilled]: (state, { payload }) => {
      const newState = { ...state }; // Create a copy of the current state
      localStorage.setItem("fitcyAccessToken", payload.token.access);
      localStorage.setItem("fitcyRefreshToken", payload.token.refresh);
      window.location.replace("/login");
      return newState; // Return the updated state
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeApp,
  logOutUser,
  clearPasswordLink,
  clearCurrentPatient,
  stopLoading,
  clearSigninError,
} = appSlice.actions;

export default appSlice.reducer;
