import React from "react";
import Button from "../atoms/Button";

const AnswersList = ({ type, question, handleSubmitAnswer, buttonState }) => {
  const lastIndex = question?.answers?.length - 1;
  return (
    <form
      method="POST"
      className="mt-6 space-y-1"
      onSubmit={handleSubmitAnswer}
    >
      {question.answers.map((answer, index) =>
        type === "MULTISELECT" ? (
          <div
            key={answer.id}
            className="first-of-type:rounded-t-2xl last-of-type:rounded-b-2xl rounded bg-teal-50 border border-gray-100 hover:bg-teal-100 transition-all cursor-pointer flex items-center"
          >
            <input
              className="appearance-none checked:bg-teal-900 border-teal-500 w-5 h-5 hidden items-center justify-center checked:before:w-[.35rem] checked:before:h-[.75rem] before:border-b-2 before:border-white before:border-r-2 before:rotate-45 peer"
              id={answer.id}
              type="checkbox"
              name="answer"
              value={JSON.stringify({
                title: answer.title,
                id: answer.id,
                score: answer.score,
              })}
            />
            <label
              className={`${index === 0 ? "rounded-t-2xl" : ""} ${
                index === lastIndex ? "rounded-b-2xl" : ""
              } font-medium cursor-pointer p-6 w-full peer-checked:bg-teal-900 peer-checked:text-white`}
              htmlFor={answer.id}
            >
              {answer.title}
            </label>
          </div>
        ) : (
          <div
            key={answer.id}
            className="p-6 first-of-type:rounded-t-2xl last-of-type:rounded-b-2xl rounded bg-teal-50 hover:bg-teal-100 transition-all border border-gray-100 cursor-pointer"
            onClick={() =>
              handleSubmitAnswer({
                target: {
                  answer: {
                    value: {
                      title: answer.title,
                      id: answer.id,
                    },
                  },
                  score: answer.score,
                },
              })
            }
          >
            <div type="radio" name="answer">
              {answer.title && <div>{answer.title}</div>}
              {answer.description && (
                <div className="mt-1 text-gray-500 text-sm">
                  {answer.description}
                </div>
              )}
            </div>
          </div>
        )
      )}
      {type === "MULTISELECT" && (
        <>
          <Button
            type="submit"
            text={buttonState}
            className="bg-teal-900 hover:shadow-button hover:bg-teal-500 text-white transition-all !mt-10"
            full
          />
        </>
      )}
    </form>
  );
};
export default AnswersList;
