import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/molecules/Header";
import pattern from "../../assets/auth-pattern.png";
import Form from "../../components/molecules/Form";
import Button from "../../components/atoms/Button";
import Swiper from "../../components/molecules/Swiper";
import therapistPattern from "../../assets/circles.png";
import calendar from "../../assets/calendar.svg";
import barChart from "../../assets/bar-chart.svg";
import shieldDollar from "../../assets/shield-dollar.svg";
import TherapistImage from "../../assets/therapistAppMain.png";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import {
  sendOtp,
  verifyOtp,
  verifyOtpViaSignup,
} from "../../redux/app/appThunks";
import ButtonLoader from "../../components/atoms/ButtonLoader";

export default function VerifyOtp({ app }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { email, password, signUp } = location.state ?? "";

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (app === "THERAPIST") {
      navigate(`/therapist/login`);
    } else {
      navigate(`/login`);
    }
  };

  const handleResendOtp = async () => {
    dispatch(sendOtp({ email: email }));
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otp?.length < 6) {
      setOtpError("PIN is not valid");
    } else {
      setLoading(true);
      if (signUp) {
        const signupResponse = await dispatch(
          verifyOtpViaSignup({
            email: email,
            password: password,
            otp: otp,
          })
        );
        if (signupResponse) {
          setLoading(false);
          if (signupResponse?.error)
            setOtpError(signupResponse?.payload?.message || "");
        }
      } else {
        const loginResponse = dispatch(
          verifyOtp({
            email: email,
            password: password,
            otp: otp,
          })
        );
        if (loginResponse) {
          setLoading(false);
          if (loginResponse?.error)
            setOtpError(loginResponse?.payload?.message || "");
        }
      }
    }
  };

  return (
    <div className="h-100">
      <Header className="lg:absolute" />
      <div className="flex flex-col lg:flex-row relative">
        <div className="flex justify-center lg:w-1/2 px-4 pb-24 lg:pb-0 pt-16 lg:pt-24">
          <div className="lg:w-96 w-full">
            <Form
              heading="Pin generated and was sent on your email"
              peragraph={`Please verify the PIN sent to ${email} to create your account.`}
              method="POST"
              onSubmit={handleVerifyOtp}
              className="mt-6"
            >
              <div className="text-sm font-medium mb-2">Enter Pin</div>
              <OtpInput
                value={otp}
                onChange={(text) => {
                  setOtp(text);
                  if (text?.length == 6) setOtpError("");
                }}
                numInputs={6}
                inputStyle={"mr-3"}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "37.5px",
                      height: "44px",
                      borderRadius: "10px",
                      fontSize: "16px",
                      textAlign: "center",
                      outlineColor: app === "THERAPIST" ? "#029CF5" : "#01554D",
                    }}
                  />
                )}
              />
              {otpError && (
                <div className="text-sm text-danger-900 mt-1.5">{otpError}</div>
              )}

              <Button
                type="submit"
                className={`text-white ${app === "THERAPIST"
                    ? "bg-blue-900 hover:bg-blue-500"
                    : "bg-teal-900 hover:bg-teal-500"
                  }  hover:shadow-button transition-all mt-10`}
                full
              >
                {loading ? <ButtonLoader /> : "Verify"}
              </Button>

              <div className="text-gray-400 mt-4 text-center font-medium">
                Didn’t get anything?{" "}
                <span
                  onClick={handleResendOtp}
                  className={`cursor-pointer ${app === "THERAPIST" ? "text-blue-900" : "text-teal-900"
                    }`}
                >
                  Resend
                </span>
              </div>
            </Form>
          </div>
        </div>
        {app === "THERAPIST" ? (
          <div
            className="h-screen flex-1 bg-cover lg:w-1/2 justify-end items-center flex overflow-hidden py-24 lg:py-0"
            style={{ backgroundImage: `url(${therapistPattern})` }}
          >
            <div className="relative">
              <img src={TherapistImage} />
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-56 absolute top-20 left-1/3">
                <img src={calendar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Manage & grow your practice effortlessly
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-52 absolute top-1/2 left-0">
                <img src={shieldDollar} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  seamless payment system
                </span>
              </div>
              <div className="p-3 rounded-lg bg-white/70 shadow-therapistInfoCard w-[18.5rem] absolute bottom-20 left-1/2">
                <img src={barChart} />
                <span className="mt-3 text-sm font-medium text-gray-800/700">
                  Meaningful insights about your practice & client’s behavior
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="h-screen flex-1 bg-cover bg-teal-900 lg:w-1/2 justify-center items-center flex overflow-hidden px-4 py-24 lg:py-0"
            style={{ backgroundImage: `url(${pattern})` }}
          >
            <Swiper />
          </div>
        )}
        <button
          onClick={handleLogin}
          className="absolute w-12 h-12 flex justify-center items-center left-4 lg:left-10 lg:top-24 rounded-full drop-shadow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.41379 12L14.6568 17.2788C15.0473 17.6725 15.0473 18.3109 14.6568 18.7047C14.2663 19.0984 13.6333 19.0984 13.2428 18.7047L7.29289 12.7131C6.90237 12.3192 6.90237 11.6807 7.29289 11.2869L13.2428 5.29532C13.6333 4.90156 14.2663 4.90156 14.6568 5.29532C15.0473 5.68907 15.0473 6.32748 14.6568 6.72124L9.41379 12Z"
              fill="#101828"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
