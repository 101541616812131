import React from "react";

const Tooltip = ({ children, direction, right, className, position, pin }) => {
  return (
    <div
      className={`${className} ${
        direction === "left"
          ? `${right ?? "right-0 after:right-4 before:right-3.5"}`
          : "-left-5 after:left-[18px]"
      } ${position === "bottom" ? "" : "bottom-7"} ${
        pin === "top"
          ? "before:content-[''] before:block before:absolute before:bottom-full before:border-[10px] before:border-transparent before:border-b-gray-300 after::content-[''] after:block after:absolute after:bottom-full after:border-8 after:border-transparent after:border-b-gray-100"
          : pin === "top center"
          ? "before:content-[''] before:block before:absolute before:bottom-full before:border-[10px] before:border-transparent before:border-b-gray-300 after::content-[''] after:block after:absolute after:bottom-full after:border-8 after:border-transparent after:border-b-gray-100 before:mx-auto before:w-5 before:left-0 before:right-0 after:mx-auto after:w-4 after:!left-0 after:right-0"
          : "before:content-[''] before:block before:absolute before:top-full before:border-[10px] before:border-transparent before:border-t-gray-300 after::content-[''] after:block after:absolute after:top-full after:border-8 after:border-transparent after:border-t-gray-100"
      } text-sm font-medium absolute border bg-gray-100 border-gray-300 rounded-lg px-4 py-3  max-w-[16rem] lg:max-w-none w-max hidden group-hover:block`}
    >
      {children}
    </div>
  );
};
export default Tooltip;
