import React from 'react';

const ChatInput = ({ 
  refresh, 
  message, 
  setMessage, 
  sendText, 
  handleKeyPress, 
  selectedUser,
  SendIcon,
  inactiveMessage
}) => {
  if (!selectedUser.is_active) {
    return (
      <div className="py-8 px-4 lg:px-6 text-gray-500">
        {inactiveMessage}{selectedUser?.is_active}
      </div>
    );
  }

  if (refresh) {
    return (
      <div className="py-2 px-4 lg:px-6 text-gray-500">
        {`You're currently offline. Please check your connection or try `}
        <span
          className="cursor-pointer text-teal-500 mx-1"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          refreshing the page
        </span>
      </div>
    );
  }

  return (
    <div className="border-t border-t-solid py-2 px-4 lg:px-6 flex items-center justify-between space-x-4">
      <input
        placeholder="Type a message"
        className="w-full rounded-full border-gray-200 outline-none h-12 px-3"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        onKeyDown={handleKeyPress}
      />
      <img
        onClick={() => (message ? sendText() : {})}
        src={SendIcon}
        className="cursor-pointer"
        alt="Send message"
      />
    </div>
  );
};

ChatInput.displayName = 'ChatInput';

export default ChatInput;